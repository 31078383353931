import React from "react";
import { Link } from "react-router-dom";

function Box({
  img,
  head = "",
  desc = "",
  status = false,
  offset = false,
  id = "",
  date = "",
}) {
  return (
      <div
          className={
            "blog-box col-lg-5 col-md-6 col-sm-12" +
            (offset == true ? " offset-lg-2" : "")
          }
      >
        <Link to={"/app/blogs/details/" + id} className="blog-box-body">
          <div className="blog-box-content">
            <div className="d-flex">
              <div
                  className="blog-box-img"
                  style={{
                    backgroundImage: "url(" + img + ")",
                  }}
              ></div>
              <div className="blog-box-article">
                <div className="blog-box-head" style={{ color: "black" }}>
                  {head}
                </div>
                <div className="blog-box-desc" style={{ color: "black" }}>
                  {desc}
                </div>
              </div>
              <div>
                <div style={{position:'absolute',display:'flex',bottom:0,right:0, borderRadius:5,margin:10,justifyContent:'center',alignItems:'center', backgroundColor:'#1e4780'}}>
                  <div className="text-right">
                    {status == false ? (
                        <Link style={{color:'white',fontSize:10}} className="btn btn-survey" to={"/app/surveys/" + id}>
                          Ankete Katıl
                        </Link>
                    ) : (
                        <div style={{color:'white',fontSize:10}} className="btn btn-survey">
                          <i className="fa fa-check"></i>
                        </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
  )
  return (
    <div
      className={
        "survey-box col-lg-5 col-md-6 col-sm-12" +
        (offset == true ? " offset-lg-2" : "")
      }
    >
      {/*<div className="text-right mb-2">{date}</div>*/}
      <div className="survey-box-head">{head}</div>
      <div className="survey-box-body">
        <div className="survey-box-img-frame">
          <div
            className="survey-box-img"
            style={{
              backgroundImage: "url(" + img + ")",
            }}
          ></div>
        </div>
        <div>
          <div className="survey-box-text ml-4">
            {desc}
            <div className="mt-2 text-right">
              {status == false ? (
                <Link className="btn btn-survey" to={"/app/surveys/" + id}>
                  Ankete Katıl
                </Link>
              ) : (
                <div className="btn btn-survey">
                  <i className="fa fa-check"></i>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Box;
