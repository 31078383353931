import React, {useState, useEffect} from "react";
import {post} from "../networking/Server";
import {getFile} from "../networking/getFile";
import Loading from "../components/loading";
import moment from "moment";
import EmojiTextarea from "../components/emojiTextarea";

function Page(props) {

    const [advice, setAdvice] = useState({});
    const [a_comment_loading, setACommentLoading] = useState(false);
    const [comment_loading, setCommentLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [delete_loading, setDeleteLoading] = useState(0);
    const [delete_loading2, setDeleteLoading2] = useState(0);
    const [myLike, setMyLike] = useState(false)
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState("");
    const [user_id, setUserID] = useState(0);


    const [answer, setAnswer] = useState({});


    useEffect(() => {
        setLoading(true)

        getAdvice();
    }, [])

    async function getAdvice() {
        const data = await post("api/advices/get_one", {advice_id: props.match.params.advice,web:true})
        if (data.result) {
            setComments(data.comments);
            setMyLike(data.my_like);
            setAdvice(data.advice);
            setComments(data.comments);
            setUserID(data.user_id)

            setAnswer({})

        }

        setLoading(false);
        setCommentLoading(false);
        setACommentLoading(false);
        setComment("");
        setDeleteLoading(0)
        setDeleteLoading2(0)

    }

    async function addComment() {
        if (!comment) return;

        setCommentLoading(true);

        const data = await post('api/advices/add_comment', {advice_id: props.match.params.advice, comment})

        if (data.result) {
            getAdvice();
        }


    }

    async function addCommentAnswer(advice_comment_id,comment) {
        if (!comment) return;

        setDeleteLoading(advice_comment_id)


        const data = await post('api/advices/add_comment_answer', {advice_comment_id,comment})
        setComment("");

        if (data.result) {
            getAdvice();
        }

    }

    async function removeComment(advice_comment_id) {

        setDeleteLoading(advice_comment_id)

        const data = await post('api/advices/remove_comment', {advice_comment_id})
        console.warn(data)
        if (data.result) {
            getAdvice();
        }

    }

    async function removeCommentAnswer(advice_comment_answer_id) {

        setDeleteLoading2(advice_comment_answer_id)

        const data = await post('api/advices/remove_comment_answer', {advice_comment_answer_id})
        if (data.result) {
            getAdvice();
        }

    }

    async function like() {
        const data = await post('api/advices/like', {advice_id: props.match.params.advice});

        if (data.result) {
            setMyLike(!myLike);
        }
    }


    return (
        <>
            <Loading loading={loading}/>
            <div className="location">Tavsiyelerimiz</div>
            <div className="main">
                <div className="container">
                    {
                        !loading?
                            <div className="area">
                                <div
                                    className="area-header"
                                    style={{
                                        backgroundImage: "url(" + getFile(advice?.image) + ")",
                                    }}
                                ></div>
                                <div className="area-bar">
                                    <div
                                        className="area-profile"
                                        style={{
                                            backgroundImage: "url(" + getFile(advice?.user?.image) + ")",
                                        }}
                                    ></div>
                                    <div className="area-writer">
                                        <span>{`${advice?.user?.name} ${advice?.user?.surname}`}</span>
                                    </div>
                                    <div className="area-like">
                                        <button onClick={like} className="btn btn-icon">
                                            {myLike ?
                                                <img style={{width: 32}} src={require('../assets/images/advices/liked.png')}/> :
                                                <img style={{width: 32}} src={require('../assets/images/advices/like.png')}/>}
                                        </button>
                                    </div>
                                </div>

                                <div className="area-head">{advice?.title}</div>
                                <div className="area-body">{advice?.content}</div>
                                <div className="area-comments">
                                    {/**
                                     * Comments
                                     */}
                                    {comments.map((e, i) => {

                                        let {advice_comment_id,advice_comment_answers,comment,user,createdAt} = e;
                                        let {name,surname} = user;

                                        return (
                                            <div key={i} className="area-comment">
                                                <div style={{backgroundColor:'#ebebe4',margin:20,borderRadius:20,padding:20}}>
                                                    <div>
                                                        <div className="area-comment-name d-inline-block">
                                                            {`${name+" "+surname}`}
                                                        </div>
                                                    </div>
                                                    <div className="area-comment-content">
                                                        {comment}
                                                    </div>
                                                    <div className="area-comment-bar">
                                                        <div
                                                            className="area-comment-date">({moment(createdAt).format("DD.MM.YYYY")})
                                                        </div>
                                                        {
                                                            user['user_id']==user_id ?
                                                            delete_loading==advice_comment_id?
                                                                <div
                                                                    style={{color:'blue', display:'flex',justifyContent:'flex-end',margin:2,marginRight:30,padding:10}}
                                                                >
                                                                    <span className={"fas fa-spinner fa-2x fa-spin"}/>
                                                                </div>:
                                                                <button
                                                                    className="btn btn-danger btn-sm btn-icon"
                                                                    onClick={()=>{
                                                                        removeComment(advice_comment_id)
                                                                    }}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </button>:null
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    Array.isArray(advice_comment_answers)?
                                                        advice_comment_answers.map((e)=>{
                                                            let {advice_comment_answer_id, user,comment,createdAt} = e;
                                                            let {name,surname} = user;
                                                            return(
                                                                <div className="area-comment" style={{backgroundColor:'#dfc9b6',margin:20,marginLeft:'20%',borderRadius:20,padding:20}}>
                                                                    <div>
                                                                        <div className="area-comment-name d-inline-block">
                                                                            {`${name+" "+surname}`}
                                                                        </div>
                                                                    </div>
                                                                    <div className="area-comment-content">
                                                                        {comment}
                                                                    </div>
                                                                    <div className="area-comment-bar">
                                                                        <div
                                                                            className="area-comment-date">({moment(createdAt).format("DD.MM.YYYY")})
                                                                        </div>
                                                                        {
                                                                            user['user_id']==user_id ?
                                                                            delete_loading2==advice_comment_answer_id?
                                                                                <div
                                                                                    style={{color:'blue', display:'flex',justifyContent:'flex-end',margin:2,marginRight:30,padding:10}}
                                                                                >
                                                                                    <span className={"fas fa-spinner fa-2x fa-spin"}/>
                                                                                </div>:
                                                                                <button
                                                                                    className="btn btn-danger btn-sm btn-icon"
                                                                                    onClick={()=>{
                                                                                        removeCommentAnswer(advice_comment_answer_id)
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-trash"></i>
                                                                                </button>:null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }):null
                                                }

                                                {
                                                    answer?.advice_comment_id == advice_comment_id?
                                                        <div>
                                                      <textarea
                                                          className="area-comment" style={{width:'76%',flex:1, backgroundColor:'#F1F1F1',margin:20,marginLeft:'20%',borderRadius:20,padding:20}}
                                                          onChange={e =>{
                                                              setAnswer({advice_comment_id,comment:e.target.value})
                                                          }}
                                                          maxLength={500}
                                                          rows="2"
                                                          placeholder="Cevap"
                                                      />
                                                            {
                                                                a_comment_loading?
                                                                    <div
                                                                        style={{color:'blue', display:'flex',justifyContent:'flex-end',margin:2,marginRight:30,padding:10}}
                                                                    >
                                                                        <span className={"fas fa-spinner fa-2x fa-spin"}/>
                                                                    </div>
                                                                    :
                                                                    <div
                                                                        onClick={()=>{
                                                                            addCommentAnswer(answer.advice_comment_id,answer.comment)
                                                                        }}
                                                                        style={{color:'blue', display:'flex',justifyContent:'flex-end',margin:2,marginRight:30,padding:10}}
                                                                    >
                                                                        Gönder
                                                                    </div>
                                                            }

                                                        </div>:
                                                        <div
                                                            onClick={()=>{
                                                                setAnswer({advice_comment_id,comment:""})
                                                            }}
                                                            style={{color:'blue', display:'flex',justifyContent:'flex-end',margin:15,padding:10}}
                                                        >
                                                            Cevap Yaz...
                                                        </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="mb-5 p-5">
                                    <label className="form-label mb-3">
                                        Yorumunuz <span className="require"> *</span>
                                    </label>
                                    <EmojiTextarea
                                        defaultValue={comment}
                                        onChange={(val) => {
                                            setComment(val)
                                        }}
                                    />
                                    <div className="btn-group w-100 mt-5">
                                        {
                                            comment_loading?
                                                <button className="btn btn-primary">
                                                    <span className={"fas fa-spinner fa-2x fa-spin"}/>
                                                </button>:
                                                <button onClick={addComment} className="btn btn-primary">
                                                    Yorum Yap
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>:null
                    }

                </div>
            </div>
        </>
    );
}

export default Page;
