import React, { useEffect, useState } from "react";
import { post } from "../networking/Server";
import Loading from "../components/loading";
import moment from "moment";
import EmojiTextarea from "../components/emojiTextarea";
import { Link } from "react-router-dom";

function Page(props) {
  let advert_id = parseInt(props.match.params.advert_id);

  const [loading, setLoading] = useState(false);
  const [a_comment_loading, setACommentLoading] = useState(false);
  const [comment_loading, setCommentLoading] = useState(false);
  const [advert, setAdvert] = useState({});
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [answer, setAnswer] = useState({});
  const [delete_loading, setDeleteLoading] = useState(0);
  const [delete_loading2, setDeleteLoading2] = useState(0);
  const [user_id, setUserID] = useState(0);


  useEffect(() => {
    setLoading(true);

    getAdverts();
  }, []);

  async function getAdverts() {
    const data = await post("api/a-web/get_one", { advert_id, web: true });

    if (data.result) {
      setAdvert(data.advert);
      setComments(data.comments);
      setAnswer({});
      setUserID(data.user_id);
    }
    setLoading(false);
    setCommentLoading(false);
    setACommentLoading(false);
    setComment("");
    setDeleteLoading(0)
    setDeleteLoading2(0)


  }

  async function addComment() {
    if (!comment) return;

    setCommentLoading(true)
    const data = await post("api/a-web/add_comment", { advert_id, comment });


    if (data.result) {
      getAdverts();
    }

  }

  async function addCommentAnswer(advert_comment_id, comment) {
    if (!comment) return;

    setACommentLoading(true)
    const data = await post("api/a-web/add_comment_answer", {
      advert_comment_id,
      comment,
    });

    setComment("");

    if (data.result) {
      getAdverts();
    }

  }

  let {
    title,
    images,
    specifications,
    description,
    phone,
    address,
      namesurname,
    email,
    web_site,
  } = advert;

  try {
    images = JSON.parse(images);
  } catch (e) {
    images = [];
  }

  try {
    specifications = JSON.parse(specifications);
  } catch (e) {
    specifications = [];
  }

  async function removeComment(advert_comment_id) {

    setDeleteLoading(advert_comment_id)

    const data = await post('api/a-web/remove_comment', {advert_comment_id})
    if (data.result) {
      getAdverts();
    }

  }

  async function removeCommentAnswer(advert_comment_answer_id) {

    setDeleteLoading2(advert_comment_answer_id)

    const data = await post('api/a-web/remove_comment_answer', {advert_comment_answer_id})
    if (data.result) {
      getAdverts();
    }

  }

  return (
    <>
      <Loading loading={loading} />
      <div className="location">İlanlarımız</div>
      <div className="main">
        <div className="container">
          {
            !loading?
                <div>
                  {/**
                   * Slider
                   */}
                  <div className="slider">
                    <div
                        id="carouselExampleIndicators"
                        className="carousel slide"
                        data-ride="carousel"
                    >
                      <ol className="carousel-indicators">
                        {images
                            ? images.map((image, i) => {
                              return (
                                  <li
                                      key={i}
                                      data-target="#carouselExampleIndicators"
                                      data-slide-to={i}
                                      className={i == 0 ? "active" : ""}
                                  ></li>
                              );
                            })
                            : null}
                      </ol>

                      <div className="carousel-inner">
                        {images
                            ? images.map((image, i) => {
                              return (
                                  <div
                                      key={i}
                                      className={
                                        "carousel-item " + (i == 0 ? "active" : "")
                                      }
                                  >
                                    <div
                                        className="carousel-img"
                                        style={{
                                          backgroundImage: "url(" + image + ")",
                                        }}
                                    ></div>
                                  </div>
                              );
                            })
                            : null}
                      </div>
                      <a
                          className="carousel-control-prev"
                          href="#carouselExampleIndicators"
                          role="button"
                          data-slide="prev"
                      >
                <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                ></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                          className="carousel-control-next"
                          href="#carouselExampleIndicators"
                          role="button"
                          data-slide="next"
                      >
                <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                ></span>
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                  {/**
                   * Head
                   */}
                  <div className="h4 text-center mt-4 mb-5">{title}</div>
                  {/**
                   * Detail Tbs
                   */}
                  <div className="advertisement-tab mb-5">
                    {/**
                     * Tab Navs
                     */}
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      {/**
                       * Tab Nav Item
                       */}
                      <li className="nav-item">
                        <a
                            class="nav-link active"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                        >
                          İlan Bilgileri
                        </a>
                      </li>
                      {/**
                       * Tab Nav Item
                       */}
                      <li className="nav-item">
                        <a
                            class="nav-link"
                            id="pills-profile-tab"
                            data-toggle="pill"
                            href="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                        >
                          Açıklama
                        </a>
                      </li>
                      {/**
                       * Tab Nav Item
                       */}
                      <li className="nav-item">
                        <a
                            class="nav-link"
                            id="pills-contact-tab"
                            data-toggle="pill"
                            href="#pills-contact"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                        >
                          İletişim
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                      >
                        {
                          /**
                           * İlan Bilgileri
                           */
                          specifications
                              ? specifications.map(({ title, desc }, i) => (
                                  <div className="advertisement-info-table" key={i}>
                                    <div className="advertisement-info-table-name">
                                      {title}
                                    </div>
                                    <div className="advertisement-info-table-value">
                                      {desc}
                                    </div>
                                  </div>
                              ))
                              : null
                        }
                      </div>
                      {/**
                       * Açıklama
                       */}
                      <p
                          class="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                      >
                        <p style={{ whiteSpace: "break-spaces" }} className="p-3">
                          {description}
                        </p>
                      </p>
                      <div
                          class="tab-pane fade"
                          id="pills-contact"
                          role="tabpanel"
                          aria-labelledby="pills-contact-tab"
                      >
                        <div className="p-3 row">
                          {/**
                           * Telefon Numarası
                           */}
                          {namesurname ? (
                              <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mb-2">
                                <i className="fa fa-user mr-3"></i>
                                <span>{namesurname}</span>
                              </div>
                          ) : null}

                          {phone ? (
                              <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mb-2">
                                <i className="fa fa-phone mr-3"></i>
                                <span>{phone}</span>
                              </div>
                          ) : null}
                          {/**
                           * Email Adresi
                           */}
                          {email ? (
                              <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mb-2">
                                <i className="fa fa-envelope mr-3"></i>
                                <span>
                        <a href={"mailto:" + email}>{email}</a>
                      </span>
                              </div>
                          ) : null}
                          {/**
                           * Websitesi
                           */}
                          {web_site ? (
                              <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mb-2">
                                <i className="fa fa-globe mr-3"></i>
                                <span>
                        <a href={web_site}>{web_site}</a>
                      </span>
                              </div>
                          ) : null}
                          {/**
                           * Adress
                           */}
                          {address ? (
                              <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mb-2">
                                <i className="fa fa-map-marker mr-3"></i>
                                <span>{address}</span>
                              </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="area">
                    <div className="area-comments">
                      {/**
                       * Comments
                       */}
                      {Array.isArray(comments)
                          ? comments.map((e, i) => {
                            let {
                              advert_comment_id,
                              advert_comment_answers,
                              comment,
                              user,
                              createdAt,
                            } = e;
                            let { name, surname } = user;

                            return (
                                <div key={i} className="area-comment">
                                  <div
                                      style={{
                                        backgroundColor: "#ebebe4",
                                        margin: 20,
                                        borderRadius: 20,
                                        padding: 20,
                                      }}
                                  >
                                    <div>
                                      <div className="area-comment-name d-inline-block">
                                        {`${name + " " + surname}`}
                                      </div>
                                    </div>
                                    <div className="area-comment-content">{comment}</div>
                                    <div className="area-comment-bar">
                                      <div className="area-comment-date">
                                        ({moment(createdAt).format("DD.MM.YYYY")})
                                      </div>
                                      {
                                        user['user_id']==user_id ?
                                            delete_loading==advert_comment_id?
                                                <div
                                                    style={{color:'blue', display:'flex',justifyContent:'flex-end',margin:2,marginRight:30,padding:10}}
                                                >
                                                  <span className={"fas fa-spinner fa-2x fa-spin"}/>
                                                </div>:
                                                <button
                                                    className="btn btn-danger btn-sm btn-icon"
                                                    onClick={()=>{
                                                      removeComment(advert_comment_id)
                                                    }}
                                                >
                                                  <i className="fa fa-trash"></i>
                                                </button>:null
                                      }
                                    </div>
                                  </div>


                                  {Array.isArray(advert_comment_answers)
                                      ? advert_comment_answers.map((e) => {
                                        let {advert_comment_answer_id, user, comment, createdAt } = e;
                                        let { name, surname } = user;
                                        return (
                                            <>
                                              <div
                                                  className="area-comment"
                                                  style={{
                                                    backgroundColor: "#dfc9b6",
                                                    margin: 20,
                                                    marginLeft: "20%",
                                                    borderRadius: 20,
                                                    padding: 20,
                                                  }}
                                              >
                                                <div>
                                                  <div className="area-comment-name d-inline-block">
                                                    {`${name + " " + surname}`}
                                                  </div>
                                                </div>
                                                <div className="area-comment-content">
                                                  {comment}
                                                </div>
                                                <div className="area-comment-bar">
                                                  <div className="area-comment-date">
                                                    (
                                                    {moment(createdAt).format("DD.MM.YYYY")}
                                                    )
                                                  </div>
                                                  {
                                                    user['user_id']==user_id ?
                                                        delete_loading2==advert_comment_answer_id?
                                                            <div
                                                                style={{color:'blue', display:'flex',justifyContent:'flex-end',margin:2,marginRight:30,padding:10}}
                                                            >
                                                              <span className={"fas fa-spinner fa-2x fa-spin"}/>
                                                            </div>:
                                                            <button
                                                                className="btn btn-danger btn-sm btn-icon"
                                                                onClick={()=>{
                                                                  removeCommentAnswer(advert_comment_answer_id)
                                                                }}
                                                            >
                                                              <i className="fa fa-trash"></i>
                                                            </button>:null
                                                  }
                                                </div>
                                              </div>

                                            </>
                                        );
                                      })
                                      : null}

                                  {answer?.advert_comment_id == advert_comment_id ? (
                                      <div>
                            <textarea
                                className="area-comment"
                                style={{
                                  width: "76%",
                                  flex: 1,
                                  backgroundColor: "#F1F1F1",
                                  margin: 20,
                                  marginLeft: "20%",
                                  borderRadius: 20,
                                  padding: 20,
                                }}
                                onChange={(e) => {
                                  setAnswer({
                                    advert_comment_id,
                                    comment: e.target.value,
                                  });
                                }}
                                maxLength={500}
                                rows="2"
                                placeholder="Cevap"
                            />
                                        {
                                          a_comment_loading?
                                              <div
                                                  style={{
                                                    color: "blue",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    margin: 2,
                                                    marginRight: 30,
                                                    padding: 10,
                                                  }}
                                              >
                                                <span className={"fas fa-spinner fa-2x fa-spin"}/>
                                              </div>
                                              :
                                              <div
                                                  onClick={() => {
                                                    addCommentAnswer(
                                                        answer.advert_comment_id,
                                                        answer.comment
                                                    );
                                                  }}
                                                  style={{
                                                    color: "blue",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    margin: 2,
                                                    marginRight: 30,
                                                    padding: 10,
                                                  }}
                                              >
                                                Gönder
                                              </div>
                                        }
                                      </div>
                                  ) : (
                                      <div
                                          onClick={() => {
                                            setAnswer({ advert_comment_id, comment: "" });
                                          }}
                                          style={{
                                            color: "blue",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            margin: 15,
                                            padding: 10,
                                          }}
                                      >
                                        Cevap Yaz...
                                      </div>
                                  )}
                                </div>
                            );
                          })
                          : null}
                    </div>
                  </div>

                  <div className="mb-5 p-5">
                    <label className="form-label mb-3">
                      Yorumunuz <span className="require"> *</span>
                    </label>
                    <EmojiTextarea
                        defaultValue={comment}
                        onChange={(val) => {
                          setComment(val);
                        }}
                    />
                    <div className="btn-group w-100 mt-5">
                      {
                        comment_loading?
                            <button className="btn btn-primary">
                              <span className={"fas fa-spinner fa-2x fa-spin"}/>
                            </button>:
                            <button onClick={addComment} className="btn btn-primary">
                              Yorum Yap
                            </button>
                      }

                    </div>
                  </div>
                </div>:null
          }
        </div>
      </div>
    </>
  );
}
export default Page;
