import React, {useEffect, useState} from "react";
import {post} from "../networking/Server";
import Loading from "../components/loading";
import $ from "jquery";
import {setAlert} from "../actions";

function Section(props) {

  const [loading, setLoading] = useState(false);
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [re_new_password, setReNewPassword] = useState("");



  useEffect(() => {
    /*const body = $("html, body");
    body.stop().animate({scrollTop: 1350}, 350, 'swing');*/
  },[]);

  function changePasword(old_password,new_password,re_new_password){

    if(!old_password || !new_password || !re_new_password)
      return;

    if(new_password.length<8){
      setAlert("Lütfen 8 Karakterli Bir Şifre Belirleyiniz")
      return;
    }

    if(new_password != re_new_password){
      setAlert("Şifreler Uyuşmuyor")
      return;
    }

    setLoading(true);

    post("api/users/reset_password",{old_password,new_password}).then((res)=>{
      try {
        setLoading(false);
        if(res.result){
          setAlert("Şifreniz Güncellenmiştir","success");
        }else {
          if(res.message==='wrong_password'){
            setAlert("Eski Şifre Hatalı, Lütfen Tekrar Deneyiniz")
          }
        }
      }catch (e) {

      }
    });
  }

  return (
    <div className="profile-area  w-100">
      <Loading loading={loading}/>
      <div className="profile-area-body">
        <div className="row">
          <div className="col-md-12 text-center mt-2 mb-5 font-weight-bold">
            Şifre Değiştir
          </div>
          {/**
           * Start İnput
           */}
          <div className="form-group col-md-12 ">
            <label className="mb-2 col-md-12 p-0">Eski Şifre</label>
            <input className="form-input col-md-12" type="password" defaultValue={old_password} onChange={(e) => setOldPassword(e.target.value)}/>
          </div>
          {/**
           * Start İnput
           */}
          <div className="form-group col-md-6">
            <label className="mb-2 col-md-12 p-0">Yeni Şifre</label>
            <input className="form-input col-md-12" type="password" defaultValue={new_password} onChange={(e) => setNewPassword(e.target.value)}/>
          </div>
          {/**
           * Start İnput
           */}
          <div className="form-group col-md-6">
            <label className="mb-2 col-md-12 p-0">Yeni Şifre Tekrar</label>
            <input className="form-input col-md-12" type="password" defaultValue={re_new_password} onChange={(e) => setReNewPassword(e.target.value)}/>
          </div>
          {
            /**
             * Button
             */
          }
          <div className="form-group col-md-12 text-right mt-3">
            <button
                onClick={()=>{
                    changePasword(old_password,new_password,re_new_password)
                }}
                className="btn btn-primary">Kaydet</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Section;
