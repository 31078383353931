import React, { useState, useEffect } from "react";
/**
 * Configs
 */
import config from "../configs/config.json";
/**
 * Actions
 */
import {AuthLogin, AuthLogout, setAlert} from "../actions";
import {Link} from "react-router-dom";
import Loading from "../components/loading";
import {post} from "../networking/Server";

function Page({ history }) {
  /**
   * Logout
   */
  useEffect(() => {
    AuthLogout();
  }, []);

  /**
   * States
   */
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  const [repass, setRePass] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [name, setName] = useState();
  const [surname, setSurname] = useState();

  const [gender_id, setGender] = useState(0);
  const [work_type_id, setWorkType] = useState(0);
  const [child, setChild] = useState(0);
  const [birthday, setBirthday] = useState();

  const [iagree, setIAgree] = useState(false);


  function emailControl(email) {
    if(!email)
      return;

    setLoading(true);
    post("family_control_email",{email}).then((res)=>{
      setLoading(false);
      try {
        if(res.result){
         setPage(2);
        }else {
          if(res.message=="not_found"){
            setPage(3)
          }else {
            setPage(1);
            setAlert("Email Bulunamadı")
          }

        }
      }catch (e) {

      }
    });
  }


  function register(name, surname, email, password, repass, iagree, gender_id,child,birthday) {

    if(!name.trim() || !surname.trim() || !email.trim() || !password.trim() || !iagree || !gender_id || !child|| !birthday){
      setAlert("Eksik Bilgi Girdiniz");
      return;
    }

    if(password != repass){
      setAlert("Parola Uyuşmuyor");
      return;
    }

    if(password?.length < 8){
      setAlert("Parola En Az Sekiz Karakterli Olmalıdır");
      return;
    }

    setLoading(true);
    post("register_family",{email,password,name,surname,extra:{ gender_id,children:child,birthday}}).then((res)=>{

      try {
        setLoading(false);

        if(res.result){
          setAlert("Başarı İle Kayıt Oldunuz");
          setPage(1);
          window.location.reload(false);
        }
        else{
          if(res.error === 'email_invalid'){
            setAlert("Email Geçersiz")
          } else if(res.error==='already'){
            setAlert("Bu Mail Adresi Zaten Var")
          }
        }

      }catch (e) {

      }
    });
  }

  return (
    <div className="login-container">
      <Loading loading={loading} />
      <div className="login-thumb">
        <div
          className="login-thumb-img"
          style={{
            backgroundImage: `url(${require("../assets/images/login-thumb.png")})`,
          }}
        ></div>
      </div>
      <div className="login-wrapper">
        {
          page==1?
              <div className="login-form">
                <h2 className="login-head">Aile Olarak Giriş Yap</h2>
                {/**
                 * Login Input
                 */}
                <div className="form-group mb-4 login-form-group">
                  <div
                      className="login-input-icon"
                      style={{
                        backgroundImage: `url(${require("../assets/images/icons/mail.png")})`,
                      }}
                  ></div>
                  <input
                      value={email ? email : ""}
                      className="login-input"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                {/**
                 * Login Buttons
                 */}
                <div className="btn-login-group">
                  <button
                      className="btn btn-login"
                      onClick={() =>{
                        emailControl(email)
                      }}
                  >
                    DEVAM ET
                  </button>
                </div>

              </div>:
              page==2?
                  <div className="login-form">
                    <h2 className="login-head">Aile Olarak Giriş Yap</h2>
                    {/**
                     * Login Input
                     */}
                    <div className="form-group mb-4 login-form-group">
                      <div
                          className="login-input-icon"
                          style={{
                            backgroundImage: `url(${require("../assets/images/icons/password.png")})`,
                          }}
                      ></div>
                      <input
                          value={pass ? pass : ""}
                          className="login-input"
                          type="password"
                          placeholder="Şifre"
                          onChange={(e) => setPass(e.target.value)}
                      />
                    </div>

                    <div className="text-right">
                      <Link
                          className="login-link"
                          to={"/forgot-password"}
                      >
                        Şifreni mi unuttun?
                      </Link>
                    </div>
                    {/**
                     * Login Buttons
                     */}
                    <div className="btn-login-group">
                      <button
                          className="btn btn-login"
                          onClick={() =>{
                            setLoading(true);

                            AuthLogin(email, pass, () => {
                              history.push("/");
                            },()=>{
                              setLoading(false);
                            })
                          }}
                      >
                        GİRİŞ YAP
                      </button>
                    </div>

                  </div>:
                  page==3?
                      <div className="login-form">
                        <h2 className="login-head">Kaydol</h2>
                        {/**
                         * Login Input
                         */}
                        <div className="form-group mb-4 login-form-group">
                          <div
                              className="login-input-icon"
                              style={{
                                backgroundImage: `url(${require("../assets/images/icons/user.png")})`,
                              }}
                          ></div>
                          <input
                              value={name ? name : ""}
                              className="login-input"
                              type="text"
                              placeholder="İsim"
                              onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        {/**
                         * Login Input
                         */}
                        <div className="form-group mb-4 login-form-group">
                          <div
                              className="login-input-icon"
                              style={{
                                backgroundImage: `url(${require("../assets/images/icons/user.png")})`,
                              }}
                          ></div>
                          <input
                              value={surname ? surname : ""}
                              className="login-input"
                              type="text"
                              placeholder="Soyisim"
                              onChange={(e) => setSurname(e.target.value)}
                          />
                        </div>
                        {/**
                         * Login Input
                         */}
                        <div className="form-group mb-4 login-form-group">
                          <div
                              className="login-input-icon"
                              style={{
                                backgroundImage: `url(${require("../assets/images/icons/mail.png")})`,
                              }}
                          ></div>
                          <input
                              value={email ? email : ""}
                              className="login-input"
                              type="email"
                              placeholder="Email"
                              onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div className="form-group mb-4 login-form-group">
                          <label
                              className="mb-2 col-md-12 p-0"
                              htmlFor="exampleFormControlFile1"
                          >
                            Cinsiyet
                          </label>
                          <select
                              onChange={(e)=>{
                                setGender(e.target.value)
                              }}
                              value={gender_id}
                              style={{ padding:5,}}
                              className="login-input">
                            <option value={0}>Seçim Yap</option>
                            <option value={"1"}>Erkek</option>
                            <option value={"2"}>Kadın</option>
                          </select>
                        </div>

                        <div className="form-group mb-4 login-form-group">
                          <label
                              className="mb-2 col-md-12 p-0"
                              htmlFor="exampleFormControlFile1"
                          >
                            Kaç Çocuğunuz Var
                          </label>
                          <select
                              onChange={(e)=>{
                                setChild(e.target.value)
                              }}
                              value={child}
                              style={{ padding:5,}}
                              className="login-input">
                            <option value={0}>Seçim Yap</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>Daha Fazla</option>
                          </select>
                        </div>
                        <div className="form-group mb-4 login-form-group">
                          <label
                              className="mb-2 col-md-12 p-0"
                              htmlFor="exampleFormControlFile1"
                          >
                            Doğum Tarihi
                          </label>
                          <input
                              onChange={(e)=>{
                                setBirthday(e.target.value)
                              }}
                              defaultValue={birthday} type="date" className="form-input col-md-12" />
                        </div>

                        {/**
                         * Login Input
                         */}
                        <div className="form-group mb-4 login-form-group">
                          <div
                              className="login-input-icon"
                              style={{
                                backgroundImage: `url(${require("../assets/images/icons/password.png")})`,
                              }}
                          ></div>
                          <input
                              value={pass ? pass : ""}
                              className="login-input"
                              type="password"
                              placeholder="Şifre"
                              onChange={(e) => setPass(e.target.value)}
                          />
                        </div>
                        {/*
           * Login Input
           */}
                        <div className="form-group mb-4 login-form-group">
                          <div
                              className="login-input-icon"
                              style={{
                                backgroundImage: `url(${require("../assets/images/icons/password.png")})`,
                              }}
                          ></div>
                          <input
                              value={repass ? repass : ""}
                              className="login-input"
                              type="password"
                              placeholder="Şifre Tekrarla"
                              onChange={(e) => setRePass(e.target.value)}
                          />
                        </div>
                        {/**
                         * Login Check
                         */}
                        <div className="form-group mt-5 mb-5 login-form-group">
                          <div className="login-check">
                            <input
                                type="checkbox"
                                id="check_spe"
                                checked={iagree ? iagree : false}
                                onChange={(e) => setIAgree(e.target.checked)}
                            />
                            <label className="login-check-helper" htmlFor="check_spe"></label>
                            <label className="login-check-label" htmlFor="check_spe">
                              KİŞİSEL VERİLERİN KORUNMASI SÖZLEŞMESİNİ KABUL EDİYORUM
                            </label>
                          </div>
                        </div>
                        {/**
                         * Login Buttons
                         */}
                        <div className="btn-login-group">
                          <button
                              className="btn btn-login"
                              onClick={() =>
                                  register(name, surname, email, pass, repass, iagree, gender_id,child,birthday)
                              }
                          >
                            Tamam
                          </button>
                        </div>

                        <div className="login-link-group text-center">
                          <span>Zaten Hesabınız Var Mı? </span>
                          <Link
                              className="login-link font-weight-bold"
                              to={`/login`}
                          >
                            Oturum Aç
                          </Link>
                        </div>
                      </div>:null
        }
      </div>
    </div>
  );
}

export default Page;
