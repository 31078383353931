import React from "react";

const notifications = [
  {
    head: "Lorem ipsum dolor sit amet",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    head: "Lorem ipsum dolor sit amet",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    head: "Lorem ipsum dolor sit amet",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

function Section() {
  return (
    <div className="profile-area  w-100">
      <div className="profile-area-body">
        <div className="row">
          <div className="col-md-12 text-center mt-2 mb-5 font-weight-bold">
            Bildirimlerim
          </div>
          <div className="notifications">
            {notifications
              ? notifications.map((p, i) => (
                  <div key={i} className="notification">
                    <div className="notification-head">{p.head}</div>
                    <div className="notification-desc">{p.desc}</div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Section;
