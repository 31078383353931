import React, { useEffect, useState } from "react";
import {post} from "../networking/Server";
import Loading from "../components/loading";
import Carousel from "react-multi-carousel";
import {getFile} from "../networking/getFile";

const logo = require("../assets/images/platforms/asildernegi.png");

function createDesc(desc,j_images){
  let images=[];
  try {images=JSON.parse(j_images)}catch (e) {}

  let view=[];
  let image_index=0;
  try {
    let arr_desc=desc.split("[image]");

    arr_desc.map((desc,i)=>{

      view.push(
          <p style={{whiteSpace:'break-spaces'}} className="mt-5 b">
            {desc}
          </p>
      );

      if(arr_desc.length-1!=i){
        let image = getFile(images[image_index]);
        view.push(
            <div className="justify-content-center mt-5">
             {/* <div
                  className="area-content-img mt-5 col-md-6"
                  style={{
                    backgroundImage:
                        "url("
                        + image +
                        ")",

                  }}
              ></div>*/}
              <img src={image} alt="" className="img-fluid"/>
            </div>

        )
        image_index++;
      }

    })
  }catch (e) {

  }

  return view;
}


function Page(props) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 1001},
      items: 2,
    },
    mobile: {
      breakpoint: { max: 1000, min: 0 },
      items: 1,
    },
  };

  const platform_id = parseInt(props.match.params.platform_id);

  const [platform, setPlatform] = useState({});

  useEffect(() => {
    getPlatform();
  }, []);

  function getPlatform() {
    post("api/platforms/get_one",{platform_id}).then((res) => {
      try {
        if (res.result) {
          setPlatform(res.platform)
        }
      } catch (e) {
      }
    });
  }



  let slider = [];
  let social = {};
  let address = {};

  try {
    slider=JSON.parse(platform.slider)
  }catch (e) {

  }

  try {
    address=JSON.parse(platform.address)
  }catch (e) {

  }

  try {
    social=JSON.parse(platform.social)
  }catch (e) {

  }


  return (
    <>
      <Loading loading={!platform?.platform_id}/>
      <div className="location">{platform.title}</div>
      <div className="main">
        <div className="container">
          <div className="area">
            <div className="area-body p-5">
              <div
                className="area-logo"
                style={{ backgroundImage: "url(" + platform.logo + ")" }}
              ></div>
              {/**
               * P
               */}

              <h5 className="h5 color-app mt-5 text-center">
                {platform.header}
              </h5>

              {/**
               * P
               */}
              {
                createDesc(platform.desc,platform.images)
              }

              {
                Array.isArray(slider) && slider.length?
                    <div className="mt-5">
                      <Carousel showDots  responsive={responsive}>
                        {
                          slider.map((image)=>{
                            return(
                                <div className="multi-coursel-item">
                                  <img
                                      draggable="false"
                                      className="multi-coursel-img"
                                      src={getFile(image)}
                                  />
                                </div>
                            )
                          })
                        }

                      </Carousel>
                    </div>
                    :null
              }



              <div className="d-flex justify-content-between flex-wrap">
                <div className="mt-5 d-flex justify-content-center align-items-center  flex-wrap">

                  {
                    social.fb?.url?
                        <a target={"_blank"} href={social.fb?.url}>
                          <img
                              className="mr-3"
                              src={require("../assets/images/facebook.png")}
                              width="50px"
                              height="50px"
                          />
                        </a>:null
                  }

                  {
                    social.ig?.url?
                        <a target={"_blank"} href={social.ig?.url}>
                          <img
                              className="mr-3"
                              src={require("../assets/images/instagram.png")}
                              width="50px"
                              height="50px"
                          />
                        </a>:null
                  }

                  {
                    social.tw?.url?
                        <a target={"_blank"} href={social.tw?.url}>
                          <img
                              className="mr-3"
                              src={require("../assets/images/twitter.png")}
                              width="50px"
                              height="50px"
                          />
                        </a>:null
                  }

                  {
                    social.yt?.url?
                        <a target={"_blank"} href={social.yt?.url}>
                          <img
                              className="mr-3"
                              src={require("../assets/images/youtube.png")}
                              width="50px"
                              height="50px"
                          />
                        </a>:null
                  }

                  {
                    social.in?.url?
                        <a target={"_blank"} href={social.in?.url}>
                          <img
                              className="mr-3"
                              src={require("../assets/images/linkedin.png")}
                              width="50px"
                              height="50px"
                          />
                        </a>:null
                  }

                  {/*<a target={"_blank"} href="https://www.instagram.com/asildernegi/?hl=tr">
                    <img
                        className="mr-3"
                        src={require("../assets/images/instagram.png")}
                        width="50px"
                        height="50px"
                    />
                  </a>*/}


                  {/*<span className="b color-app mt-4">
                  / @asildernegi
                  </span>*/}
                </div>
                {
                  social.web?
                      <div className="mt-5 d-flex justify-content-center align-items-center flex-wrap">
                        <img
                            className="mr-3"
                            src={require("../assets/images/www.png")}
                            width="50px"
                            height="50px"
                        />
                        <a
                            className="b color-app mt-4"
                            href={social.web}
                            target="_blank"
                        >
                          {social.web}
                        </a>
                      </div>:null
                }



              </div>
              {
                address?.title?
                    <div className="mt-5 d-flex justify-content-center" style={{width: '60%', margin: 'auto'}}>
                      <img
                          className="mr-3"
                          src={require("../assets/images/map.png")}
                          width="50px"
                          height="50px"
                      />
                      <div
                          style={{fontWeight:'bold'}}
                          className="b color-app"
                      >
                        <p style={{color:'black'}}>{address?.title}: <span style={{color:'#004892'}}>{address?.address}</span></p>
                      </div>
                    </div>:null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
