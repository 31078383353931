import React, { useEffect, useState } from "react";
import { post } from "../networking/Server";
import { getFile } from "../networking/getFile";
import Loading from "../components/loading";
import { StoryBtn, StoryModel } from "../components";
import { Link, withRouter } from "react-router-dom";
import { findWeather, getDay } from "../functions/weather";
import { getData, setData } from "../networking/SaveData";
import Banner from "../components/banner";
import { CircleSpinner } from "react-spinners-kit";

function Page(props) {
  const [state, setState] = useState({
    slider: [],
    videos: [],
    loading: true,
    notice_story: [],
    advantage_story: [],
    new_advantage_story: [],
  });

  const [originalData, setOriginalData] = useState([]);
  const [perm, setPerm] = useState(false);
  const [today_weather, setTodatWeather] = useState({});
  const [days_weather, setDaysWeather] = useState([]);
  const [counts, setCounts] = useState({});

  const [adverts, setAdverts] = useState([]);
  const [advices, setAdvice] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [family_chats, setFamilyChats] = useState([]);
  const [menu, setMenu] = useState([]);
  const [showWeather, setShowWeather] = useState(false);

  let time;

  useEffect(() => {
    controlUser();

    getVideos();
    getAdvantages();
    getSlider();

    getNoticeStory();
    getAdvantageStory();
    getNewAdvantageStory();
    // getWeather({});
    // getLocation();

    getCounts();
    getLasts();
    getMenu();
  }, []);

  async function getMenu() {
    const res = await post("api/home/get_menu");

    if (!res.result) return null;

    setMenu(res.menu);
  }

  async function getLasts() {
    const res = await post("api/home/get_lasts");

    if (!res.result) return null;

    setAdverts(res.adverts);
    setAdvice(res.advices);
    setBlogs(res.blogs);
    setFamilyChats(res.family_chats);
  }

  async function getCounts() {
    const res = await post("api/home/get_counts");

    if (!res.result) return null;

    setCounts(res.counts);
  }

  async function getWeather(coords) {
    if (getData("weather")) {
      const weather = getData("weather");

      let today_weather = convertWeather(weather.weather?.today);
      let days_weather = [];

      if (Array.isArray(weather.weather.forecasts)) {
        weather.weather.forecasts.map((weather) => {
          days_weather.push(convertWeather(weather));
        });
      }

      setPerm(true);

      setTodatWeather(today_weather);
      setDaysWeather(days_weather);
    }

    if (!coords.latitude) return;

    const weather = await post("api/home/get_weather", {
      latitude: coords.latitude,
      longitude: coords.longitude,
    });

    if (!weather.result) return null;

    setData("weather", weather);

    let today_weather = convertWeather(weather.weather?.today);
    let days_weather = [];

    if (Array.isArray(weather.weather.forecasts)) {
      weather.weather.forecasts.map((weather) => {
        days_weather.push(convertWeather(weather));
      });
    }

    setPerm(true);

    setTodatWeather(today_weather);
    setDaysWeather(days_weather);
  }

  function convertWeather(weather) {
    let _weather = findWeather(weather?.code);

    return {
      img: _weather["img"],
      title: _weather["title"],
      themp: weather?.themp,
      low: weather?.low,
      high: weather?.high,
      city: weather?.city,
      day: getDay(weather?.day),
    };
  }

  function getLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getWeather(position.coords);
        },
        (error) => {
          console.warn(error);
          setPerm(false);
        }
      );
    } else {
      setPerm(false);
    }
  }

  async function getAdvantages() {
    const res = await post("api/advantages/get", {
      size: Number.MAX_SAFE_INTEGER,
      page: 1,
      advantage_category_id: 0,
      search: "",
      rate: [0, 100],
      city: "",
    });

    if (res.result) {
      setOriginalData(res.advantages);
    }
  }

  function getSlider() {
    post("api/home/get_slider").then(async (res) => {
      try {
        if (res.result) {
          setState((prevState) => {
            return { ...prevState, slider: res.slider, loading: false };
          });
        }
      } catch (e) {
        setState((prevState) => {
          return { ...prevState, loading: false };
        });
      }
    });
  }

  function getNoticeStory() {
    post("api/notices/get_story").then((res) => {
      try {
        if (res.result) {
          setState((prevState) => {
            return {
              ...prevState,
              notice_story: convertNoticeImages(res.story),
            };
          });
        }
      } catch (e) {}
    });
  }

  function getAdvantageStory() {
    post("api/advantages/get_story").then((res) => {
      try {
        if (res.result) {
          setState((prevState) => {
            return {
              ...prevState,
              advantage_story: convertAdvantageImages(res.story),
            };
          });
        }
      } catch (e) {}
    });
  }

  function getNewAdvantageStory() {
    post("api/advantages/get_story_news").then((res) => {
      try {
        if (res.result) {
          setState((prevState) => {
            return {
              ...prevState,
              new_advantage_story: convertAdvantageImages(res.story),
            };
          });
        }
      } catch (e) {}
    });
  }

  function convertAdvantageImages(story) {
    let n_story = [];

    try {
      story.map(({ slider, advantage_id }) => {
        let image = "";
        try {
          image = JSON.parse(slider)[0];
        } catch (e) {}
        n_story.push({
          img: getFile(image),
          page: () => {
            props.history.push(`/app/advantages/details/${advantage_id}`);
          },
        });
      });
    } catch (e) {}

    return n_story;
  }

  function convertNoticeImages(story) {
    let n_story = [];

    try {
      story.map(({ image, notice_id }) => {
        n_story.push({
          img: getFile(image),
          page: () => {
            props.history.push(`/app/announcements/details/${notice_id}`);
          },
        });
      });
    } catch (e) {}

    return n_story;
  }

  function shuffle(array) {
    return array.sort(() => Math.random() - 0.5);
  }

  function controlUser() {
    post("api/auth/get_me").then((res) => {
      try {
        if (res.result) {
          setShowWeather(res.weather);

          if (!res.settings?.active) {
            props.history.push(`/login`);
          }
        } else {
          props.history.push(`/login`);
        }
      } catch (e) {}
    });
  }

  function getVideos() {
    post("api/home/get_videos").then((res) => {
      try {
        if (res.result) {
          setState((prevState) => {
            return { ...prevState, videos: res.videos };
          });
        }
      } catch (e) {}
    });
  }

  return (
    <>
      <Loading loading={state.loading} />
      <div className="location">Anasayfa</div>
      <div className="main">
        {/**
         * Search
         */}
        <Search />
        {/**
         * Weather
         */}
        {perm && showWeather && (
          <div className="weather_container mb-5">
            <div className="weather">
              <div className="weather_main">
                {/**
                 * İcon
                 */}
                <div
                  style={{ flex: 1 }}
                  className="weather_main_group d-flex align-items-center"
                >
                  <img className="weather_main_icon" src={today_weather.img} />
                </div>
                {/**
                 * City & Status
                 */}
                <div
                  style={{ flex: 1 }}
                  className="weather_main_group d-flex flex-column align-items-center  justify-content-center"
                >
                  <div className="weather_main_city">{today_weather.city}</div>
                  <div className="weather_main_status">
                    <span>{today_weather.title}</span>
                    <span>{today_weather.themp}°C</span>
                  </div>
                </div>
                {/**
                 * Day
                 */}
                <div
                  style={{ flex: 1, justifyContent: "flex-end" }}
                  className="weather_main_group"
                >
                  <div className="weather_main_day">{today_weather.day}</div>
                </div>
              </div>
              {Array.isArray(days_weather) && days_weather.length ? (
                <div className="weather_content">
                  {days_weather.map((p, i) => {
                    return (
                      <div className="weather_content_item" key={i}>
                        <div className="weather_content_item_day">{p.day}</div>
                        <img
                          className="weather_content_item_icon"
                          src={p.img}
                        />
                        <div className="weather_content_item_status">
                          {p.title}
                        </div>
                        <div className="weather_content_item_degrees">
                          <div className="weather_content_item_degree">
                            <span className="max">
                              <i className="fa fa-arrow-up" />
                            </span>
                            {p.high}°C
                          </div>
                          <div className="weather_content_item_degree">
                            {p.low}°C
                            <span className="min">
                              <i className="fa fa-arrow-down" />
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        )}
        {/**
         * Slider
         */}
        <div className="slider home-slider">
          <Banner banner_type={1} />
        </div>
      </div>
      <div className="content home-content">
        <div className="container">
          {/** Story Start */}
          <div className="row button-group">
            <StoryBtn
              label="Sana Özel"
              img={require("../assets/images/sanaozel.png")}
              color="#29bce4"
              name="sana_ozel"
            />
            <StoryBtn
              label="Duyurular"
              img={require("../assets/images/duyuru.png")}
              color="#ea892e"
              name="duyurular"
            />
            <StoryBtn
              label="Yeni Avantajlar"
              img={require("../assets/images/yeniavantajlar.png")}
              color="#7cc78a"
              name="yeni_avantajlar"
            />
          </div>
          {/** Story End */}

          {/**Videos */}
          {Array.isArray(state.videos) && state.videos.length ? (
            <div className="scroll-swiper mb-3 mt-5">
              <div className="scroll-swiper-bar">
                <div className="scroll-swiper-head">Videolarımız</div>
              </div>
            </div>
          ) : null}

          <div className="row video-group">
            {state.videos
              ? state.videos.map(({ url, title }) => {
                  return (
                    <div
                      className="col-sm"
                      style={{
                        margin: 10,
                        borderRadius: 20,
                        backgroundColor: "#85947b",
                        padding: 0,
                      }}
                    >
                      <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                          className="embed-responsive-item"
                          src={url}
                          allowFullScreen
                        ></iframe>
                      </div>
                      <span style={{ color: "white", marginBottom: 10 }}>
                        {title}
                      </span>
                    </div>
                  );
                })
              : null}
          </div>
          {/**İlanlarımız */}
          {adverts.length ? (
            <div className="scroll-swiper mb-5 mt-5">
              <div className="scroll-swiper-bar">
                <div className="scroll-swiper-head">İlanlarımız</div>
                <Link className="scroll-swiper-link" to="/app/advertisements">
                  Tümünü Gör
                </Link>
              </div>
              <div className="scroll-swiper-items">
                {adverts.map(({ advert_id, images, title }, i) => {
                  let image = "";
                  try {
                    image = JSON.parse(images)[0];
                  } catch (e) {}
                  return (
                    <Link
                      style={{ backgroundColor: "#19b1de" }}
                      className="scroll-swiper-item"
                      key={i}
                      to={"/app/advertisements_detail/" + advert_id}
                    >
                      <img
                        className="scroll-swiper-item-img"
                        src={getFile(image)}
                        draggable={false}
                      />
                      <div
                        className="scroll-swiper-item-label "
                        style={{ color: "white" }}
                      >
                        {title}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          ) : null}

          {/**Tavsiyelerimiz */}
          {advices.length ? (
            <div className="scroll-swiper mb-5 mt-5">
              <div className="scroll-swiper-bar">
                <div className="scroll-swiper-head">Tavsiyelerimiz</div>
                <Link className="scroll-swiper-link" to="/app/advices">
                  Tümünü Gör
                </Link>
              </div>
              <div className="scroll-swiper-items">
                {advices.map(({ advices_id, image, title }, i) => (
                  <Link
                    style={{ backgroundColor: "#d9e999" }}
                    className="scroll-swiper-item"
                    key={i}
                    to={"/app/advices/detail/" + advices_id}
                  >
                    <img
                      className="scroll-swiper-item-img"
                      src={getFile(image)}
                      draggable={false}
                    />
                    <div
                      className="scroll-swiper-item-label"
                      style={{ color: "black" }}
                    >
                      {title}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : null}

          {/**Köşe Yazılarımız */}
          {blogs.length ? (
            <div className="scroll-swiper mb-5 mt-5">
              <div className="scroll-swiper-bar">
                <div className="scroll-swiper-head">Köşe Yazılarımız</div>
                <Link className="scroll-swiper-link" to="/app/blogs">
                  Tümünü Gör
                </Link>
              </div>
              <div className="scroll-swiper-items">
                {blogs.map(({ blog_id, image, title }, i) => (
                  <Link
                    style={{ backgroundColor: "#7c8289" }}
                    className="scroll-swiper-item"
                    key={i}
                    to={"/app/blogs/details/" + blog_id}
                  >
                    <img
                      className="scroll-swiper-item-img"
                      src={getFile(image)}
                      draggable={false}
                    />
                    <div
                      className="scroll-swiper-item-label"
                      style={{ color: "white" }}
                    >
                      {title}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : null}
          {/**Aile Sohpetlerimiz */}
          {family_chats.length ? (
            <div className="scroll-swiper mb-5 mt-5">
              <div className="scroll-swiper-bar">
                <div className="scroll-swiper-head">Aile Sohbetlerimiz</div>
                <Link className="scroll-swiper-link" to="/app/family-talks">
                  Tümünü Gör
                </Link>
              </div>

              <div className="scroll-swiper-items">
                {family_chats
                  ? family_chats.map(({ url, title }) => {
                      return (
                        <div
                          className="scroll-swiper-item"
                          style={{ width: 350, backgroundColor: "#df8f44" }}
                        >
                          <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                              className="embed-responsive-item"
                              src={url}
                              allowFullScreen
                            ></iframe>
                          </div>
                          <span
                            style={{
                              color: "white",
                              margin: 10,
                              width: "100%",
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {title}
                          </span>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          ) : null}
          {/**Aselsan Menü */}
          {menu.length ? (
            <div className="aselsan-menu">
              <div className="aselsan-menu-bar">
                <div className="aselsan-menu-head">Aselsan Menü</div>
                <div className="aselsan-menu-date">12.09.2020</div>
              </div>
              {menu.map(({ name, calorie }, i) => (
                <div className="aselsan-menu-item" key={i}>
                  <div className="aselsan-menu-item-label">{name}</div>
                  {calorie ? (
                    <div className="aselsan-menu-item-kcal">
                      <img
                        className="aselsan-menu-item-kcal-icon"
                        src={require("../assets/images/kcal.png")}
                      />
                      <span>{calorie} kcal</span>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              ))}
            </div>
          ) : null}

          {/**Covid */}
          <div className="covid">
            {/**Covid Head */}
            {/*<div className="covid-head">
              Sağlıklı
              <img
                src={require("../assets/images/logo.png")}
                className="covid-head-logo"
              />
              olmak için 3 adımda kendini koru
            </div>*/}
            {/**Covid Group*/}
            <div className="covid-group ">
              {/**Item */}
              <div className="covid-item">
                <div className="covid-icon">
                  <img
                    src={require("../assets/images/covid/like.png")}
                    className="covid-img"
                  />
                </div>
                <div className="covid-label">Sağlıklı Yaşam</div>
              </div>
              {/**Mark */}
              <div className="covid-mark">=</div>
              {/**Item */}
              <div className="covid-item">
                <div className="covid-icon">
                  <img
                    src={require("../assets/images/covid/social.png")}
                    className="covid-img"
                  />
                </div>
                <div className="covid-label">Sosyal Mesafeyi Koruyun</div>
              </div>
              {/**Mark */}
              <div className="covid-mark">+</div>
              {/**Item */}
              <div className="covid-item">
                <div className="covid-icon">
                  <img
                    src={require("../assets/images/covid/mask.png")}
                    className="covid-img"
                  />
                </div>
                <div className="covid-label">Maske Takın</div>
              </div>
              {/**Mark */}
              <div className="covid-mark">+</div>
              {/**Item */}
              <div className="covid-item">
                <div className="covid-icon">
                  <img
                    src={require("../assets/images/covid/wash-your-hands.png")}
                    className="covid-img"
                  />
                </div>
                <div className="covid-label">Ellerinizi Yıkayın</div>
              </div>
            </div>
            {/**Covid Desc */}
            <div className="covid-desc">
              Maskeler, virüsün maskeyi takan kişiden diğer kişilere yayılmasını
              önlemeye yardımcı olabilir. Tek başına maske, COVID-19'a karşı
              koruma sağlamaz; bu önlem, fiziksel mesafe ve el hijyeni
              önlemleriyle birlikte kullanılmalıdır.
            </div>
          </div>
        </div>
      </div>
      {/** Story Models */}
      <StoryModel name="sana_ozel" stories={state.advantage_story} />
      <StoryModel name="duyurular" stories={state.notice_story} />
      <StoryModel name="yeni_avantajlar" stories={state.new_advantage_story} />
    </>
  );
}

export default Page;

function Search() {
  const [focus, setFocus] = useState(false);
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [opacity, setOpacity] = useState(0);

  function onSearch() {
    setOpacity(0);
    if (String(search).length >= 3) {
      setLoading(true);
      post("api/home/search", { search }).then((res) => {
        if (res.result) {
          setData(res.data);
        }
        setLoading(false);
      });
    } else {
      setData([]);
    }
    setTimeout(() => {
      setOpacity(1);
    }, 2000);
  }

  return (
    <>
      <div className="search-main">
        <div className="search-content">
          <input
            readOnly
            autoComplete="off"
            onFocus={() => setFocus(true)}
            type="text"
            className="search"
            id="search"
            placeholder="Tüm İçeriklerde Ara"
          />
          <label className="search-icon" htmlFor="search">
            <i className="fa fa-search"></i>
          </label>
        </div>
        {focus ? (
          <div className="search-modal">
            <div
              className="search-modal-back"
              onClick={() => setFocus(false)}
            />
            <div
              className={
                "search-modal-body animate__animated animate__faster animate__fadeIn"
              }
            >
              <div className="search-modal-title">
                <button
                  className="search-modal-close"
                  onClick={() => setFocus(false)}
                >
                  <i className="arrow fas fa-arrow-left"></i>
                  <i className="times fas fa-times"></i>
                </button>
                <div className="search-modal-head">Arama</div>
              </div>
              <div className="search-modal-input">
                <i className="fa fa-search"></i>
                <input
                  autoComplete="off"
                  type="text"
                  autoFocus={true}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyUp={() => onSearch()}
                  placeholder="Tüm İçeriklerde Ara"
                />
                <div className="pl-3 pr-3">
                  <CircleSpinner size={14} color="#000" loading={loading} />
                </div>
              </div>
              <div className="search-modal-content">
                {data && data.length > 0 ? (
                  data.map((e, i) => <SearchBox key={i} {...e} />)
                ) : !loading && String(search).length >= 3 ? (
                  <div style={{ textAlign: "center", opacity: opacity }}>
                    Üzgünüz, Aradığınız Şeyi Bulamadık
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

const SearchBox = withRouter(function ({
  id,
  title = "",
  image = "",
  description = "",
  type = "",
  url = "",
  history,
}) {
  let _img = String(image);
  if (_img.indexOf("[") >= 0) {
    _img = String(image).substring(2, _img.length - 2);
  }

  let text_color = type == 4 || type == 6 ? "#505050" : "white";

  const ADVANTAGE = 1,
    BLOG = 2,
    ADVERT = 3,
    ADVICE = 4,
    SURVEY = 5,
    NOTICE = 6,
    FAMILY = 7;

  function createCat(type) {
    if (ADVANTAGE == type) {
      return "Avantaj";
    }
    if (BLOG == type) {
      return "Köşe Yazısı";
    }
    if (ADVERT == type) {
      return "İlan";
    }
    if (ADVICE == type) {
      return "Tavsiye";
    }
    if (SURVEY == type) {
      return "Anket";
    }
    if (NOTICE == type) {
      return "Duyuru";
    }
    if (FAMILY == type) {
      return "Aile Sohbetleri";
    }

    return "";
  }

  function createColor(type) {
    if (ADVANTAGE == type) {
      return "#5b388d";
    }
    if (BLOG == type) {
      return "#7c8289";
    }
    if (ADVERT == type) {
      return "#19b1de";
    }
    if (ADVICE == type) {
      return "#d9e999";
    }
    if (SURVEY == type) {
      return "#85947b";
    }
    if (NOTICE == type) {
      return "#fdfaa2";
    }
    if (FAMILY == type) {
      return "#df8f44";
    }

    return "";
  }

  function goToLink(type, id) {
    if (ADVANTAGE == type) {
      return "/app/advantages/details/" + id;
    }
    if (BLOG == type) {
      return "/app/blogs/details/" + id;
    }
    if (ADVERT == type) {
      return "/app/advertisements_detail/" + id;
    }
    if (ADVICE == type) {
      return "/app/advices/detail/" + id;
    }
    if (SURVEY == type) {
      return "/app/surveys/" + id;
    }
    if (NOTICE == type) {
      return "/app/announcements/details/" + id;
    }
  }

  return (
    <div className={"blog-box w-100"}>
      <a
        style={{ cursor: "pointer" }}
        onClick={() =>
          FAMILY == type
            ? window.open(url, "_blank")
            : history.push(goToLink(type, id))
        }
        className="blog-box-body"
      >
        <div className="blog-box-content p-2 pt-3 pb-3">
          <div className="w-100 mb-2">
            <div
              className="search-status"
              style={{ color: text_color, backgroundColor: createColor(type) }}
            >
              {createCat(type)}
            </div>
          </div>
          <div className="d-flex">
            <div
              className="blog-box-img"
              style={{
                backgroundImage: "url(" + _img + ")",
              }}
            ></div>
            <div className="blog-box-article">
              <div className="blog-box-head" style={{ color: "black" }}>
                {title}
              </div>
              <div className="blog-box-desc" style={{ color: "black" }}>
                {description.substring(0, 240)}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
});
