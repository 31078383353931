import React, { useState, useEffect } from "react";
/**
 * Configs
 */
import config from "../configs/config.json";
/**
 * Actions
 */
import {AuthRegister, AuthLogout, setAlert} from "../actions";
import Loading from "../components/loading";
import { post } from "../networking/Server";
import { Link } from "react-router-dom";


function Page(props) {
  const code = props.match.params.token;

  const [state, setState] = useState({
    loading: true,
  });

  useEffect(() => {
    AuthLogout();
    controlCode();
  }, []);

  function controlCode() {
    post("control_code", { code }).then((res) => {
      setState((prevState) => {
        return { ...prevState, loading: false };
      });

      try {
        if (res.result) {
        } else {
          props.history.push("/login");
        }
      } catch (e) {
        props.history.push("/login");
      }
    });
  }

  function register(name, surname, email, password, repass, iagree, gender_id, work_type_id,child,birthday) {

    if(email.includes("@aselsan")){
      setAlert("Lütfen Hesap Oluştururken Kişisel Mailinizi Kullanınız");
      return;
    }

    if (
      !name.trim() ||
      !surname.trim() ||
      !email.trim() ||
      !password.trim() ||
      !iagree ||
      !gender_id ||
      !work_type_id ||
      !child ||
      !birthday
    ) {
      setAlert("Eksik Bilgi Girdiniz");
      return;
    }

    if (password != repass) {
      setAlert("Parola Uyuşmuyor");
      return;
    }

    if (password?.length < 8) {
      setAlert("Parola En Az Sekiz Karakterli Olmalıdır");
      return;
    }

    setState((prevState) => {
      return { ...prevState, loading: true };
    });
    post("register", {
      code,
      email,
      password,
      name,
      surname,
      extra: { gender_id, work_type_id, children: child, birthday },
    }).then((res) => {
      try {
        setState((prevState) => {
          return { ...prevState, loading: false };
        });

        if (res.result) {
          setAlert("Başarı İle Kayıt Oldunuz","success");
          props.history.push("/login");
        } else {
          if (res.error === "cod_invalid") {
            setAlert("Kod Geçersiz");
          } else if (res.error === "already") {
            setAlert("Bu Mail Adresi Zaten Var");
          }
        }
      } catch (e) {}
    });
  }

  /**
   * States
   */
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");

  const [gender_id, setGender] = useState(0);
  const [work_type_id, setWorkType] = useState(0);
  const [child, setChild] = useState(0);
  const [birthday, setBirthday] = useState();

  const [pass, setPass] = useState("");
  const [repass, setRePass] = useState("");
  const [iagree, setIAgree] = useState(false);

  return (
    <>
      <div className="login-container">
        <Loading loading={state.loading} />
        <div className="login-thumb">
          <div
            className="login-thumb-img"
            style={{
              backgroundImage: `url(${require("../assets/images/login-thumb.png")})`,
            }}
          ></div>
        </div>

        <div className="login-wrapper">
          {/**
           * Login Form
           */}
          <div className="login-form">
            <h2 className="login-head">Kaydol</h2>
            {/**
             * Login Input
             */}
            <div className="form-group mb-4 login-form-group">
              <div
                className="login-input-icon"
                style={{
                  backgroundImage: `url(${require("../assets/images/icons/user.png")})`,
                }}
              ></div>
              <input
                value={name ? name : ""}
                className="login-input"
                type="text"
                placeholder="İsim"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {/**
             * Login Input
             */}
            <div className="form-group mb-4 login-form-group">
              <div
                className="login-input-icon"
                style={{
                  backgroundImage: `url(${require("../assets/images/icons/user.png")})`,
                }}
              ></div>
              <input
                value={surname ? surname : ""}
                className="login-input"
                type="text"
                placeholder="Soyisim"
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
            {/**
             * Login Input
             */}
            <div className="form-group mb-4 login-form-group">
              <div
                className="login-input-icon"
                style={{
                  backgroundImage: `url(${require("../assets/images/icons/mail.png")})`,
                }}
              ></div>
              <input
                value={email ? email : ""}
                className="login-input"
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-group mb-4 login-form-group">
              <label
                className="mb-2 col-md-12 p-0"
                htmlFor="exampleFormControlFile1"
              >
                Cinsiyet
              </label>
              <select
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                value={gender_id}
                style={{ padding: 5 }}
                className="login-input"
              >
                <option value={0}>Seçim Yap</option>
                <option value={"1"}>Erkek</option>
                <option value={"2"}>Kadın</option>
              </select>
            </div>

            <div className="form-group mb-4 login-form-group">
              <label
                className="mb-2 col-md-12 p-0"
                htmlFor="exampleFormControlFile1"
              >
                Bağlı Olduğun Genel Müdür Yardımcılığı
              </label>
              <select
                onChange={(e) => {
                  setWorkType(e.target.value);
                }}
                value={work_type_id}
                style={{ padding: 5 }}
                className="login-input"
              >
                <option value={0}>Seçim Yap</option>
                <option value={1}>Genel Yönetim</option>
                <option value={2}>HBT</option>
                <option value={3}>UGES</option>
                <option value={4}>SST</option>
                <option value={5}>REHİS</option>
                <option value={6}>MGEO</option>
              </select>
            </div>

            <div className="form-group mb-4 login-form-group">
              <label
                  className="mb-2 col-md-12 p-0"
                  htmlFor="exampleFormControlFile1"
              >
                Doğum Tarihi
              </label>
              <input
                  onChange={(e) => {
                    setBirthday(e.target.value);
                  }}
                  defaultValue={birthday}
                  type="date"
                  className="form-input col-md-12"
              />
            </div>

            <div className="form-group mb-4 login-form-group">
              <label
                className="mb-2 col-md-12 p-0"
                htmlFor="exampleFormControlFile1"
              >
                Kaç Çocuğunuz Var
              </label>
              <select
                onChange={(e) => {
                  setChild(e.target.value);
                }}
                value={child}
                style={{ padding: 5 }}
                className="login-input"
              >
                <option value={0}>Seçim Yap</option>
                <option value={1}>Çocuğum Yok</option>
                <option value={2}>1</option>
                <option value={3}>2</option>
                <option value={4}>3</option>
                <option value={5}>4</option>
                <option value={6}>Daha Fazla</option>
              </select>
            </div>

            {/**
             * Login Input
             */}
            <div className="form-group mb-4 login-form-group">
              <div
                className="login-input-icon"
                style={{
                  backgroundImage: `url(${require("../assets/images/icons/password.png")})`,
                }}
              ></div>
              <input
                value={pass ? pass : ""}
                className="login-input"
                type="password"
                placeholder="Şifre"
                onChange={(e) => setPass(e.target.value)}
              />
              <div>En Az 8 Karakter Giriniz</div>
            </div>
            {/*
             * Login Input
             */}
            <div className="form-group mb-4 login-form-group">
              <div
                className="login-input-icon"
                style={{
                  backgroundImage: `url(${require("../assets/images/icons/password.png")})`,
                }}
              ></div>
              <input
                value={repass ? repass : ""}
                className="login-input"
                type="password"
                placeholder="Şifre Tekrarla"
                onChange={(e) => setRePass(e.target.value)}
              />
            </div>
            {/**
             * Login Check
             */}
            <div className="form-group mt-5 mb-5 login-form-group">
              <div className="login-check">
                <input
                  type="checkbox"
                  id="check_spe"
                  checked={iagree ? iagree : false}
                  onChange={(e) => setIAgree(e.target.checked)}
                />
                <label
                  className="login-check-helper"
                  htmlFor="check_spe"
                ></label>

                <a
                  type="button"
                  class="btn btn-link p-0 text-left login-check-label"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  KİŞİSEL VERİLERİN KORUNMASI SÖZLEŞMESİNİ KABUL EDİYORUM
                </a>
              </div>
            </div>
            {/**
             * Login Buttons
             */}
            <div className="btn-login-group">
              <button
                className="btn btn-login"
                onClick={() =>
                  register(
                    name,
                    surname,
                    email,
                    pass,
                    repass,
                    iagree,
                    gender_id,
                    work_type_id,
                    child,
                    birthday
                  )
                }
              >
                Tamam
              </button>
            </div>

            <div className="login-link-group text-center">
              <span>Zaten Hesabınız Var Mı? </span>
              <Link className="login-link font-weight-bold" to={`/login`}>
                Oturum Aç
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/**
       * Model
       */}

      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                KİŞİSEL VERİLERİN KORUNMASI SÖZLEŞMESİ
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {/**
               * KİŞİSEL VERİLERİN KORUNMASI SÖZLEŞMESİNİ
               */}

              <div style={{margin:10, alignSelf:'center',textAlign:'center',fontWeight:'bold',fontSize:20}}>ASELSAN AİLEM UYGULAMASI</div>
              <div style={{margin:10,alignSelf:'center',textAlign:'center',fontWeight:'bold',fontSize:20}}>KİŞİSEL VERİLERİN İŞLENMESİ AYDINLATMA METNİ</div>

              <div style={{margin:10,fontSize:20, fontWeight:'bold',marginVertical:10}}>a) Veri Sorumlusu ve Temsilcisi</div>
              <div style={{margin:10,fontSize:18}}>ASELSAN Elektronik Sanayi ve Ticaret A.Ş. (“ASELSAN” ve/veya Şirket), kişisel verilerinizin güvenliği hususuna azami hassasiyet göstermektedir. Bu bilinçle, Şirket ile ilişkili tüm gerçek kişilere ait her türlü kişisel verinin 6698 sayılı Kişisel Verilerin Korunması Kanunu’na uygun olarak işlenmesine ve muhafaza edilmesine büyük önem atfetmektedir. Kişisel verileriniz; veri sorumlusu olarak ASELSAN tarafından aşağıda açıklanan kapsamda işlenebilecektir.</div>
              <div style={{margin:10,fontSize:20, fontWeight:'bold',marginVertical:10}}>b) Kişisel Verilerin Hangi Amaçla İşleneceği</div>
              <div style={{margin:10,fontSize:18}}>Toplanan kişisel veriler, kişisel verilerinizi ASELSAN’a açıklamanıza konu olan ve/veya aşağıda sıralanan amaçlarla; 6698 sayılı Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları dahilinde işlenebilecektir.</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* ASELSAN tarafından gerçekleştirilen faaliyetler ile Ailem Uygulaması çerçevesinde yürütülen faaliyetlerin gerçekleştirilmesi için ilgili birimlerimiz tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi kapsamında; bilgi teknolojileri alt yapısının oluşturulması ve yönetilmesi, iş sürekliliğinin sağlanması faaliyetlerinin planlanması ve/veya icrası, kurumsal iletişim faaliyetlerinin planlanması ve/veya icrası, etkinlik yönetimi, kurumsal sürdürülebilirlik faaliyetlerin planlanması ve icrası, kazanç ve bağış faaliyet ve süreçlerinin planlanması ve icrası,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* ASELSAN Ailem üyeliğinizin oluşturulması, ASELSAN’ın etkinlik ve organizasyon yönetimi ve düzenlenmesi süreçleri, ASELSAN’ın Ailem üyelerine özel olarak sağladığı hizmet, fayda ve avantajlardan yararlandırılmanız,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* ASELSAN Ailem Uygulamasının bir sosyal platform olarak da kullanılmasıyla, kişilerin isteğine bağlı olarak profil fotoğrafı, ilgi alanları, cinsiyeti, doğum tarihleri, çalışılan sektör bilgisi gibi kişisel verilerin Şirket amaçlarına uygun olarak üyeleri tarafından görülecek Ailem platformundan yararlanmanız,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* 4857 sayılı İş Kanunu ve 5510 sayılı Sosyal Sigortalar Kanunu gereği çalışan verilerinin işlenmesi,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* 3308 Sayılı Mesleki Eğitim Kanunu, 6331 Sayılı İş Sağlığı ve Güvenliği Kanunu, 2547 Sayılı Yükseköğretim Kanunu ile ilgili diğer mevzuat ve sözleşme/protokoller kapsamında staj/mesleki eğitim/burs/eğitim programı faaliyetlerinin yürütülmesi,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* İlgili mevzuattan kaynaklanan saklama, bilgilendirme, raporlama ve mevzuatlarla tanımlanan diğer yükümlülüklerin yerine getirilmesi,</div>

              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* İlgili mevzuattan kaynaklanan saklama, bilgilendirme, raporlama ve mevzuatlarla tanımlanan diğer yükümlülüklerin yerine getirilmesi,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* 6698 sayılı Kişisel Verilerin Korunması Kanununda tanımlanan görevli veya yetkili mercilerin bilgi veya belge taleplerinin karşılanması,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* İmzalanan staj/mesleki eğitim sözleşmesinin gereğinin yerine getirilmesidir.</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* Bu hukuki sebeple sözlü, yazılı veya elektronik ortamda; ASELSAN’ın web sitesi veya e-posta gibi kanallar aracılığıyla toplanan kişisel verileriniz KVKK’nın 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında ve bu metinde belirtilen amaçlarla da işlenebilmekte ve aktarılabilmektedir.</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* ASELSAN’ın ve ASELSAN ile iş ilişkisi içerisinde olan ilgili kişilerin hukuki, teknik ve ticari iş güvenliğinin temini kapsamında, verilerin doğru ve güncel olmasının sağlanması, Şirket denetim faaliyetlerinin planlanması ve/veya icrası, Şirket faaliyetlerinin Şirket prosedürleri ve/veya ilgili mevzuata uygun olarak yürütülmesinin temini için gerekli operasyonel faaliyetlerinin planlanması ve/veya icrası, Şirket yerleşkeleri ve/veya tesislerinin güvenliğinin temini.</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* ASELSAN tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi kapsamında; müşteri ilişkileri yönetimi süreçlerinin planlanması ve/veya icrası, ürün ve/veya hizmetlerin satış süreçlerinin planlanması ve/veya icrası, müşteri talep ve/veya şikayetlerinin takibi.</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* Şirket tarafından sunulan ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili kişilere önerilmesi ve tanıtılması için gerekli olan aktivitelerin planlanması ve/veya icrası kapsamında; ürün ve/veya hizmetlerin pazarlama süreçlerinin planlanması ve/veya icrası, müşteri memnuniyeti aktivitelerinin planlanması ve/veya icrası.</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* Kişisel verilerinizin ASELSAN tarafından işlenme amaçları konusunda detaylı bilgilere; www.aselsan.com.tr internet sitesinde yer alan ASELSAN Kişisel Verilerin Korunması ve İşlenmesi Politikasından ulaşabilirsiniz.</div>
              <div style={{margin:10,fontSize:20, fontWeight:'bold',marginVertical:10}}>c) İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</div>
              <div style={{margin:10,fontSize:18}}>Toplanan kişisel verileriniz; yukarıda belirtilen amaçların gerçekleştirilmesi doğrultusunda; iş ortaklarımıza, hissedarlarımıza, tedarikçilerimize, kanunen yetkili kamu kurumlarına ve özel kişi veya kuruluşlar ile üçüncü kişilere ve yine bu amaçların yerine getirilmesi ile sınırlı olarak iştiraklerimize 6698 sayılı Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde ASELSAN Kişisel Verilerin Korunması ve İşlenmesi Politikasından belirtilen amaçlarla sınırlı olarak aktarılabilecek, yurtiçinde ve yurtdışında işlenebilecektir.</div>
              <div style={{margin:10,fontSize:20, fontWeight:'bold',marginVertical:10}}>ç) Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</div>
              <div style={{margin:10,fontSize:18}}>Kişisel verileriniz ASELSAN tarafından farklı kanallar ve farklı hukuki veya ticari sebeplere dayanarak, fiziki ve/veya elektronik ortamlarda, size fayda sağlanması, Şirket içi gerekliliklerin yerine getirilmesi/faaliyetlerin sürdürülmesi, geliştirilmesi ve/veya mevzuattan doğan yükümlülüklerin yerine getirilmesine yönelik olarak toplanmakta ve muhafaza edilmektedir.</div>
              <div style={{margin:10,fontSize:20, fontWeight:'bold',marginVertical:10}}>d) Kişisel Veri Sahibinin 6698 sayılı Kanun’un 11. Maddesinde Sayılan Hakları</div>
              <div style={{margin:10,fontSize:18}}>Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi Kişisel Verilerin Korunması ve İşlenmesi Politikasından düzenlenen yöntemlerle şirketimize iletmeniz durumunda, Şirket talebin niteliğine göre talebi en kısa sürede ve en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak işlemin ayrıca bir maliyeti gerektirmesi hâlinde, ASELSAN tarafından Kişisel Verileri Koruma Kurulunca belirlenen tarifedeki ücret alınacaktır. Bu kapsamda kişisel veri sahiplerinin sahip olduğu haklar aşağıda belirtilmiştir.</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* Kişisel verilerinin işlenip işlenmediğini öğrenme,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* Kişisel verilerinin işlendiği durumlarda buna ilişkin bilgi talep etme,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* Kişisel verilerinin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* Kişisel verilerinin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* 6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinin silinmesini veya yok edilmesini isteme ve bu istek kapsamında yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</div>
              <div style={{margin:10,marginLeft:5,fontSize:18,marginTop:5}}>* İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme ve</div>
              <div style={{marginLeft:5,fontSize:18,marginTop:5}}>* Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</div>
              <div style={{margin:10,fontSize:20, fontWeight:'bold',marginVertical:10}}>e) Kişisel Verilerin Saklanma Süresi</div>
              <div style={{margin:10,fontSize:18}}>İşbu Aydınlatma Metninde anılan kanallar vasıtasıyla ASELSAN ile paylaşmış olduğunuz kişisel veriler, 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ilgili mevzuatta süre öngörülmüş ise, öngörülen sürelere uygun şekilde saklanacaktır. Kanun ve ilgili mevzuatta süre öngörülmüş ise anılan sürenin sona ermesi akabinde veya herhangi bir süre öngörülmemiş olması durumunda veri hakkında yapılacak işlemler ASELSAN Kişisel Verilerin Korunması ve İşlenmesi Politikasında belirtilmiştir.</div>
              <div style={{margin:10,fontSize:18}}>6698 sayılı Kişisel Verilerin Korunması Kanunu’nun “Veri Sorumlusuna Başvuru” başlıklı 13. Maddesinin 1. Fıkrası uyarınca, yukarıda belirtilen haklarınızı kullanmak ve ilgili talebinizi, yazılı veya Kişisel Verileri Koruma Kurulu’nun belirlediği diğer yöntemlerle Şirket’e iletebilirsiniz. Başvurunuzu 6698 sayılı Kişisel Verilerin Korunması Kanunu gereğince yazılı olarak Şirket’e iletmeniz gerekmektedir. Bu çerçevede Şirket’e ilgili kanun kapsamında yapacağınız başvurularda yazılı olarak başvurunuzu ileteceğiniz kanallar ve usuller aşağıda açıklanmaktadır.</div>
              <div style={{margin:10,fontSize:18}}>Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi belirli ve anlaşılır yapacak gerekli bilgiler ile 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde belirtilen hakları kullanmayı talep ettiğiniz hakkınıza yönelik açıklamalarınızı içeren talebinizi; www.aselsan.com.tr adresinde yer alan formu doldurarak formun imzalı bir nüshasını kvk@aselsan.com.tr e-posta adresine veya Mehmet Akif Ersoy Mah. 296. Cad. No:16 Yenimahalle/ANKARA adresine kimliğinizi tespit edici belgeler ile bizzat elden iletebilir, noter kanalıyla veya 6698 sayılı Kişisel Verilerin Korunması Kanunu’nda belirtilen diğer yöntemler ile gönderebilir veya ilgili formu ASELSAN’ın KEP adresi olan aselsan@hs02.kep.tr adresine güvenli elektronik imzalı olarak iletebilirsiniz.</div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn custom-btn">
                Onaylıyorum
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
