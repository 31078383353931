import React, { useEffect, useState } from "react";
import Loading from "../components/loading";
import {c_specifications} from "../functions/AdvertSpecifications";
import {post} from "../networking/Server";
import $ from "jquery";
import {goTop} from "../functions/goTop";
import {setAlert} from "../actions";

const options = [{}];

function Page(props) {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [web_site, setWeb] = useState("");
  const [address, setAddress] = useState("");
  const [advert_category_id, setCategoty] = useState(0);
  const [description, setDesc] = useState("");
  const [specifications, setSpecifications] = useState([]);


  useEffect(() => {

    setSpecifications(c_specifications[advert_category_id])
  }, [advert_category_id]);


  function addAdvert() {


    if (!images?.length) {
      setAlert("En Az Bir İlan Resmi Ekleyiniz")
      return;
    }

    if (!title.trim()) {
      setAlert("İlan Başlığı Boş Olamaz")
      return;
    }

    if (!advert_category_id) {
      setAlert("İlan kategorisi Seçiniz")
      return;
    }

    if (!description.trim()) {
      setAlert("İlan Açıklaması Boş Olamaz")
      return;
    }

    if (!address.trim()) {
      setAlert("İlan Adresi Boş Olamaz")
      return;
    }


    if (!Array.isArray(specifications) || !specifications.length) {
      setAlert("En Az Bir Özellik Ekleyiniz")
      return;
    }

    if (!controlSpecifications(specifications)) {
      setAlert("Lütfen Özellikleri Doldurunuz")
      return;
    }


    setLoading(true);

    goTop()


    let formData = new FormData();

    Array.from(images).forEach(e => formData.append("banner", e))
    formData.append("title", title)
    formData.append("specifications", JSON.stringify(specifications))
    formData.append("description", description)
    formData.append("advert_category_id", advert_category_id)
    formData.append("address", address)
    formData.append("phone", phone)
    formData.append("email", email)
    formData.append("web_site", web_site)

    formData.append("web", "1");

    post("api/a-web/add",formData).then((res)=>{
      setLoading(false);
      try {
        if(res.result){

          props.history.goBack()
        }
      }catch (e) {

      }
    })
  }

  function controlSpecifications(data){
    let control = true;
    try {
      if(!data?.length)
        return false;

      data.map(({title,desc})=>{
        if(!title.trim() || !desc.trim())
          control=false;
      })
    }catch (e) {
      return false;
    }

    return control;
  }

  return (
    <>
      <Loading loading={loading}/>
      <div className="location">İlanlarımız</div>
      <div className="main">
        <div className="container">
          <div className="form-area mt-5 mb-5">
            <div className="form-head mb-5">İlan Ver</div>
            {/**
             * Form Start
             */}
            <div className="row">
              {/**
               * İlan Fotoğrafı
               */}
              <div className="form-group col-md-6">
                <label className="mb-4 p-0" htmlFor="photo">
                  İlan Fotoğrafı
                </label>
                <input onChange={e => {setImages(e.target.files)}} accept="image/*"  multiple={true} type="file" className="form-control-file" id="photo" />
              </div>
              {/**
               * İlan Başlığı
               */}
              <div className="form-group col-md-6">
                <label className="mb-2 col-md-12 p-0" htmlFor="head">
                  İlan Başlığı
                </label>
                <input onChange={(e)=>{setTitle(e.target.value)}} defaultValue={title} className="form-input col-md-12" />
              </div>
              {/**
               * Telefon Numarası
               */}
              <div className="form-group col-md-6">
                <label className="mb-2 col-md-12 p-0" htmlFor="head">
                  Telefon Numarası
                </label>
                <input onChange={(e)=>{setPhone(e.target.value)}} defaultValue={phone} className="form-input col-md-12" />
              </div>
              {/**
               * Email
               */}
              <div className="form-group col-md-6">
                <label className="mb-2 col-md-12 p-0" htmlFor="head">
                  Email
                </label>
                <input onChange={(e)=>{setEmail(e.target.value)}} defaultValue={email} className="form-input col-md-12" />
              </div>
              {/**
               * Kategori
               */}
              <div className="form-group col-md-6">
                <label className="mb-2 col-md-12 p-0" htmlFor="head">
                  Kategori
                </label>
                <select onChange={(e)=>{
                  setCategoty(e.target.value)
                }} defaultValue={advert_category_id}  className="form-input col-md-12">
                  <option value={0}>Kategori Seç</option>
                  <option value={1}>Araç</option>
                  <option value={2}>Gayrimenkul</option>
                  <option value={3}>Eşya</option>
                  <option value={4}>Diğer</option>
                </select>
              </div>
              {/**
               * Websitesi
               */}
              <div className="form-group col-md-6">
                <label className="mb-2 col-md-12 p-0" htmlFor="head">
                  Websitesi
                </label>
                <input onChange={(e)=>{setWeb(e.target.value)}} defaultValue={web_site} className="form-input col-md-12" />
              </div>
              {/**
               * Adress
               */}
              <div className="form-group col-md-6">
                <label className="mb-2 col-md-12 p-0" htmlFor="short_desc">
                  Adres
                </label>
                <textarea
                    onChange={(e)=>{setAddress(e.target.value)}} defaultValue={address}
                    id="short_desc"
                  className="form-input col-md-12"
                ></textarea>
              </div>
              {/**
               * Açıklama
               */}
              <div className="form-group col-md-6">
                <label className="mb-2 col-md-12 p-0" htmlFor="short_desc">
                  Açıklama
                </label>
                <textarea
                    onChange={(e)=>{setDesc(e.target.value)}} defaultValue={description}
                    id="short_desc"
                  className="form-input col-md-12"
                ></textarea>
              </div>
              {/**
               * İlan Bilgileri
               */}
              <div className="form-group col-md-12">
                <label className="mb-2 col-md-12 p-0" htmlFor="short_desc">
                  İlan Bilgileri
                </label>
                <div className="advertisement-grid ">
                  <div className="row">
                    {specifications.map(({title,desc,constant}, i) => (
                      <div
                        className="advertisement-grid-item col-md-6 col-sm-12"
                        key={Math.random()*(i+1)}
                      >
                        {
                          !constant?
                              <div onClick={()=>{
                                specifications.splice(i, 1)
                                setSpecifications(JSON.parse(JSON.stringify(specifications)))
                              }} className="advertisement-grid-item-close">
                                <i className="fa fa-times"></i>
                              </div>:null
                        }
                        <div className="advertisement-grid-item-body">
                          <div className="row pr-3 pl-3">
                            {/**
                             * Name
                             */}
                            <input
                                onChange={(event)=>{
                                  specifications[i]['title']=event.target.value;
                                  setSpecifications(specifications)
                                }}
                                placeholder={"Özellik"} disabled={constant} defaultValue={title} className="form-input col-md-12 advertisement-grid-item-name" />
                            {/**
                             *Lamel
                             */}
                            <div className="col-4 advertisement-grid-item-lamel"></div>
                            {/**
                             * Value
                             */}
                            <input
                                onChange={(event)=>{
                                  specifications[i]['desc']=event.target.value;
                                  setSpecifications(specifications)
                                }}
                                placeholder={desc}  className="form-input col-8 advertisement-grid-item-value mt-3" />
                          </div>
                        </div>
                      </div>
                    ))}
                    {/**
                     * Seçenek Ekle
                     */}
                    <div className="col-md-6 col-sm-12 p-4 d-flex align-items-end">
                      <button
                          onClick={()=>{
                            specifications.push({title:"",desc:""});
                            setSpecifications(JSON.parse(JSON.stringify(specifications)))
                          }}
                          className="btn btn-special-advertisement w-100">
                        Seçenek Ekle
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/**
             * Button
             */}
            <div className="text-center mt-4">
              <button
                  onClick={()=>{
                      addAdvert()
                  }}
                  className="btn form-btn">İlan Ver</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
