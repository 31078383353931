/* STORE */
import store from "../helpers/store";
/* STATİCS */
const SET_ALERT = "SET_ALERT";
const REMOVE_ALERT = "REMOVE_ALERT";
/* FUNCTİONS */

export function setAlert(message, color = "danger", time = 5000) {
  store.dispatch({
    type: SET_ALERT,
    message: message,
    color: color,
  });

  setTimeout(() => {
    removeAlert();
  }, time);
}

export function removeAlert() {
  store.dispatch({
    type: REMOVE_ALERT,
  });
}
