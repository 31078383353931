import React from "react";
import { connect } from "react-redux";

function AlertBar({ message = "", color = "" }) {
  return (
    <div className="alertbar">
      <div className={"alert alert-" + color} role="alert">
        {message}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  message: state.alertReducer.message,
  color: state.alertReducer.color,
});

export default connect(mapStateToProps)(AlertBar);
