import config from "../configs/config.json";
import Axios from "axios";

const url = (endpoint) => {
  return `${config["api-url"]}/${endpoint}`;
};

const post = (endpoint, data = {}) => {
  return Axios.post(url(endpoint), data).then((res) => res.data);
};
const get = (endpoint, data = {}) => {
  return Axios.get(url(endpoint), data).then((res) => res.data);
};
const del = (endpoint, data = {}) => {
  return Axios.delete(url(endpoint), data).then((res) => res.data);
};
const put = (endpoint, data = {}) => {
  return Axios.put(url(endpoint), data).then((res) => res.data);
};

export { post, get, del, put };
