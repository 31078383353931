import React from "react";

class Loading extends React.Component{

    constructor(props){
        super(props);

    }

    render(){
        return (
            <>
                    <span className={"loading fas fa-spinner fa-3x fa-spin " + (this.props.loading?"is-active":"")}></span>
            </>
        )
    }
}

export default Loading
