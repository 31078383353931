import React, { useEffect, useState } from "react";
import { Link, Switch, Route, withRouter } from "react-router-dom";
import config from "../configs/config.json";

import {
  ProfileInfoSection,
  ProfileSettingsSection,
  ProfileFamilyMembersSection,
  ProfileNotificationsSection,
  ProfileFrameSection,
} from ".";
import { post } from "../networking/Server";
import Loading from "../components/loading";
import { getFile } from "../networking/getFile";
import { getFrame } from "../functions/frames";

const grid = [
  {
    title: "Bilgilerimi Görüntüle",
    icon: "address-card",
    color: "#014189",
    to: `/app/profile/info`,
  },
  {
    title: "Favorilerim",
    icon: "heart-o",
    color: "#e26776",
    to: `/app/favorites`,
  },
  {
    title: "Köşe Yazılarım",
    icon: "newspaper-o",
    color: "#b6e45a",
    to: `/app/myblogs`,
  },
  {
    title: "Tavsiyelerim",
    icon: "comments-o",
    color: "#F5F749",
    to: `/app/myadvices`,
  },
  {
    title: "İlanlarım",
    icon: "vcard-o",
    color: "#b75d69",
    to: `/app/myadvertisements`,
  },
  {
    title: "Ayarlar",
    icon: "cog",
    color: "#f3a277",
    to: `/app/profile/settings`,
  },
  {
    title: "Aile Üyeleri",
    icon: "users",
    color: "#a285b8",
    to: `/app/profile/family-members`,
  },
  {
    title: "Çıkış Yap",
    icon: "sign-out",
    color: "#b2e2f7",
    to: `/login`,
  },
];
const frame = require("../assets/images/frames/1.png");

function Page({ location, history }) {
  const [state, setState] = useState({
    loading: true,
    profile: {},
  });

  useEffect(() => {
    history.push("/app/profile/info");
    getProfile();
  }, []);

  function getProfile() {
    post("api/users/get").then((res) => {
      try {
        if (res.result && res.user) {
          setState((prevState) => {
            return { ...prevState, profile: res.user, loading: false };
          });
        }
      } catch (e) {}
    });
  }

  function onLogout() {
    post("api/users/logout").then((res) => {
      history.push("/login");
    });
  }

  var { pathname } = location;
  const { name, surname, image, frame } = state.profile;

  return (
    <>
      {/*<Loading loading={state.loading} />*/}
      <div className="location">Profilim</div>
      <div className="main">
        <div className="container ">
          <div className="row mt-5 mb-5">
            <div className="profile-area col-md-12 mb-2">
              <div className="profile-area-body row">
                {/**
                 * Profile photo
                 */}
                <div className="profile-area-photo-cont">
                  <div className="profile-area-photo">
                    <div
                      className="profile-area-photo-frame"
                      style={{
                        backgroundImage: "url(" + getFrame(frame) + ")",
                      }}
                    >
                      {
                        image?
                            <div
                                className="profile-area-photo-img"
                                style={{
                                  backgroundColor: "#909090",
                                  backgroundImage: "url(" + getFile(image) + ")",
                                }}
                            ></div>:
                            <img
                                className="profile-area-photo-img"
                                src={require("../assets/images/user.png")}
                                style={{
                                  backgroundColor: "#909090",
                                }}
                            />
                      }
                    </div>
                  </div>
                </div>
                {/**
                 * Profile Name
                 */}
                <div className="profile-area-name-cont">
                  <div className="profile-area-name">
                    {(name ? name : "") + " " + (surname ? surname : "")}
                  </div>
                </div>
                {/**
                 * Btn Bar
                 */}
                <div className="profile-area-btn-bar">
                  {/* <Link to={`/profile/notifications`}>
                    <i className="fa fa-bell" />
                  </Link>*/}
                  <Link to={`/app/profile/settings`}>
                    <i className="fa fa-wrench" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row d-flex flex-wrap flex-md-column flex-sm-column-reverse">
              <div className="profile-area col-md-4">
                <div className="profile-area-body">
                  <div className="profile-area-grid row">
                    {/**
                     * Grit Item Start
                     */}
                    {grid
                      ? grid.map((p, i) => {
                          var active = false;
                          if (pathname == p.to) {
                            var active = true;
                          }

                          return (
                            <div
                              key={i}
                              className={
                                "profile-area-grid-item col-lg-6 col-md-12" +
                                (active ? " active" : "")
                              }
                            >
                              {
                                p.to=="/login"?
                                    <div
                                        className="profile-area-grid-col"
                                        onClick={onLogout.bind(this)}
                                    >
                                      <i
                                          className={
                                            "profile-area-grid-item-icon fa fa-" +
                                            p.icon
                                          }
                                          style={{ color: p.color }}
                                      ></i>
                                      <div className="profile-area-grid-item-text">
                                        {p.title}
                                      </div>
                                    </div>:
                                    <Link className="profile-area-grid-col" to={p.to}>
                                      <i
                                          className={
                                            "profile-area-grid-item-icon fa fa-" +
                                            p.icon
                                          }
                                          style={{ color: p.color }}
                                      ></i>
                                      <div className="profile-area-grid-item-text">
                                        {p.title}
                                      </div>
                                    </Link>
                              }
                            </div>
                          );
                        })
                      : null}
                    {/**
                     * Grit Item End
                     */}
                  </div>
                </div>
              </div>
              <div className="col-md-8 p-0">
              {/**
               * Area Sections
               */}
                {
                  state?.loading &&
                      <span className={"inner-loading fas fa-spinner fa-3x fa-spin"}></span>

                }
              <Switch>
                {state.profile?.name ? (
                  <Route
                    path="/app/profile/info"
                    render={(props) => (
                      <ProfileInfoSection
                        {...props}
                        getProfile={getProfile.bind()}
                        profile={state.profile}
                      />
                    )}
                    exact
                  />
                ) : null}

                <Route
                  path="/app/profile/settings"
                  render={(props) => <ProfileSettingsSection {...props} />}
                  exact
                />
                <Route
                  path="/app/profile/family-members"
                  render={(props) => <ProfileFamilyMembersSection {...props} />}
                  exact
                />
                <Route
                  path="/app/profile/notifications"
                  render={(props) => <ProfileNotificationsSection {...props} />}
                  exact
                />
                <Route
                  path="/app/profile/frame"
                  render={(props) => (
                    <ProfileFrameSection {...props} frame_id={frame} />
                  )}
                  exact
                />
              </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Page);
