import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Route, Redirect } from "react-router";
//Redux
import { Provider } from "react-redux";
import store from "./helpers/store";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { AlertBar } from "./layouts";
import { RouterScroll } from "./components";

/**
 * Pages
 */
import {
  LoginPage,
  FamilyLoginPage,
  RegisterPage,
  ForgotPasswordPage,
  ForgotPasswordCodePage,
} from "./pages";
import { Beforeunload } from "react-beforeunload";
import { post } from "./networking/Server";

ReactDOM.render(
  <Provider store={store}>

    <Beforeunload
      onBeforeunload={async (event) => {
        /*event.preventDefault()

      let a =await post('api/users/disconnect');
      console.warn(a)*/
      }}
    >
      <React.StrictMode>
        <Router>
          <RouterScroll>
            <AlertBar />
            <Switch>
              <Route
                path="/login"
                render={(props) => <LoginPage {...props} />}
                exact
              />
              <Route
                path="/family-login"
                render={(props) => <FamilyLoginPage {...props} />}
                exact
              />
              <Route
                path="/register/:token"
                render={(props) => <RegisterPage {...props} />}
                exact
              />
              <Route
                path="/forgot-password"
                render={(props) => <ForgotPasswordPage {...props} />}
                exact
              />
              <Route
                path="/forgot-password-code"
                render={(props) => <ForgotPasswordCodePage {...props} />}
                exact
              />

              <Route path="/app" component={(props) => <App {...props} />} />

              <Redirect path="/" to="/app" />
            </Switch>
          </RouterScroll>
        </Router>
      </React.StrictMode>
    </Beforeunload>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
