export function cutText(text, length) {
    try {
        if(text.length>length)
            return text.slice(0,length)+"...";
    }catch (e) {

    }

    return text
}
