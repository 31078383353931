import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {post} from "../networking/Server";
import Pagination from "../components/pagination";
import Loading from "../components/loading";
import {cutText} from "../functions/cutText";

const categorys = [
  {
    img:
        require("../assets/images/adverts/car.png"),
    id: 1,

    text: "Araç",
    color: "#28bce4",
  },
  {
    img:
        require("../assets/images/adverts/home-run.png"),
    id: 2,
    text: "Gayrimenkul",
    color: "#e68631",
  },
  {
    img:
        require("../assets/images/adverts/esya.png"),
    id: 3,
    text: "Eşya",
    color: "#f20058",
  },
  {
    img:
        require("../assets/images/adverts/other.png"),
    id: 4,
    text: "Diğer",
    color: "#014189",
  },
];

function Page(props) {
  const [loading, setLoading] = useState(false);
  const [adverts, setAdverts] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(9);
  const [category, setCategory] = useState(parseInt(props.match.params.category));
  const [max_size, setMaxSize] = useState(0);


  useEffect(() => {
    getAdverts();
  }, []);

  useEffect(() => {
    getAdverts();
  }, [category, size, page]);


  async function getAdverts() {
    setLoading(true);
    const data = await post("api/a-web/get", {size, page, advert_category_id: category});

    if (data.result) {
      setMaxSize(data.max_size)
      setAdverts(data.adverts);
    }
    setLoading(false);
  }

  let title="";
  try {
    title=categorys[parseInt(props.match.params.category)-1]['text']
  }catch (e) {

  }


  return (
    <>
      <Loading loading={loading}/>
      <div className="location">İlanlarımız</div>
      <div className="main">
        <div className="container">
          <div className="advertisements-container">
          <div className="advertisement-head">{title} İlanlarımız</div>
            {/**
             * Advertisements
             */}
            {
              !loading && Array.isArray(adverts) && !adverts.length?
                  <div style={{display:'flex',justifyContent:'center',fontWeight:'center',fontSize:20}}>Bu kategoride herhangi bir ilan bulunmamaktadır.</div>:null

            }
            <div className="row">

              {adverts
                ? adverts.map(({advert_id,images,title,address}, i,p={}) => {
                  let image="";
                  try {
                    image=JSON.parse(images)[0]
                  }catch (e) {

                  }

                  return(
                      <Link
                          className="advertisement-box col-lg-4 col-md-6 col-sm-12"
                          key={i}
                          to={"/app/advertisements_detail/" + advert_id}
                      >
                        <div className="advertisement-box-body">
                          <div
                              className="advertisement-box-img"
                              style={{ backgroundImage: "url(" + image + ")" }}
                          ></div>
                          <div className="d-flex justify-content-between">
                            <div className="advertisement-box-text">{title}</div>
                            <div className="advertisement-box-adress">
                              <i className="fa fa-map-marker mr-2"></i>
                              {cutText(address,100)}
                            </div>
                          </div>
                        </div>
                      </Link>
                  )
                  })
                : null}
            </div>
          </div>
        </div>

        <Pagination
            max_size={max_size}
            page={page}
            size={size}
            onChangeSize={(_size) => {
              setSize(_size)
            }}
            onChange={(_page) => {
              setPage(_page)
            }}
        />
      </div>
    </>
  );
}

export default Page;
