import React from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import {goTop} from "../functions/goTop";

function StoryModel({name, stories = []}) {
    return (
        <div
            className="modal fade story-model"
            id={name}
            tabindex="-1"
            role="dialog"
            aria-labelledby="sdasd"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true"><i className="fa fa-times"></i></span>
                        </button>
                        <div className="story-model-slider">
                            <div
                                id={name + "_ind"}
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <ol className="carousel-indicators">
                                    {stories
                                        ? stories.map((p, i) => (
                                            <li
                                                data-target={"#" + name + "_ind"}
                                                data-slide-to="0"
                                                className={i == 0 ? "active" : ""}
                                            ></li>
                                        ))
                                        : null}
                                </ol>
                                <div className="carousel-inner">
                                    {stories
                                        ? stories.map((p, i) => {
                                            return (
                                                <Link
                                                    onClick={() => {
                                                        const body = $("html, body");
                                                        goTop()
                                                        p.page()
                                                    }}
                                                    data-dismiss="modal"
                                                    className={
                                                        " carousel-item" + (i == 0 ? " active" : "")
                                                    }
                                                    //to={`/app/advantages/details/${p.advantage_id}`}
                                                    key={i}
                                                >
                                                    <div
                                                        className="carousel-img"
                                                        style={{
                                                            backgroundImage: "url(" + p.img + ")",
                                                        }}
                                                    ></div>
                                                </Link>
                                            );
                                        })
                                        : null}
                                </div>
                                <a
                                    className="carousel-control-prev"
                                    href={"#" + name + "_ind"}
                                    role="button"
                                    data-slide="prev"
                                >
                  <span
                      style={{backgroundColor: '#909090', borderRadius: 10, padding: 20}}
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                  ></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a
                                    className="carousel-control-next"
                                    href={"#" + name + "_ind"}
                                    role="button"
                                    data-slide="next"
                                >
                  <span
                      style={{backgroundColor: '#909090', borderRadius: 10, padding: 20}}

                      className="carousel-control-next-icon"
                      aria-hidden="true"
                  ></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StoryModel;
