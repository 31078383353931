import React, {useEffect, useState, useRef} from "react";
import {Link} from "react-router-dom";
import {post} from "../networking/Server";
import Loading from "../components/loading";
import {getFile} from "../networking/getFile";
import Pagination from "../components/pagination";
import {addFav} from "../functions/addFav";
import {goTop} from "../functions/goTop";
import {Range} from "rc-slider";
import {cutText} from "../functions/cutText";

function Page(props) {
    const [state, setState] = useState({
        loading: true,
        page: 1,
        advantages: [],
        categories: [],
        size: 9,
    });
    const [max_size, setMaxSize] = useState(0);

    const [category, setCategory] = useState(0);

    const [height, setHeight] = useState(0);
    const [citys, setCitys] = useState([]);
    const [city, setCity] = useState("");
    const [rate, setRate] = useState([0, 100]);

    let advantageRef = useRef(null);

    let search = props.match.params.search;

    useEffect(() => {
        getCategories();
        getAdvantages(state.page);
    }, []);

    useEffect(() => {
        setHeight(advantageRef.current?.clientHeight);
    }, [state]);

    useEffect(() => {
        search = "";
        getAdvantages(1);
    }, [category]);

    function getCategories() {
        post("api/advantages/get_categories").then((res) => {
            try {
                if (res.result) {
                    setHeight(0);

                    setCitys(res.citys[0]);

                    setState((prevState) => {
                        return {...prevState, categories: res.categories};
                    });
                }
            } catch (e) {
            }
        });
    }

    function getAdvantages(page, size = state.size, clear = false) {
        post("api/advantages/get", {
            web:true,
            size,
            page,
            city: clear ? "" : city ? city : "",
            rate: rate,
            advantage_category_id: clear ? 0 : parseInt(category),
            search: clear ? "" : search ? search : "",
        }).then((res) => {
            try {
                if (res.result) {
                    setMaxSize(res.max_size);


                    setState((prevState) => {
                        return {
                            ...prevState,
                            advantages: res.advantages.slice((page-1)*size,(page-1)*size+size),
                            loading: false,
                            page,
                            size,
                        };
                    });
                    setHeight(1);
                }
            } catch (e) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        loading: false
                    };
                });
            }
        });
    }



    return (
        <>
            <Loading loading={state.loading}/>
            <div className="location">Asil Avantajlar</div>
            <div className="main">
                <div className="container">
                    <div className="row">
                        {/**
                         * Bar Start
                         */}
                        <div
                            className="col-sm-12 col-lg-3 advantage-categories"
                            style={{height, maxWidth: "min-content"}}
                        >
                            {/**
                             * ALL BUTTON
                             */}
                            {
                                !state.loading?
                                    <div className="advantages-bar-item">
                                        <button
                                            onClick={() => {
                                                goTop();
                                                setState((prevState) => {
                                                    return {...prevState, loading: true};
                                                });
                                                setCategory('0');
                                            }} className="btn custom-btn w-100 p-2"
                                            style={{paddingBottom: 0.5 + 'rem', paddingTop: 0.5 + 'rem'}}>
                                            Tüm Avantajlar
                                        </button>
                                    </div>:null
                            }

                            {/** */}
                            {state.categories
                                ? state.categories.map(
                                    ({icon, name, advantage_category_id}, i) => (
                                        <div
                                            key={i}
                                            onClick={() => {
                                                goTop();
                                                setState((prevState) => {
                                                    return {...prevState, loading: true};
                                                });
                                                setCategory(advantage_category_id);
                                            }}
                                            className="advantages-bar-item"
                                        >
                                            <img
                                                style={{
                                                    backgroundColor:
                                                        category == advantage_category_id ? "white" : "",
                                                    width: 70,
                                                    height: 70,
                                                    alignSelf: "center",
                                                }}
                                                src={getFile(icon)}
                                                alt=""
                                            />
                                            <span
                                                style={{
                                                    fontAlign: "center",
                                                    fontWeight:
                                                        category == advantage_category_id ? "bold" : null,
                                                }}
                                            >
                          {name}
                        </span>
                                        </div>
                                    )
                                )
                                : null}
                        </div>
                        <div
                            ref={advantageRef}
                            className="advantages-menu col-sm-12 col-lg-9"
                        >
                            {!state.loading ? (
                                <div className="mb-5 mt-2">
                                    <a
                                        className="btn btn-custom-filter"
                                        data-toggle="modal"
                                        data-target="#filterModal"
                                    >
                                        <i className="fa fa-filter mr-2"></i>
                                        Filtrele
                                    </a>
                                </div>
                            ) : null}
                            {
                                !state.loading && Array.isArray(state.advantages) && !state.advantages.length?
                                    <div style={{textAlign:'center', justifyContent:'center',fontWeight:'center',fontSize:20}}>Şuan için bu kategoride avantajımız bulunmamaktadır. Avantaj öneriniz varsa <Link to={"contact-us"}>Bize Ulaşın</Link> sekmesinden iletebilirsiniz.</div>:null

                            }
                            <div className={"row"}>
                                {state.advantages &&
                                state.advantages.map(
                                    (
                                        {
                                            advantage_id,
                                            company,
                                            slider,
                                            advantage_favorites,
                                            title,
                                            discount_rate,
                                            discount_price,
                                        },
                                        i
                                    ) => {
                                        let image = "";
                                        try {
                                            image = JSON.parse(slider)[0];
                                        } catch (e) {
                                        }

                                        return (
                                            <div
                                                className="advantages-menu-item col-lg-4 col-md-6 col-sm-12"
                                                key={i}
                                            >
                                                <Link
                                                    className="advantages-menu-item-top"
                                                    to={`/app/advantages/firma/${company?.company_id}`}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className="advantages-menu-title-img"
                                                            style={{
                                                                backgroundImage:
                                                                    "url(" + getFile(company?.logo) + ")",
                                                            }}
                                                        />
                                                        <div className="advantages-menu-item-title">
                                                            {cutText(company?.name,35)}
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="advantages-menu-item-content">
                                                    <Link
                                                        className="advantages-menu-item-img d-block"
                                                        style={{
                                                            backgroundImage: "url(" + getFile(image) + ")",
                                                        }}
                                                        to={`/app/advantages/details/${advantage_id}`}
                                                    >
                                                        {discount_price ? (
                                                            <div className="advantages-menu-item-img-price">
                                                                {discount_price}₺
                                                            </div>
                                                        ) : null}
                                                        {discount_rate ? (
                                                            <div className="advantages-menu-item-img-disc">
                                                                {discount_rate}%
                                                            </div>
                                                        ) : null}
                                                    </Link>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <Link
                                                            className="advantages-text"
                                                            to={`/app/advantages/details/${advantage_id}`}
                                                        >
                                                            {title}
                                                        </Link>
                                                        <div>
                                                            {advantage_favorites?.length ? (
                                                                <div
                                                                    onClick={() => {
                                                                        addFav(setState, advantage_id).then(
                                                                            () => {}
                                                                        );

                                                                        let advantages = state.advantages;
                                                                        advantages[i]['advantage_favorites']=[];
                                                                        setState((prevState) => {
                                                                            return {
                                                                                ...prevState,
                                                                                advantages,
                                                                            };
                                                                        });
                                                                    }}
                                                                >
                                                                    <i className="advantages-heart fa fa-heart favorite-heart"/>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    onClick={() => {
                                                                        addFav(setState, advantage_id).then(
                                                                            () => {}
                                                                        );

                                                                        let advantages = state.advantages;
                                                                        advantages[i]['advantage_favorites']=[""];
                                                                        setState((prevState) => {
                                                                            return {
                                                                                ...prevState,
                                                                                advantages,
                                                                            };
                                                                        });
                                                                    }}
                                                                >
                                                                    <i className="advantages-heart fa fa-heart-o"/>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    //Pagintaion
                }
                <Pagination
                    max_size={max_size}
                    page={state.page}
                    size={state.size}
                    onChangeSize={(size) => {
                        setState((prevState) => {
                            return {...prevState, loading: true};
                        });
                        getAdvantages(state.page, size);
                    }}
                    onChange={(page) => {
                        setState((prevState) => {
                            return {...prevState, loading: true};
                        });
                        getAdvantages(page);
                    }}
                />
            </div>

            {/**
             * Filter Modal
             */}

            <div
                class="modal fade"
                id="filterModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >

                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body p-4">
                            {/**
                             * Close
                             */}
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            {/**
                             * Head
                             */}
                            <h5 class="h3 modal-title" id="exampleModalLongTitle">
                                FİLTRELE
                            </h5>
                            {/**
                             * Content
                             */}
                            <div className="mt-5 mb-5">
                                <div className="form-group">
                                    <label className="form-label b mb-3">Şehir</label>
                                    <select
                                        value={city}
                                        onChange={(event) => {
                                            setCity(event.target.value);
                                        }}
                                        className="form-control"
                                    >
                                        <option value={""}>Tümü</option>
                                        {Array.isArray(citys)
                                            ? citys.map(({city}) => {
                                                return <option value={city}>{city}</option>;
                                            })
                                            : null}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="form-label b mb-3">Kategori</label>
                                    <select
                                        value={category}
                                        onChange={(event) => {
                                            setCategory(event.target.value);
                                        }}
                                        className="form-control"
                                    >
                                        <option value={0}>Tümü</option>
                                        {Array.isArray(state.categories)
                                            ? state.categories.map(
                                                ({name, advantage_category_id}) => {
                                                    return (
                                                        <option value={advantage_category_id}>
                                                            {name}
                                                        </option>
                                                    );
                                                }
                                            )
                                            : null}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label className="form-label b mb-3">
                                        İndirim Miktarı ({rate[0]}% - {rate[1]}%)
                                    </label>

                                    <Range
                                        min={0}
                                        max={100}
                                        value={rate}
                                        defaultValue={[0, 100]}
                                        onChange={(res) => {
                                            setRate(res);
                                        }}
                                    />
                                </div>
                            </div>
                            {/**
                             * Buttons
                             */}
                            <div className="d-flex justify-content-between">
                                <button
                                    data-dismiss="modal"
                                    onClick={() => {
                                        setCategory(0);
                                        setCity("");
                                        setState((prevState) => {
                                            return {...prevState, loading: true};
                                        });

                                        getAdvantages(1, state.size, true);
                                    }}
                                    type="button"
                                    class="btn btn-secondary"
                                >
                                    Temizle
                                </button>
                                <button
                                    data-dismiss="modal"
                                    onClick={() => {
                                        setState((prevState) => {
                                            return {...prevState, loading: true};
                                        });
                                        getAdvantages(1);
                                    }}
                                    type="button"
                                    class="btn custom-btn"
                                >
                                    Uygula
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Page;
