import React from "react";

function Page() {
  return (
    <div className="error-page">
      <div
        className="error-page-img "
        style={{
          backgroundImage:
            "url(" + require("../assets/images/404-thumb.jpg") + ")",
        }}
      ></div>
      <div className="mb-5  p-4">
        <h3 className="h2">Sayfa Bulunamadı!</h3>
        <p>Aradğınız Kaynak kaldırılmış adı değiştirilmiş yada geçici olarak Devre dışı bırakılmış olabilir.</p>
      </div>
    </div>
  );
}
export default Page;
