import React, { useEffect, useState } from "react";

const logo = require("../assets/images/platforms/asildernegi.png");

function Page() {
  return (
    <>
      <div className="location">Platformlarımız</div>
      <div className="main">
        <div className="container">
          <div className="area">
            <div className="area-body p-5">
              {/**
               *Logo
               */}
              <div
                className="area-logo"
                style={{ backgroundImage: "url(" + logo + ")" }}
              ></div>
              {/**
               * P
               */}
              <p className="mt-5 b">
                ASİL Yardımlaşma Derneği, ASİL Dernekleşme ve Sosyal Sorumluluk
                Çalışma Gruplarının ortak çalışmaları sonucunda, Ankara Valiliği
                – İl Sivil Toplumla İlişkiler Müdürlüğü’ne yapılan başvurunun
                olumlu değerlendirilmesi sonrasında 21 Kasım 2019 tarihi
                itibariyle kurulmuştur.Derneğimiz, kurum içi ve kurum dışında
                ihtiyaç sahibi kişilere güvenilir bir şekilde ulaşabilmek,
                onların yalnızlık ve çaresizliğinde yüreklerine dokunabilmek ve
                ASELSAN’ın sadece güven veren teknoloji değil, aynı zamanda
                değer üreten teknoloji olduğunu gösterebilmek amacıyla
                kurulmuştur. Derneğimiz 5 ana faaliyet alanında çalışmalarını
                sürdürmektedir. Faaliyet Alanlarımız sırasıyla; “Aile Desteği,
                Eğitim Bursu, Okul Desteği, Sağlık Desteği, Patilere Destek”
                şeklinde yapılandırılmıştır. Söz konusu faaliyet alanlarında
                düzenli çalışmalar sürdürülmekle birlikte; Derneğimiz sosyal
                sorumluluk bilinciyleülkemizin ve içinde bulunduğumuz toplumun
                ihtiyaç duyduğu her anda destek sağlamayı hedeflemiştir.
                Derneğimiz, sosyal sorumluluk bilinci ile faaliyetlerine gerek
                katılımcılarımızın gerekse tedarikçilerimiz ve pay
                sahiplerimizin desteği ile ara vermeden devam etmektedir.
              </p>
              {/**
               * WWW LİNK
               */}
              <div className="d-flex justify-content-between flex-wrap">
                <div className="mt-5 d-flex justify-content-center align-items-center  flex-wrap">

                  <a target={"_blank"} href="https://www.facebook.com/teknomacera/">
                    <img
                        className="mr-3"
                        src={require("../assets/images/twitter.png")}
                        width="50px"
                        height="50px"
                    />
                  </a>
                  <a target={"_blank"} href="https://www.instagram.com/asildernegi/?hl=tr">
                    <img
                        className="mr-3"
                        src={require("../assets/images/instagram.png")}
                        width="50px"
                        height="50px"
                    />
                  </a>


                  <span className="b color-app mt-4">
                  / @asildernegi
                  </span>
                </div>
                <div className="mt-5 d-flex justify-content-center align-items-center flex-wrap">
                  <img
                    className="mr-3"
                    src={require("../assets/images/www.png")}
                    width="50px"
                    height="50px"
                  />
                  <a
                    className="b color-app mt-4"
                    href="https://asildernegi.org.tr/"
                    target="_blank"
                  >
                    / www.asildernegi.org.tr
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
