import React, { useState, useEffect } from "react";
/**
 * Configs
 */
import config from "../configs/config.json";
/**
 * Actions
 */
import { AuthLogout, AuthForgotPassCode } from "../actions";

function Page({ history }) {
  /**
   * Logout
   */
  useEffect(() => {
    AuthLogout();
  }, []);

  /**
   * States
   */
  const [code, setCode] = useState();

  return (
    <div className="login-container">
      <div className="login-thumb">
        <div
          className="login-thumb-img"
          style={{
            backgroundImage: `url(${require("../assets/images/login-thumb.png")})`,
          }}
        ></div>
      </div>
      <div className="login-wrapper">
        {/**
         * Login Form
         */}
        <div className="login-form">
          <h2 className="login-head">Şifremi Unuttum - Kod Kontrol</h2>
          <div className="login-link-group text-center mb-5">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          {/**
           * Login Input
           */}
          <div className="form-group mb-4 login-form-group">
            <div
              className="login-input-icon"
              style={{
                backgroundImage: `url(${require("../assets/images/icons/password.png")})`,
              }}
            ></div>
            <input
              value={code ? code : ""}
              className="login-input"
              type="email"
              placeholder="Kod"
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          {/**
           * Login Buttons
           */}
          <div className="btn-login-group">
            <button
              className="btn btn-login"
              onClick={() => AuthForgotPassCode(code)}
            >
              Kontrol Et
            </button>
          </div>

          <div className="login-link-group text-center">
            <span>Email Adresinize Kod Gelmedimi? </span>
            <a
              className="login-link font-weight-bold"
            >
              Tekrar Gönder
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
