import React, {useEffect, useState} from "react";
import {post} from "../networking/Server";
import Loading from "../components/loading";
import {getFile} from "../networking/getFile";
import {addFav} from "../functions/addFav";
import {Link} from "react-router-dom";
import {convertDate} from "../functions/convertDate";
import {goTop} from "../functions/goTop";


function Page(props) {
    const [state, setState] = useState({
        loading: true,
        page: 1,
        advantage: {},
        is_added: true,
        added_user_full: true
    });

    const advantage_id = parseInt(props.match.params.detail);

    useEffect(() => {
        getAdvantage();
    }, []);

    function getAdvantage() {
        post("api/advantages/get_one", {advantage_id,web:true}).then((res) => {
            try {
                if (res.result && res.advantage) {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            advantage: res.advantage,
                            is_added: res.is_added,
                            added_user_full: res.added_user_full,
                            loading: false
                        };
                    });
                }
            } catch (e) {
            }
        });
    }

    function acceptAdvantage(advantage_id) {
        goTop();
        setState((prevState) => {
            return {...prevState, loading: true};
        });
        post("api/advantages/add_user", {advantage_id}).then((res) => {
            try {
                if (res.result) {
                    getAdvantage()
                }
            } catch (e) {
            }
        });
    }

    let {
        title,
        description,
        slider,
        advantage_favorites,
        city,
        advantage_code,
        coupon_type_id,
        max_user,
        company,
        advantage_scope,
        createdAt,
        documents,
        discount_price,
        discount_rate,
        advantage_finish_date
    } = state.advantage;
    try {
        documents = JSON.parse(documents);
    } catch (e) {
    }

    try {
        slider = JSON.parse(slider);
    } catch (e) {
    }

    function buttonView() {

        if (advantage_code) {
            if (coupon_type_id == 1) {
                return (
                    <div className="text-center mt-5 mb-5">
                        <button
                            disabled
                            type="button"
                            className="btn btn-primary form-btn"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                        >
                            {advantage_code}
                        </button>
                    </div>

                )
            } else if (coupon_type_id == 2) {
                return (
                    <div className="text-center mt-5 mb-5">
                        {
                            state.is_added ?
                                <button
                                    disabled
                                    type="button"
                                    className="btn btn-primary form-btn"
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                >
                                    {advantage_code}
                                </button> :
                                state.added_user_full ?
                                    <button
                                        disabled
                                        type="button"
                                        className="btn btn-primary form-btn"
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                    >
                                        Avantaj Kotası Doldu
                                    </button> :
                                    <button
                                        onClick={() => {
                                            acceptAdvantage(advantage_id)
                                        }}
                                        type="button"
                                        className="btn btn-primary form-btn"
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                    >
                                        Avantajdan Yararlan
                                    </button>
                        }
                    </div>
                )
            } else if (coupon_type_id == 3) {
                return (
                    <div className="text-center mt-5 mb-5">
                        {
                            state.is_added ?
                                <button
                                    disabled
                                    type="button"
                                    className="btn btn-primary form-btn"
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                >
                                    Çekilişe Katıldınız
                                </button> :
                                <button
                                    onClick={() => {
                                        acceptAdvantage(advantage_id)
                                    }}
                                    type="button"
                                    className="btn btn-primary form-btn"
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                >
                                    Çekilişe Katıl
                                </button>

                        }
                    </div>
                )
            }
        }

        return (<div/>)

    }

    return (
        <>
            <Loading loading={state.loading}/>
            <div className="location">{title ? title : "Asil Avantajlar"}</div>
            <div className="main">
                <div className="container">
                    {
                        !state.loading?
                            <div>
                                <div className="slider">

                                    <div
                                        id="carouselExampleIndicators"
                                        className="carousel slide"
                                        data-ride="carousel"
                                    >
                                        <ol className="carousel-indicators">
                                            {slider
                                                ? slider.map((image, i) => {
                                                    return (
                                                        <li
                                                            key={i}
                                                            data-target="#carouselExampleIndicators"
                                                            data-slide-to={i}
                                                            className={i == 0 ? "active" : ""}
                                                        ></li>
                                                    );
                                                })
                                                : null}
                                        </ol>



                                        <div className="carousel-inner advantage-detail-page">
                                            {discount_price ? (
                                                <div className="advantages-menu-item-img-price">
                                                    {discount_price}₺
                                                </div>
                                            ) : null}
                                            {discount_rate ? (
                                                <div className="advantages-menu-item-img-disc">
                                                    {discount_rate}%
                                                </div>
                                            ) : null}
                                            {slider
                                                ? slider.map((image, i) => {
                                                    return (
                                                        <div
                                                            key={i}
                                                            className={
                                                                "carousel-item " + (i == 0 ? "active" : "")
                                                            }
                                                        >
                                                            <div
                                                                className="carousel-img"
                                                                style={{
                                                                    backgroundImage: "url(" + getFile(image) + ")",
                                                                }}
                                                            ></div>
                                                        </div>
                                                    );
                                                })
                                                : null}
                                        </div>
                                        <a
                                            className="carousel-control-prev"
                                            href="#carouselExampleIndicators"
                                            role="button"
                                            data-slide="prev"
                                        >
                <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                ></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a
                                            className="carousel-control-next"
                                            href="#carouselExampleIndicators"
                                            role="button"
                                            data-slide="next"
                                        >
                <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                ></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                                {/**
                                 * Heart
                                 */}
                                <div className="advantages-detail-bar">
                                    <div>
                                        {advantage_favorites?.length ? (
                                            <div
                                                onClick={() => {
                                                    addFav(setState, advantage_id).then(() => {});

                                                    let advantage = state.advantage;
                                                    advantage['advantage_favorites']=[];
                                                    setState((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            advantage,
                                                        };
                                                    });
                                                }}
                                            >
                                                <i className="advantages-heart fa fa-heart favorite-heart"/>
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    addFav(setState, advantage_id).then(() => {});

                                                    let advantage = state.advantage;
                                                    advantage['advantage_favorites']=[""];
                                                    setState((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            advantage,
                                                        };
                                                    });
                                                }}
                                            >
                                                <i className="advantages-heart fa fa-heart-o"/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/**
                                 * Props
                                 */}
                                <div className="advantages-detail-props row ml-0">
                                    <div className="advantages-detail-prop col-4">
                                        <div
                                            className="advantages-detail-prop-item"
                                            style={{borderColor: "#e98832"}}
                                        >
                                            <div
                                                className="advantages-detail-prop-item-img"
                                                style={{
                                                    backgroundImage:
                                                        "url(" +
                                                        require("../assets/images/prop-ecommerce.png") +
                                                        ")",
                                                }}
                                            />
                                        </div>
                                        <span style={{color: "#e98832"}}>{city}</span>
                                    </div>
                                    <div className="advantages-detail-prop col-4">
                                        <div
                                            className="advantages-detail-prop-item"
                                            style={{borderColor: "#f20058"}}
                                        >
                                            <div
                                                className="advantages-detail-prop-item-img"
                                                style={{
                                                    backgroundImage:
                                                        "url(" + require("../assets/images/prop-clock.png") + ")",
                                                }}
                                            />
                                        </div>
                                        <span style={{color: "#f20058"}}>
                {convertDate(advantage_finish_date)}
                                            <br/>
                (Geçerlilik Tarihi)
              </span>
                                    </div>
                                    <div className="advantages-detail-prop col-4">
                                        <div
                                            className="advantages-detail-prop-item"
                                            style={{
                                                borderColor: "#7cc78a",
                                            }}
                                        >
                                            <div
                                                className="advantages-detail-prop-item-img"
                                                style={{
                                                    backgroundImage:
                                                        "url(" + require("../assets/images/prop-gift.png") + ")",
                                                }}
                                            />
                                        </div>
                                        <span style={{color: "#7cc78a"}}>
                {!advantage_code || !max_user ? "SINIRSIZ" : max_user}
                                            <br/>
                                            {!advantage_code || !max_user ? "" : "SINIRLI"}
              </span>
                                    </div>
                                </div>
                                {/**
                                 * Content
                                 */}
                                <div className="advantages-detail-content">
                                    <Link
                                        className="advantages-detail-title"
                                        to={`/app/advantages/firma/${company?.company_id}`}
                                    >
                                        <div
                                            className="advantages-detail-logo"
                                            style={{
                                                backgroundImage: "url(" + getFile(company?.logo) + ")",
                                            }}
                                        ></div>
                                        <div className="advantages-detail-head">{company?.name}</div>
                                    </Link>
                                    <div className="advantages-detail-sub-head">{title}</div>

                                    <div
                                        style={{lineHeight: 1.5, wordBreak: "break-word", hyphens: "auto"}}
                                        className="advantages-detail-desc"
                                        dangerouslySetInnerHTML={{__html: description}}
                                    >
                                        {}
                                    </div>

                                    {Array.isArray(documents) ? (
                                        <div className="advantages-detail-desc">
                                            <div className="advantages-detail-desc-head">
                                                Ekler
                                            </div>
                                            {
                                                documents.map((document,i)=>{
                                                    return(
                                                        <p className={"ml-2"}><a target={"_blank"} href={document}>Ek {i+1}</a></p>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : null}

                                    {advantage_scope ? (
                                        <div className="advantages-detail-desc">
                                            <div className="advantages-detail-desc-head">
                                                KAMPANYA KAPSAMI
                                            </div>
                                            <div style={{ lineHeight: 1.5,}}
                                                 dangerouslySetInnerHTML={{__html: advantage_scope}}/>
                                        </div>
                                    ) : null}
                                </div>
                                {/**
                                 * Model Button
                                 */}
                                {
                                    buttonView(state.advantage)
                                }
                            </div>:null
                    }
                </div>
            </div>

            {/**
             * Model
             */}
            <div
                class="modal fade"
                id="exampleModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className="text-center mb-3">
                                <i className="avantage-info fa fa-info"></i>
                            </div>
                            <div className="text-center avantage-code">
                                {
                                    coupon_type_id == 3 ?
                                        <div>Çekilişe Katıldınız</div> :
                                        <div>Avantaj Kodu::<span> {advantage_code}</span></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Page;
