import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom'
import {post} from "../networking/Server";
import Loading from "../components/loading";
import {getFile} from "../networking/getFile";
import {addFav} from "../functions/addFav";
import Pagination from "../components/pagination";

function Page() {
    const [state, setState] = useState({
        loading: true,
        page: 1,
        advantages: [],
        size: 9
    });
    const [max_size, setMaxSize] = useState(0);


    useEffect(() => {
        getAdvantages(state.page);
    }, []);


    function getAdvantages(page, size=state.size) {
        post("api/users/get_favories", {size, page}).then((res) => {

            try {
                if (res.result) {
                    setMaxSize(res.max_size);
                    setState(prevState => {
                        return {...prevState, advantages: res.favories, loading: false, page, size};
                    })
                }
            } catch (e) {

            }
        });
    }

    return (
        <>
            <Loading loading={state.loading}/>
            <div className="location">Favorilerim</div>
            <div className="main">
                <div className="container">
                    {
                        //Menu Start
                    }

                    {
                        !state.loading && Array.isArray(state.advantages) && !state.advantages.length?
                            <div style={{display:'flex',justifyContent:'center',fontWeight:'center',fontSize:20}}>Hiç Favoriniz Yok</div>:null

                    }
                    <div className="advantages-menu row">
                        {state.advantages && state.advantages.map(({advantage_id, company, slider, advantage_favorites, title, discount_rate, discount_price}, i) => {
                            let image = "";
                            try {
                                image = JSON.parse(slider)[0]
                            } catch (e) {

                            }

                            return (
                                <div
                                    className="advantages-menu-item col-lg-4 col-md-6 col-sm-12"
                                    key={i}
                                >
                                    <Link
                                        className="advantages-menu-item-top"
                                        to={`/app/advantages/firma/${company?.company_id}`}
                                    >
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="advantages-menu-title-img"
                                                style={{backgroundImage: "url(" + getFile(company?.logo) + ")"}}
                                            />
                                            <div className="advantages-menu-item-title">{company?.name}</div>
                                        </div>

                                    </Link>
                                    <div className="advantages-menu-item-content">
                                        <Link
                                            className="advantages-menu-item-img d-block"
                                            style={{backgroundImage: "url(" + getFile(image) + ")"}}
                                            to={`/app/advantages/details/${advantage_id}`}
                                        >
                                            {
                                                discount_price ?
                                                    <div className="advantages-menu-item-img-price">
                                                        {discount_price}
                                                    </div> : null
                                            }
                                            {
                                                discount_rate ?
                                                    <div className="advantages-menu-item-img-disc">
                                                        {discount_rate}
                                                    </div> : null
                                            }

                                        </Link>
                                        <div className="d-flex justify-content-between mt-3">
                                            <Link
                                                className="advantages-text"
                                                to={`/app/advantages/details/${advantage_id}`}
                                            >
                                                {title}
                                            </Link>
                                            <div>
                                                {advantage_favorites?.length ? (
                                                    <div onClick={() => {
                                                        setState(prevState => {
                                                            return {...prevState, loading: true};
                                                        });
                                                        addFav(setState, advantage_id).then(() => {
                                                            getAdvantages(state.page)
                                                        })
                                                    }}><i className="advantages-heart fa fa-heart favorite-heart"/>
                                                    </div>
                                                ) : (
                                                    <div onClick={() => {
                                                        setState(prevState => {
                                                            return {...prevState, loading: true};
                                                        })
                                                        addFav(setState, advantage_id).then(() => {
                                                            getAdvantages(state.page)
                                                        })
                                                    }}><i className="advantages-heart fa fa-heart-o"/></div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {
                    //Pagintaion
                }
                <Pagination
                    max_size={max_size}
                    page={state.page}
                    size={state.size}
                    onChange={(page) => {
                        setState(prevState => {
                            return {...prevState, loading: true};
                        })
                        getAdvantages(page)
                    }}
                    onChangeSize={(size) => {
                        setState(prevState => {
                            return {...prevState, loading: true};
                        })
                        getAdvantages(state.page, size)
                    }}
                />
            </div>
        </>
    );
}

export default Page;
