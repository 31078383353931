import React, { useEffect, useState } from "react";
import { post } from "../networking/Server";
import Loading from "../components/loading";
import Pagination from "../components/pagination";

function Page() {
  const [state, setState] = useState({
    loading: true,
    videos: [],
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(9);
  const [max_size, setMaxSize] = useState(0);
  const [activeUrl, setActiveUrl] = useState("");

  useEffect(() => {
    getVideos();
  }, []);

  useEffect(() => {
    getVideos();
  }, [size, page]);

  function getVideos() {
    setState((prevState) => {
      return { ...prevState, loading: true };
    });
    post("api/home/get_family_chat_videos", { size, page, web: true }).then(
      (res) => {
        try {
          if (res.result) {
            setMaxSize(res.max_size);
            setState((prevState) => {
              return { ...prevState, videos: res.videos, loading: false };
            });
          }
        } catch (e) {}
      }
    );
  }

  return (
    <>
      <Loading loading={state.loading} />
      <div className="location">Aile Sohbetlerimiz</div>
      <div className="main">
        <div
          class="modal fade portofilo-modal"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog portofilo-modal-diaolg" role="document">
            <div class="modal-content portofilo-modal-content">
              <iframe
                className="portofilo-video"
                src={activeUrl}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="1"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 mb-5">
            {state.videos
              ? state.videos.map(({ url, image, title }, i) => (
                  <div
                    className="portofilo col-lg-4 col-md-6 col-sm-12"
                    key={i}
                  >
                    <div
                      onClick={() => setActiveUrl(url)}
                      className="portofilo-body"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      <div
                        className="portofilo-img"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        <img src={image} />
                        <div className="portofilo-play">
                          <i class="fas fa-play-circle"></i>
                        </div>
                      </div>
                      <div
                        className="portofilo-title"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        {title}
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
          <Pagination
            max_size={max_size}
            page={page}
            size={size}
            onChangeSize={(_size) => {
              setSize(_size);
            }}
            onChange={(_page) => {
              setPage(_page);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Page;
