import React, {useEffect, useState} from "react";
import $ from "jquery";
import Loading from "../components/loading";
import {post} from "../networking/Server";
import {Link} from "react-router-dom";
import {goTop} from "../functions/goTop";

function Footer(history) {
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        $(function () {
            $(".goToTop").on("click", function () {
                $("html, body").animate({scrollTop: 0}, "slow");
                return false;
            });
        });
    }, []);

    function setContactUs(title, desc) {

        if (!title.trim() || !desc.trim())
            return;


        setLoading(true);
        post("api/contact_reports/add", {title, message: desc}).then((res) => {
            try {
                if (res.result) {
                    alert("Bildirminiz Bize Ulaşmıştır. Teşekkür Ederiz.");

                    setLoading(false)

                    history.push("/")
                }
            } catch (e) {

            }
        });
    }

    return (
        <footer className="page-footer font-small blue pt-4">
            <Loading loading={loading}/>
            <div className="container-fluid text-md-left">
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-3 mt-md-0 mt-3 mb-3 d-flex flex-column">
                        <p>
                            <strong>Sosyal Medya Hesaplarımız</strong>
                        </p>
                        <div className="social-media">
                            <a target="_blank" href="https://www.facebook.com/aselsan">
                                <i className="fa fa-facebook"></i>
                            </a>
                            <a target="_blank" href="https://www.instagram.com/aselsan_resmi">
                                <i className="fa fa-instagram"></i>
                            </a>
                            <a target="_blank" href="https://twitter.com/aselsan">
                                <i className="fa fa-twitter"></i>
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/company/aselsan/">
                                <i className="fa fa-linkedin"></i>
                            </a>
                            <a target="_blank" href="https://www.youtube.com/user/AselsanTV">
                                <i className="fa fa-youtube"></i>
                            </a>
                        </div>
                    </div>

                    <hr className="clearfix w-100 d-md-none pb-3"/>

                    <div className="col-md-4 mb-md-0 f-nav  d-flex flex-column  justify-content-center">
                        <ul onClick={() => {
                            goTop();
                        }} className="list-unstyled navigation">
                            <li>
                                <Link to={"/app/advantages"}>Asil Avantajlar</Link>
                            </li>
                            <li>
                                <Link to={"/app/family-talks"}>Aile Sohbetlerimiz</Link>
                            </li>
                            <li>
                                <Link to={"/app/blogs"}>Köşe Yazılarımız</Link>
                            </li>
                            <li>
                                <Link to={"/app/platforms"}>Platformlarımız</Link>
                            </li>
                            <li>
                                <Link to={"/app/announcements"}>Duyurularımız</Link>
                            </li>
                            <li>
                                <Link to={"/app/surveys"}>Anketler</Link>
                            </li>
                            <li>
                                <Link to={"/app/advertisements"}>İlanlarımız</Link>
                            </li>
                            <li>
                                <Link to={"/app/advices"}>Tavsiyelerimiz</Link>
                            </li>
                            <li>
                                <Link to={"/app/first-work-day"}>İlk İş Günüm</Link>
                            </li>
                            <li>
                                <Link to={"/app/favorites"}>Favoriler</Link>
                            </li>

                        </ul>
                    </div>

                   {/* <div className="col-md-2 mb-md-0 mb-3  d-flex flex-column">
                        <ul className="list-unstyled navigation">

                        </ul>
                    </div>*/}

                    <hr className="clearfix w-100 d-md-none pb-3"/>

                    <div className="col-md-3 mb-md-0 mb-3  d-flex flex-column justify-content-center">
                        <h5 className="text-uppercase">BİZE ULAŞIN</h5>
                        <form>
                            <div className="form-group ">
                                <label className="mb-2 w-100">
                                    Konu Ekle <span className="require">*</span>
                                </label>
                                <select onChange={e => setTitle(e.target.value)}
                                        style={{backgroundColor: '#29324e', border: 0, color: '#727987'}}
                                        className="col-md-12 form-control">
                                    <option value={0}>Konu Seçiniz</option>
                                    <option value={1}>Şikayet</option>
                                    <option value={2}>Öneri</option>
                                    <option value={3}>Diğer</option>
                                </select>
                            </div>
                            <div className="input-group">
                                <label className="required" htmlFor="message">
                                    Mesaj
                                </label>
                                <textarea onChange={e => setDesc(e.target.value)} maxLength={500} id="message" rows="8"
                                          placeholder="Mesaj"></textarea>
                            </div>
                            <div className="input-group">
                                <button
                                    onClick={() => {
                                        setContactUs(title, desc)
                                    }}
                                    type="button">Gönder
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="footer-copyright text-center py-3">
                2020 Copyright ©
                <a href="https://www.aselsan.com.tr/"> ASELSAN A.Ş.</a>
                <a href="#" className="goToTop">
                    <i className="fa fa-arrow-up"></i>
                </a>
            </div>
        </footer>
    );
}

export default Footer;
