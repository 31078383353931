import React, {useEffect, useState} from "react";
import Picker from "emoji-picker-react";
import "../assets/css/emoji.scss"

function EmojiTextarea(props) {

    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState("");

    const {
        onChange = () => {

        },
        defaultValue = "",
        rows =  5
    } = props;

    useEffect(() => {
        if (value !== defaultValue) {
            setValue(defaultValue)
        }
    }, [defaultValue])

    useEffect(() => {
        onChange(value);
    }, [value]);

    return (
        <div className="emoji-textarea">
            <textarea
                value={value}
                rows={rows}
                onChange={e => setValue(e.target.value)}
                className="form-control"></textarea>
            <i onClick={() => {
                setIsOpen(!isOpen);
            }} className="fa fa-smile-o" aria-hidden="true"></i>
            <div className={`emoji-picker ${isOpen ? 'show' : ''}`}>
                <Picker onEmojiClick={(e, emoji) => {
                    setValue(value + emoji.emoji)
                }} disableAutoFocus={true}/>
            </div>
        </div>
    );
}

export default EmojiTextarea;
