import React, {useEffect, useState, useRef} from "react";
import Loading from "../components/loading";
import {Link} from "react-router-dom";
import {post} from "../networking/Server";
import {getFile} from "../networking/getFile";
import Pagination from "../components/pagination";
import {cutText} from "../functions/cutText";
import EmojiTextarea from "../components/emojiTextarea";

export const bar = [
    {
        id: 1,
        img: require("../assets/images/advices/saglik.png"),
        label: "Sağlık",
        color: "#7944c9",
    },
    {
        id: 2,
        img: require("../assets/images/advices/evisleri.png"),
        label: "Ev İşleri",
        color: "#890100",
    },
    {
        id: 3,
        img: require("../assets/images/advices/spor.png"),
        label: "Spor Etkinlikleri",
        color: "#2abde4",
    },
    {
        id: 4,
        img: require("../assets/images/advices/aracbakim.png"),
        label: "Araç Bakım",
        color: "#7cc78a"
    },
    {
        id: 5,
        img: require("../assets/images/advices/egitim.png"),
        label: "Eğitim & Öğretim",
        color: "#e98832",
    },
];

function Page() {


    const [loading, setLoading] = useState(false);
    const [advices, setAdvices] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(9);
    const [category, setCategory] = useState(0);

    const [title, setTitle] = useState("");
    const [files, setFiles] = useState(null);
    const [content, setContent] = useState("");
    const [adviceCategory, setAdviceCategory] = useState(1);
    const formRef = useRef();

    const [max_size, setMaxSize] = useState(0);


    useEffect(() => {
        getAdvices();
    }, []);

    useEffect(() => {
        getAdvices();
    }, [category, size, page])


    async function getAdvices() {
        setLoading(true);
        console.log(size,page,category);
        const data = await post("api/advices/get", {size, page, advice_category_id: category});

        if (data.result) {
            setMaxSize(data.max_size)
            setAdvices(data.advices);
        }
        setLoading(false);
    }

    async function addAdvice() {

        if (!title || !content || !adviceCategory) return;

        if (files && files?.length <= 0) return;


        setLoading(true);

        const formData = new FormData();

        formData.append("title", title);
        formData.append("content", content);
        formData.append("advice_category_id", adviceCategory);
        formData.append("banner", files[0]);

        formData.append("web", "1");

        const data = await post("api/advices/add", formData);


        if (data.result) {
            getAdvices();
        }

        setContent("")
        setTitle("")
        setFiles(null);
        formRef.current.reset();

        setLoading(false);

    }

    return (
        <>
            <Loading loading={loading}/>
            <div className="location">Tavsiyelerimiz</div>
            <div className="main">
                <div className="advices-bar">
                    {bar
                        ? bar.map((p, i) => (
                            <div
                                key={i}
                                onClick={() => {
                                    setCategory(p.id)
                                }}
                                className={"advices-bar-item" + (p.id === category ? " active" : "")}
                            >
                                <div
                                    className="advices-bar-item-img"

                                >
                                    <img src={p.img} alt=""/>
                                </div>
                                <div
                                    style={{color: p.color}}
                                    className="advices-bar-item-text"
                                >
                                    {p.label}
                                </div>
                            </div>
                        ))
                        : null}
                </div>
            </div>
            <div className="content">
                <div className="container">
                    <div className="text-right">
                        <button
                            className="btn custom-btn"
                            data-target="#exampleModalCenter"
                            data-toggle="modal"
                        >
                            Tavsiyeni Paylaş
                        </button>
                    </div>
                    <div className="advices-container">
                        {
                            !loading && Array.isArray(advices) && !advices.length?
                                <div style={{display:'flex',justifyContent:'center',fontWeight:'center',fontSize:20}}>Bu kategoride herhangi bir tavsiye bulunmamaktadır.</div>:null

                        }
                        <div className="row">
                            {/**
                             * Advices
                             */}
                            {advices
                                ? advices.map((e, i) => (
                                    <div
                                        className="advice-box col-lg-4 col-md-6 col-sm-12"
                                        key={i}
                                    >
                                        <div className="advice-box-title mb-2">
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="advice-box-profile-img"
                                                    style={{
                                                        backgroundImage: "url(" + getFile(e.user.image) + ")",
                                                    }}
                                                ></div>
                                                <div className="advice-box-name ml-3">{cutText(e.title, 70)}</div>
                                            </div>
                                        </div>
                                        <Link className="advice-box-body" to={"/app/advices/detail/" + e.advices_id}>
                                            <div
                                                className="advice-box-img"
                                                style={{backgroundImage: "url(" + getFile(e.image) + ")"}}
                                            ></div>
                                            <div className="advice-box-desc">{cutText(e.content, 100)}</div>

                                        </Link>
                                    </div>
                                ))
                                : null}
                        </div>

                    </div>
                    <Pagination
                        max_size={max_size}
                        page={page}
                        size={size}
                        onChangeSize={(_size) => {
                            setSize(_size)
                        }}
                        onChange={(_page) => {
                            setPage(_page)
                        }}
                    />
                </div>

                <div
                    className="modal fade"
                    id="exampleModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    Tavsiye Paylaş
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        setContent("")
                                        setTitle("")
                                        setFiles(null);
                                        formRef.current.reset();
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form ref={formRef}>
                                    <div className="form-group">
                                        <label className="form-label mb-2">Resim</label>
                                        <input accept="image/*" multiple={false} type="file" onChange={e => {
                                            setFiles(e.target.files)
                                        }} className="form-control-file"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="category-select" className="form-label mb-2">Kategori</label>
                                        <select id="category-select" value={adviceCategory}
                                                onChange={e => setAdviceCategory(e.target.value)}
                                                className="form-control">
                                            {bar.map(e => {
                                                return (
                                                    <option key={e.id} value={e.id}>{e.label}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label mb-2">Başlık</label>
                                        <input value={title} onChange={e => setTitle(e.target.value)} type="text"
                                               className="form-control"/>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label mb-2">Açıklama</label>
                                        <EmojiTextarea onChange={(val) => {
                                            setContent(val)
                                        }}/>

                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        setContent("")
                                        setTitle("")
                                        setFiles(null);
                                        formRef.current.reset();
                                    }}
                                >
                                    İptal
                                </button>
                                <button data-dismiss="modal" onClick={addAdvice} type="button"
                                        className="btn btn-primary">
                                    Paylaş
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Page;
