export function getFile(url) {
    try {
        if (url.substr(0, 4) == "http") {
            return url;
        } else
            return "https://ailemaselsan.com.tr:8443/" + url;
    } catch (e) {
        return ""
    }
}
