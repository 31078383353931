import React, {useEffect, useState} from "react";
import {post} from "../networking/Server";
import Loading from "../components/loading";
import $ from "jquery";
import {setAlert} from "../actions";


function Section() {
  const [familys, setFamilys] = useState();
  const [email, setEmail] = useState("");
  const [layer, setLayer] = useState(0);
  const [layer_name, setLayerName] = useState();
  const [loading, setLoading] = useState(true);
  const [removing, setRemoving] = useState({
    email:"",
    family_id:""
  });

  useEffect( () => {
    getFamilys()

    /*const body = $("html, body");
    body.stop().animate({scrollTop: 1350}, 350, 'swing');*/
  },[]);


  function getFamilys(){
    post("api/familys/get").then((res)=>{
      try {
        if(res.result && res.familys){
          setFamilys(res.familys);
          setLoading(false)
        }
      }catch (e) {

      }
    });
  }

  function addFamilys(email,layer,layer_name){
    if(!email.trim() || !layer)
      return;

    setLoading(true);

    post("api/familys/set",{email,layer_name,layer}).then((res)=>{
      try {
        if(res.result){
          setAlert("Belirttiğiniz Email Hesabına Bir Davetiye Gönderilmiştir");
          setEmail("");
          setLayer(0);
          setLayerName("");
          getFamilys()
        }
      }catch (e) {

      }
    });
  }


  function removeFamilys(family_id,email){
    if(!family_id || !email)
      return;

    setLoading(true);

    post("api/familys/remove",{email,family_id}).then((res)=>{
      try {
        if(res.result){
          setRemoving({
            email:"",
            family_id:""
          })
          getFamilys()
        }
      }catch (e) {

      }
    });
  }
  return (
    <>
      <Loading loading={loading}/>
      <div className="profile-area w-100">
        <div className="profile-area-body">
          <div className="col-md-12 text-center  font-weight-bold">
            Aile Üyeleri
          </div>
          <div className="text-right mb-3">
            {/**
             * Add Famliy Button
             */}
            <button
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#addfamily"
            >
              Aile Üyesi Ekle
            </button>
          </div>
          <div className="family-members">
            {familys
              ? familys.map(({family_id,email,layer,layer_name}, i,p={}) => (
                  <div key={i} className="family-member">
                    <div className="family-member-info">
                      <div className="family-member-name">{layer_name}</div>
                      <div className="family-member-email">{email}</div>
                    </div>
                    <div className="family-member-btns btn-group">

                      {/**
                       * Delete Famliy Button
                       */}
                      <button
                        className="btn btn-danger btn-sm btn-icon"
                        data-toggle="modal"
                        data-target="#delfamily"
                        onClick={()=>{
                          setRemoving({
                            email,
                            family_id
                          })
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      {/**
       * Add Modal
       */}
      <div
        class="modal fade"
        id="addfamily"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Aile Üyesi Ekle
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group col-md-12">
                <label
                  className="mb-2 col-md-12 p-0"
                  htmlFor="exampleFormControlFile1"
                >
                  E-Mail
                </label>
                <input
                    defaultValue={email}
                    onChange={e=>setEmail(e.target.value)}
                    className="form-input col-md-12" />
              </div>
              <div className="form-group col-md-12">
                <label
                  className="mb-2 col-md-12 p-0"
                  htmlFor="exampleFormControlFile1"
                >
                  Yakınlık Dereceniz
                </label>
                <select
                    value={layer_name}
                    onChange={(e)=>{
                        setLayer(e.target.value=="Diğer"?2:1);
                        setLayerName(e.target.value);
                    }}
                    className="form-input col-md-12">
                  <option value={0}>Seçiniz</option>
                  <option value={"Anne"}>Anne</option>
                  <option value={"Baba"}>Baba</option>
                  <option value={"Eş"}>Eş</option>
                  <option value={"Çocuk"}>Çocuk</option>
                  <option value={"Diğer"}>Diğer</option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                İptal
              </button>
              <button
                  data-dismiss={email && layer ? "modal":""}
                  onClick={()=>{
                      addFamilys(email,layer,layer_name)
                  }}
                  type="button" className="btn btn-primary">
                Ekle
              </button>
            </div>
          </div>
        </div>
      </div>
      {/**
       * Delete Modal
       */}
      <div
        class="modal fade"
        id="delfamily"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Aile Üyesi Sil
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Aile Üyesini Silmek İstediğinizden Eminmisiniz.
            </div>
            <div className="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                İptal
              </button>
              <button
                  data-dismiss="modal"
                  onClick={()=>{
                    removeFamilys(removing.family_id,removing.email)
                  }}
                  type="button" className="btn btn-danger">
                Sil
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Section;
