import React, { useEffect, useState } from "react";
import {post} from "../networking/Server";

const work_zero = require("../assets/images/workday/0.png");
const work_one = require("../assets/images/workday/1.png");
const work_two = require("../assets/images/workday/2.png");
const work_three = require("../assets/images/workday/3.png");

const desc_zero =
  "Nizamiyede senin için oluşturulmuş yaka kartını teslim alarak güne başladın. Güvenlik Müdürlüğünü ziyaret ettin ve senden istenen evrakları teslim ettin. Şimdi Sıra İK Uygulamaları Yönetimi Müdürlüğü ziyaretinde! Sana daha önce iletilen dosyadaki evrakları teslim edebilirsin.";



const data=[
  {
    img:require('../assets/images/workday/7.png'),
    title:"Değerli ASELSAN’lı!\nASELSAN Yolculuğun Başladı,\nAramıza Hoş Geldin!",
    center:true
  },
  {
    num:1,
    img:require('../assets/images/workday/8.png'),
    desc:"İlk iş gününün akışı:\n\n\nNizamiyede senin için oluşturulmuş yaka kartını teslim alarak güne başladın."
  },
  {
    num:2,
    img:require('../assets/images/workday/4.png'),
    desc:"İK Uygulamaları Yönetimi Müdürlüğü’müzü ziyaret ettin. Sana daha önce iletilen dosyadaki evrakları teslim ettin."
  },
  {
    num:3,
    img:require('../assets/images/workday/2.png'),
    desc:"Tüm süreçlerinde sana destek olacak PUSULA’n ile tanıştın."
  },
  {
    num:4,
    img:require('../assets/images/workday/1.png'),
    desc:"Gün içerisinde Güvenlik Müdürlüğü’müzü ziyaret etmelisin ve senden istenen evrakları teslim etmelisin."
  },
  {
    num:5,
    img:require('../assets/images/workday/5.png'),
    desc:"Gün içerisinde maaş hesabı açma ve bankacılık işlemleri için banka ziyareti yapmalısın. Banka hesabını açtıktan sonra hesap bilgilerin olan evrakı İK Uygulamaları Yönetimi Müdürlüğü’ne bırakmalısın."
  },
  {
    num:6,
    img:require('../assets/images/workday/6.png'),
    desc:"Özel Sağlık Sigortası ile ilgili ASELSAN intranet portalinde  “Kurumsal Risk ve Süreç Yönetimi  Müdürlüğü” sayfasında yer alan “Türkiye Sigorta Başvuru Formu”nu doldurmalı ve doldurduğun formu gss@aselsan.com.tr  adresine e-posta ile göndermelisin.\n\nDetaylı bilgi ve soruların için gss@aselsan.com.tr  adresine e-posta gönderebilirsin."
  },
  {
    img:require('../assets/images/workday/3.png'),
    title:"İşte ilk gününü tamamladın,\ntekrar aramıza hoş geldin!",
    center:true,
    end:true
  }
]


function Page(props) {

  useEffect(() => {
    setView();
  },[]);

  function setView(){
    post("api/home/set_first_work_day_view",{web:true}).then((res)=>{

    });
  }

  return (
    <>
      <div className="location">İlk İş Günüm</div>
      <div className="main">
        <div className="container">
          <div className="col-md-12 first-work-area mt-5">
            <img className="first-work-img img-fluid" src={work_zero} />
            <div className="h5 mt-3">ASELSAN Yolculuğun Başladı!</div>
            <div className="h5">Aramıza Hoş Geldin</div>

            <div className="h5 c-custom mt-5">İlk İş Gününün Akışı:</div>
            <p className="text-center">{desc_zero}</p>
          </div>

          <div className="work-line mt-5 mb-5">
            {
              data.map(({img,title,desc},index)=>{
                return(
                    <div className={`work-line-item ${index%2==0?"left":"right"}`}>
                      <div className="work-line-item-body">
                        <div className="work-line-item-num">{index+1}</div>
                        <div
                            className="work-line-item-img"
                            style={{ backgroundImage: "url(" + img + ")" }}
                        ></div>
                        <p className="mt-5" style={{whiteSpace:"pre-line"}}>
                          <p style={{fontWeight:'bold',whiteSpace:"pre-line"}}>{title}</p>
                          {desc}
                        </p>
                      </div>
                    </div>
                )
              })
            }


{/*

            <div className="work-line-item right">
              <div className="work-line-item-body">
                <div className="work-line-item-num">2</div>
                <div
                  className="work-line-item-img"
                  style={{ backgroundImage: "url(" + work_two + ")" }}
                ></div>
                <p className="mt-5">
                  Sağlık sigortası işlemleri için sigorta bölümünü ziyaret
                  etmelisin.
                </p>
              </div>
            </div>
            <div className="work-line-item left">
              <div className="work-line-item-body">
                <div className="work-line-item-num">3</div>
                <div
                  className="work-line-item-img"
                  style={{ backgroundImage: "url(" + work_three + ")" }}
                ></div>
               <p className="mt-5">
                 Servis kaydı ve bilgilendirmesi için Ulaştırma Amirliğini ziyaret etmelisin.
                </p>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
