import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../components/pagination";
import {post} from "../networking/Server";
import Loading from "../components/loading";
import $ from "jquery";
import {goTop} from "../functions/goTop";
import {cutText} from "../functions/cutText";
import Banner from "../components/banner";

/**
 * Components
 */

const adv_bar = [
  {
    img:
        require("../assets/images/adverts/car.png"),
    id: 1,

    text: "Araç",
    color: "#28bce4",
  },
  {
    img:
        require("../assets/images/adverts/home-run.png"),
    id: 2,
    text: "Gayrimenkul",
    color: "#e68631",
  },
  {
    img:
        require("../assets/images/adverts/esya.png"),
    id: 3,
    text: "Eşya",
    color: "#f20058",
  },
  {
    img:
        require("../assets/images/adverts/other.png"),
    id: 4,
    text: "Diğer",
    color: "#014189",
  },
];


function Page(props) {
  const [loading, setLoading] = useState(false);
  const [adverts, setAdverts] = useState([]);
  const [slider, setSlider] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(9);
  const [max_size, setMaxSize] = useState(0);


  useEffect(() => {
    getAdverts();
  }, []);

  useEffect(() => {
    getAdverts();
  }, [size, page]);


  async function getAdverts() {
    setLoading(true);
    const data = await post("api/a-web/get", {size, page, advert_category_id: 0});

    if (data.result) {
      setAdverts(data.adverts);
      setMaxSize(data.max_size);

      setSlider(getSlider(data.adverts))
    }
    setLoading(false);
  }

  function getSlider(adverts) {
    let images = [];

    try {
      let i = 0;
      adverts.map((value) => {


        let image = "";
        try {
          image = JSON.parse(value?.images)[0]
        } catch (e) {

        }

        if(image){
          i++;


          if (i > 5)
            return;

          images.push(image)
        }

      })
    } catch (e) {


    }

    return images;
  }

  return (
    <>
      <Loading loading={loading}/>
      <div className="location">İlanlarımız</div>
      <div className="main">
        <div className="container">
          {
            !loading?
                <div className="advertisements-container">
                  <div className="advertisements-header" style={{display: 'flex', justifyContent: 'center'}}>
                    {/**Slider  Start*/}
                    <div className="slider advertisements-slider col-lg-10 col-md-12">
                      <Banner
                          banner_type={3}
                      />
                    </div>

                    {/**Slider End */}

                    <div className="advertisements-bar">
                      {/**
                       * Bars
                       */}
                      {adv_bar
                          ? adv_bar.map((p, i) => (
                              <Link
                                  onClick={() => {
                                    goTop()
                                  }}
                                  key={i}
                                  to={"/app/advertisements/" + p.id}
                                  className="advertisements-bar-item"
                              >
                                <div
                                    style={{
                                      padding: 20,
                                      backgroundColor: "white",
                                      borderRadius: 1000,
                                    }}
                                >
                                  <div
                                      className="advertisements-bar-item-img"
                                      style={{backgroundImage: "url(" + p.img + ")"}}
                                  ></div>
                                </div>
                                <div
                                    className="advertisements-bar-item-text"
                                    style={{color: p.color}}
                                >
                                  {p.text}
                                </div>
                              </Link>
                          ))
                          : null}
                    </div>
                  </div>

                  <div className="page-bar mb-5">
                    <Link to="/app/advertisement-add" className="page-bar-btn">
                      <img
                          className="page-bar-btn-back"
                          src={require("../assets/images/add.png")}
                      />
                    </Link>
                    <Link to="/app/advertisement-add" className="page-bar-head">
                      İlan Ver
                    </Link>
                  </div>

                  {/**
                   * Advertisements
                   */}
                  <div className="row">

                    {adverts
                        ? adverts.map(({advert_id, title, images, address}, i, p = {}) => {
                          let image = "";
                          try {
                            image = JSON.parse(images)[0]
                          } catch (e) {

                          }
                          return (
                              <Link
                                  className="advertisement-box col-lg-4 col-md-6 col-sm-12"
                                  key={i}
                                  to={"/app/advertisements_detail/" + advert_id}
                              >
                                <div className="advertisement-box-body">
                                  <div
                                      className="advertisement-box-img"
                                      style={{backgroundImage: "url(" + image + ")"}}
                                  ></div>
                                  <div className="d-flex justify-content-between">
                                    <div className="advertisement-box-text">{title}</div>
                                    <div className="advertisement-box-adress">
                                      <i className="fa fa-map-marker mr-2"></i>
                                      {cutText(address, 100)}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                          )
                        })
                        : null}
                  </div>

                </div>:null
          }
        </div>

        <Pagination
            max_size={max_size}
            page={page}
            size={size}
            onChangeSize={(_size) => {
              setSize(_size)
            }}
            onChange={(_page) => {
              setPage(_page)
            }}
        />
      </div>
    </>
  );
}

export default Page;
