/* STATİCS */

/* FUNCTİONS */
export function SetToken(token) {
  localStorage.setItem("userToken", token);
}
export function GetToken() {
  return localStorage.getItem("userToken");
}
export function RemoveToken() {
  localStorage.removeItem("userToken");
}
