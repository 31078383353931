import React, { useState } from "react";
import { Link } from "react-router-dom";

const contacts = [
  {
    title: '"AİLEM" DE NELER VAR?',
    desc:
      "Avantajlarımızı görüntüleyebilir, anketlere katılabilir, ilanlar ve tavsiyelerinizi bizimle paylaşabilir, üst düzey yöneticilerimizle gerçekleştirdiğimiz sohbetlerimizi takip edebilir ve yakınlarınızı uygulamamıza davet edebilirsiniz.",
  },
  {
    title: "PROFİLİMİ NASIL DÜZENLEYEBİLİRİM?",
    desc:
      "Profilinizi,  ana sayfada sağ üst köşedeki “Profil ikonu“'na tıklayarak görüntüleyebilir, güncellemelerinizi kaydedebilirsiniz.",
  },
  {
    title: 'EŞİM YA DA ÇOCUKLARIM "AİLEM" İ KULLANABİLİR Mİ?',
    desc:
      "Tabi ki, seçtiğiniz aile üyelerinize davet linki göndererek uygulamamızı kullanmalarını sağlayabilirsiniz.",
    type: "family",
  },
  {
    title: "ŞİFREMİ NEREDEN DEĞİŞTİREBİLİRİM?",
    desc: "Tam da buradan :)",
    type: "change_password",
  },
  {
    title: "YENİ AVANTAJ ÖNERİM VAR, NASIL İLETEBİLİRİM?",
    desc:
      '"Bize Ulaşın" da konu olarak "Öneri" başlığını seçerek bize yazabilirsiniz.',
  },
  {
    title: "BİR SORUM/ŞİKAYETİM VAR, ŞİMDİ NE YAPABİLİRİM?",
    desc: '"Bize Ulaşın" da uygun konu başlığını seçerek bize yazabilirsiniz.',
  },
];

function Page(props) {
  const [selected, setSelected] = useState();

  return (
    <>
      <div className="location">Bize Ulaşın</div>
      <div className="main">
        <div className="container">
          {/**
           * Start
           */}
          <div className="text-right mt-4 mb-4">
            <Link className="btn custom-btn pl-4 pr-4" to="/app/contact-us/communication">
              BİZE ULAŞIN
            </Link>
          </div>
          {/**
           * End
           */}
          {/**
           * Accordion Start
           */}
          <div className="accordion contact-accordion mt-5  mb-5">
            {contacts
              ? contacts.map((p, i) => (
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target={"#collapse" + i}
                          aria-expanded="false"
                          aria-controls={"collapse" + i}
                        >
                          <div className="head">{p.title}</div>

                          <div className="icon">
                            <i className="fa fa-angle-down"></i>
                          </div>
                        </button>
                      </h5>
                    </div>

                    <div
                      id={"collapse" + i}
                      class={"collapse w-100"}
                      aria-labelledby="headingOne"
                    >
                      <div className="card-body">
                        <p
                          className="contact-desc"
                          style={{ lineHeight: 1.5 }}
                          dangerouslySetInnerHTML={{ __html: p.desc }}
                        ></p>
                        {p.type == "change_password" ? (
                          <Link to={"/app/profile/settings"}>
                            Şifremi Değiştir
                          </Link>
                        ) : null}

                        {p.type == "family" ? (
                          <Link to={"/app/profile/family-members"}>
                            Aile Üyesi Ekle
                          </Link>
                        ) : null}
                        <div className="d-flex  align-items-center">
                          <div className="contact-exp">
                            Bu İçerik Size Yardımcı Oldumu?
                          </div>
                          <div>
                            <button
                              data-toggle="collapse"
                              data-target={"#collapse" + i}
                              aria-expanded="false"
                              aria-controls={"collapse" + i}
                              className="contact-btn-true"
                            >
                              EVET
                            </button>
                            <button
                              onClick={() => {
                                props.history.push(
                                  "/app/contact-us/communication"
                                );
                              }}
                              className="contact-btn-false"
                            >
                              HAYIR
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
          {/**
           * Accordion End
           */}
        </div>
      </div>
    </>
  );
}

export default Page;
