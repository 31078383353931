import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router";
/**
 * Style
 */
import "animate.css";
import "../src/assets/css/master.scss";
import "../src/assets/css/loading.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "rc-slider/assets/index.css";
import 'react-multi-carousel/lib/styles.css';
/**
 * Layouts
 */
import { NavBar, Footer } from "./layouts";
/**
 * Pages
 */
import {
  HomePage,
  BlogsPage,
  MyBlogsPage,
  BlogsAddPage,
  BlogsDetailsPage,
  ProfilePage,
  AdvantagesPage,
  AdvantagesDetailPage,
  AdvantagesFrimaPage,
  SurveysPage,
  SurveyEvaluationPage,
  FamilyTalks,
  AnnouncementsPage,
  AnnouncementsDetailsPage,
  FavoritesPage,
  ContactPage,
  ContactCommunicationPage,
  Error404Page,
  AdvicesPage,
  AdvicesDetailPage,
  AdvertisementsPage,
  AdvertisementsCategoryPage,
  AdvertisementsDetailPage,
  AdvertisementsAddPage,
  AdvertisementsEditPage,
  FirstWorkDayPage,
  MyAdvicesPage,
  MyAdvertisementsPage,
  PlatformsPage,
  PlatformDetay,
  PlatformAsilPage,
  PlatformBilgePage,
  PlatformAselsanGoletiPage,
  PlatformTeknoMaceraPage,
  PlatformGucumuzBirPage,
  BannerPage
} from "./pages";
/**
 * Actions
 */
import { AuthVerify, setAlert } from "./actions";
import {setData,getData} from "./networking/SaveData";
import {getDay} from "./functions/weather";
import {goTop} from "./functions/goTop";

function App({ history }) {
  useEffect(() => {
    AuthVerify((status) => {
      if (status == false) history.push("/login");
    });
  });

  if(getData("url")!=window.location.href){
    goTop();
    setData("url",window.location.href);
  }


  return (
    <>
      <NavBar />
      <Switch>
        <Route path="/app/" render={(props) => <HomePage {...props} />} exact />
        <Route path="/app/blogs" render={() => <BlogsPage />} exact />
        <Route path="/app/myblogs" render={() => <MyBlogsPage />} exact />
        <Route
          path="/app/blogs/add"
          render={(props) => <BlogsAddPage {...props} />}
          exact
        />
        <Route
          path="/app/blogs/details/:detail"
          render={(props) => <BlogsDetailsPage {...props} />}
          exact
        />
        <Route
          path="/app/profile"
          render={(props) => <ProfilePage {...props} />}
        />
        <Route
          path="/app/advantages"
          component={AdvantagesPage}
          //render={(props) => <AdvantagesPage {...props} />}
          exact
        />
        <Route
          path="/app/advantages/:search"
          render={(props) => <AdvantagesPage {...props} />}
          exact
        />
        <Route
          path="/app/advantages/details/:detail"
          render={(props) => <AdvantagesDetailPage {...props} />}
          exact
        />
        <Route
          path="/app/advantages/firma/:firma"
          render={(props) => <AdvantagesFrimaPage {...props} />}
          exact
        />
        <Route
          path="/app/surveys"
          render={(props) => <SurveysPage {...props} />}
          exact
        />
        <Route
          path="/app/surveys/:survey_id"
          render={(props) => <SurveyEvaluationPage {...props} />}
          exact
        />
        <Route
          path="/app/family-talks"
          render={(props) => <FamilyTalks {...props} />}
          exact
        />
        <Route
          path="/app/announcements"
          render={(props) => <AnnouncementsPage {...props} />}
          exact
        />
        <Route
          path="/app/announcements/details/:detail_id"
          render={(props) => <AnnouncementsDetailsPage {...props} />}
          exact
        />
        <Route
          path="/app/favorites"
          render={(props) => <FavoritesPage {...props} />}
          exact
        />
        <Route
          path="/app/contact-us"
          render={(props) => <ContactPage {...props} />}
          exact
        />
        <Route
          path="/app/contact-us/communication"
          render={(props) => <ContactCommunicationPage {...props} />}
          exact
        />
        <Route
          path="/app/advices"
          render={(props) => <AdvicesPage {...props} />}
          exact
        />
        <Route
          path="/app/myadvices"
          render={(props) => <MyAdvicesPage {...props} />}
          exact
        />
        <Route
          path="/app/advices/detail/:advice"
          render={(props) => <AdvicesDetailPage {...props} />}
          exact
        />
        <Route
          path="/app/advertisements"
          render={(props) => <AdvertisementsPage {...props} />}
          exact
        />
        <Route
          path="/app/myadvertisements"
          render={(props) => <MyAdvertisementsPage {...props} />}
          exact
        />
        <Route
          path="/app/advertisements/:category"
          render={(props) => <AdvertisementsCategoryPage {...props} />}
          exact
        />
        <Route
          path="/app/advertisements_detail/:advert_id"
          render={(props) => <AdvertisementsDetailPage {...props} />}
          exact
        />
        <Route
          path="/app/advertisements_edit/:advert_id"
          render={(props) => <AdvertisementsEditPage {...props} />}
          exact
        />
        <Route
          path="/app/advertisement-add"
          render={(props) => <AdvertisementsAddPage {...props} />}
          exact
        />
        <Route
          path="/app/first-work-day"
          render={(props) => <FirstWorkDayPage {...props} />}
          exact
        />
        <Route
          path="/app/platforms"
          render={(props) => <PlatformsPage {...props} />}
          exact
        />
        <Route
            path="/app/platform/:platform_id"
            render={(props) => <PlatformDetay {...props} />}
            exact
        />
        <Route
          path="/app/platforms/gucumuzbir"
          render={(props) => <PlatformGucumuzBirPage {...props} />}
          exact
        />
        <Route
          path="/app/platforms/teknomacera"
          render={(props) => <PlatformTeknoMaceraPage {...props} />}
          exact
        />
        <Route
          path="/app/platforms/aselsangoleti"
          render={(props) => <PlatformAselsanGoletiPage {...props} />}
          exact
        />
        <Route
          path="/app/platforms/abilge"
          render={(props) => <PlatformBilgePage {...props} />}
          exact
        />
        <Route
          path="/app/platforms/asildernegi"
          render={(props) => <PlatformAsilPage {...props} />}
          exact
        />
        <Route
            path="/app/banner/:banner_id"
            render={(props) => <BannerPage {...props} />}
            exact
        />
        {/**
         * 404
         */}
        <Route component={(props) => <Error404Page />} />
      </Switch>
      <Footer history={history} />

    </>
  );
}

export default App;
