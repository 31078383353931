import React, { useEffect, useState } from "react";

const logo = require("../assets/images/platforms/aselsangoleti.png");

function Page() {
  return (
    <>
      <div className="location">Platformlarımız</div>
      <div className="main">
        <div className="container">
          <div className="area">
            <div className="area-body p-5">
              {/**
               *Logo
               */}
              <div
                className="area-logo"
                style={{ backgroundImage: "url(" + logo + ")" }}
              ></div>
              {/**
               * Head
               */}
              <h5 className="h5 color-app mt-5 text-center">
                PİKNİK ALANI KULLANIMINDA DİKKAT EDİLMESİ GEREKEN KONULAR
              </h5>
              {/**
               * P
               */}
              <p className="b mt-5">
                ASELSAN'ın çevreye duyarlı yaklaşımı ile hayata geçirdiği,
                doğada hoşça vakit geçirebileceğiniz, eğlenebileceğiniz Gölbaşı
                Yerleşkemiz sizlere ve misafirlerinize kapılarını açtı. Doğayı
                gelecek nesillere aktarılacak bir emanet olarak görme inanç ve
                sorumluluğumuzun bir yansıması olan ve BİRLİKTELİK değerimizi
                güçlendirecek bu yerleskemizde:
              </p>
              {/**
               * Items
               */}

              <div className="row mt-5">
                {/* 1 */}
                <div className="col-lg-4 aselsan-golet-item">
                  <img
                    src={require("../assets/images/aselsangolet/1.jpg")}
                    className="aselsan-golet-img"
                  />
                </div>
                {/* 2 */}
                <div className="col-lg-4 aselsan-golet-item">
                  <img
                    src={require("../assets/images/aselsangolet/2.jpg")}
                    className="aselsan-golet-img"
                  />
                </div>
                {/* 3 */}
                <div className="col-lg-4 aselsan-golet-item">
                  <img
                    src={require("../assets/images/aselsangolet/3.jpg")}
                    className="aselsan-golet-img"
                  />
                </div>
                {/* 4 */}
                <div className="col-lg-4 aselsan-golet-item">
                  <img
                    src={require("../assets/images/aselsangolet/4.jpg")}
                    className="aselsan-golet-img"
                  />
                </div>
                {/* 5 */}
                <div className="col-lg-4 aselsan-golet-item d-flex flex-column justify-content-between">
                  <p className="b">
                    Toprakla buluşmaya olanak sağlayacak ve bitki
                    yetiştiriciliği yapabilecek 65 adet hobi bahçesi,
                  </p>
                  <p className="b">Harika manzarısıyla gölet alanı,</p>
                  <p className="b">
                    Misafirlerinizle keyifli vakit geçirebileceğiniz piknik ve
                    mangal alanı,
                  </p>
                </div>
                {/* 6 */}
                <div className="col-lg-4 aselsan-golet-item">
                  <img
                    src={require("../assets/images/aselsangolet/5.jpg")}
                    className="aselsan-golet-img"
                  />
                </div>
                {/* 7 */}
                <div className="col-lg-4 aselsan-golet-item">
                  <img
                    src={require("../assets/images/aselsangolet/6.jpg")}
                    className="aselsan-golet-img"
                  />
                </div>
                {/* 8 */}
                <div className="col-lg-4 aselsan-golet-item d-flex flex-column justify-content-between">
                  <p className="b">
                    Her yaşta çocuğumuzun keyifle oyunlar oynayabileceği çocuck
                    parkı ve eğelence alanları,
                  </p>
                  <p className="b">
                    Yürüyüş yapmak istiyenler için doğayla iç içe bir yürüyüş
                    parkuru bulunmakta,
                  </p>
                  <p className="b">
                    Spor severle için kum voleybolu, kum futbolu ve kum ayak
                    tenisi,
                  </p>
                </div>
                {/* 9 */}
                <div className="col-lg-4 aselsan-golet-item">
                  <img
                    src={require("../assets/images/aselsangolet/7.jpg")}
                    className="aselsan-golet-img"
                  />
                </div>
                {/* 10 */}
                <div className="col-lg-4 aselsan-golet-item">
                  <img
                    src={require("../assets/images/aselsangolet/8.jpg")}
                    className="aselsan-golet-img"
                  />
                </div>
                {/* 11 */}
                <div className="col-lg-4 aselsan-golet-item">
                  <img
                    src={require("../assets/images/aselsangolet/9.jpg")}
                    className="aselsan-golet-img"
                  />
                </div>
                {/* 12 */}
                <div className="col-lg-4 aselsan-golet-item">
                  <img
                    src={require("../assets/images/aselsangolet/10.jpg")}
                    className="aselsan-golet-img"
                  />
                </div>
              </div>

              {/**
               * P
               */}
              <p className="mt-5 b">
                <div className="mt-3">
                  Doğada olmanın mutluluğunu yaşayacağımız, ailemiz ve
                  sevdiklerimizle keyifli zamanlar geçireceğimiz yerleşkemizde
                  daha güzel vakit geçirebilmek için aşağıdaki kurallara uymaya
                  özen gösterilmelidir. Piknik alanı; - Pazartesi, Salı,
                  Çarşamba ve Perşembe günleri 08.00-22.00, - Cuma, Cumartesi ve
                  Pazar günleri 08.00-24.00 saatleri arasında kullanımınıza
                  açıktır.
                </div>
                <div className="mt-5">
                  » Piknik alanına yanlarında siz olmak kaydıyla misafirlerinizi
                  getirebilirsiniz. Ayrıca birinci derece akrabalarınız
                  (anne-baba, eş ve çocuklar) siz olmadan da istifade
                  edebilirler.
                </div>
                <div className="mt-3">
                  » Gelen tüm konuklarımızın piknik alanı kurallarına uymaları
                  sağlanmalıdır.
                </div>
                <div className="mt-3">
                  » Piknik alanına evcil hayvan getirmek durumunda olan
                  konuklarımızın bu hayvanları başıboş bırakmamaları, çevreyi
                  kirletmelerine müsaade etmemeleri ve gölet havuzundan uzak
                  tutmaları gereklidir.
                </div>
                <div className="mt-3">
                  » Piknik alanındaki piknik mobilyalarına, bitki ve hayvanlara
                  zarar verilmemelidir.
                </div>
                <div className="mt-3">
                  » Devrilme tehlikesi nedeni ile piknik masaları üzerine
                  semaver konulmamalıdır.
                </div>
                <div className="mt-3">
                  » Mangal alanı dışında ateş yakılmamalıdır (çay semaveri
                  hariç).
                </div>
                <div>» Mangal İstasyonlarında mangal kömürü bulunmaktadır.</div>
                <div className="mt-3">
                  » Yerleşke içerisinde alkollü içecek tüketilmemesine özen
                  gösterilmelidir.
                </div>
                <div>» Gölet kenarındaki taşların üzerine inilmemelidir.</div>
                <div className="mt-3">
                  » Alanda bulunan meyve ağaçları ilaçlı olabileceğinden
                  meyveleri yıkanmadan yenmemelidir.
                </div>
                <div className="mt-3">
                  » Çocuklar; mangal bölgesinden uzak tutulmalı, gölet
                  çevresinde ve çocuk oyun alanında kontrolleri sağlanmalıdır.
                </div>
                <div className="mt-3">
                  » Çocuk oyunlarında ve kum aktivitelerinde kullanılan toplar
                  kendi alanı dışına çıkarılmamalıdır.
                </div>
                <div className="mt-3">
                  » Piknik alanına görüntü ve ses kirliliği yapacak unsurlar
                  kullanılmamalıdır.
                </div>
                <div className="mt-3">
                  » Gölet’e girmenin tehlikeli ve yasak olduğu unutulmamalıdır.
                </div>
                <div className="mt-3">
                  » İki masadan fazla masa birleştirmemeli, kullanılmayacak masa
                  işgal edilmemelidir. 20 kişi ve üzerindeki toplu gelişler için
                  yetkili personele bilgi verilmelidir. Yetkili Personel:
                  Mustafa UYSAL e-posta: uysalGaselsan.com.tr Cep telefonu: 0530
                  918 6025
                </div>
                <div className="mt-3">
                  » Davranışlarından rahatsız olduğunuz konukları doğrudan
                  uyarmak yerine durumu yetkili personele iletmeniz
                  gerekmektedir. Piknik alanında haşere ve kemirgenlere karşı
                  tedbir alınmakla birlikte, karşılaşılması durumunda
                  yetkililere haber verilmelidir.
                </div>
                <div className="mt-3">
                  »Yetkililerin uyarılarına hoşgörü ile yaklaşılmalıdır.
                </div>
                <div className="mt-3">
                  » Piknik sırasında çevrenin temiz tutulmasına özen
                  gösterilmeli; Çöpler. Cinsine uygun olan atık ayrıştırma
                  kutularına atılmalıdır.
                </div>
                <div className="mt-3">
                  » ASELSAN, piknik alanının amacına uygun hizmet edebilmesi
                  için gerekli ilave tedbirler alabilir .
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
