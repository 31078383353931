import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import config from "../configs/config.json";
import {post} from "../networking/Server";
import Loading from "../components/loading";
import {getFile} from "../networking/getFile";
import {addFav} from "../functions/addFav";
import Pagination from "../components/pagination";

const head = "Nike";
const logo =
    "https://c.static-nike.com/a/images/w_1920,c_limit/mdbgldn6yg1gg88jomci/image.jpg";
const phone = "+(90) 551 034 87 52";
const email = "skrthbyk@gmail.com";

const menu = [
    {
        title: "Nike",
        title_img:
            "https://c.static-nike.com/a/images/w_1920,c_limit/mdbgldn6yg1gg88jomci/image.jpg",
        img:
            "https://c.static-nike.com/a/images/w_1920,c_limit/mdbgldn6yg1gg88jomci/image.jpg",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
        price: "168 TL",
        disc: "%20",
        id: "123",
    },
    {
        title: "Nike",
        title_img:
            "https://c.static-nike.com/a/images/w_1920,c_limit/mdbgldn6yg1gg88jomci/image.jpg",
        img:
            "https://c.static-nike.com/a/images/w_1920,c_limit/mdbgldn6yg1gg88jomci/image.jpg",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
        price: "168 TL",
        disc: "%20",
        id: "124",
    },
];

function Page(props) {

    const [state, setState] = useState({
        loading: true,
        page: 1,
        company: {},
        advantages: [],
        size: 9
    });

    const company_id = parseInt(props.match.params.firma);

    useEffect(() => {
        getCompany()
        getAdvantages(state.page)
    }, []);

    function getCompany() {
        post("api/company/get", {company_id}).then((res) => {
            try {
                if (res.result && res.company) {
                    setState(prevState => {
                        return {...prevState, company: res.company, loading: false};
                    })
                }
            } catch (e) {

            }
        });
    }

    function getAdvantages(page, size=state.size) {
        post("api/company/get_advantages", {company_id, size, page}).then((res) => {
            try {
                if (res.result && res.advantages) {
                    setState(prevState => {
                        return {...prevState, advantages: res.advantages, page, loading: false, size};
                    })
                }
            } catch (e) {

            }
        });
    }


    const {name, logo, address, phone, email} = state.company;

    return (
        <>
            <Loading loading={state.loading}/>
            <div className="location">{name}</div>
            <div className="main">
                <div className="container">
                    {/**
                     * Advantage İnformations
                     */}
                    <div className="advantages-informations mt-5">
                        <div
                            className="advantages-information-logo"
                            style={{backgroundImage: "url(" + getFile(logo) + ")"}}
                        ></div>
                        <div className="advantages-information-head">{name}</div>
                        <div className="advantages-information-sections row mt-4">
                            {
                                phone ?
                                    <div className="advantages-information-item col-lg-4 col-md-6 col-sm-12">
                                        <i className="fa fa-phone"/>
                                        <span>{phone}</span>
                                    </div> : null
                            }

                            {
                                email ?
                                    <div className="advantages-information-item col-lg-4 col-md-6 col-sm-12">
                                        <i className="fa fa-envelope"/>
                                        <span>{email}</span>
                                    </div> : null
                            }

                            {
                                address ?
                                    <div className="advantages-information-item col-lg-4 col-md-6 col-sm-12">
                                        <i className="fa fa-map-marker"/>
                                        <span>{address}</span>
                                    </div> : null
                            }
                        </div>
                    </div>
                    {
                        //Menu Start
                    }
                    <div className="advantages-menu row">
                        {state.advantages && state.advantages.map(({advantage_id, company, slider, advantage_favorites, title, discount_rate, discount_price}, i) => {
                            let image = "";
                            try {
                                image = JSON.parse(slider)[0]
                            } catch (e) {

                            }

                            return (
                                <div
                                    className="advantages-menu-item col-lg-4 col-md-6 col-sm-12"
                                    key={i}
                                >

                                    <div className="advantages-menu-item-content">
                                        <Link
                                            className="advantages-menu-item-img d-block"
                                            style={{backgroundImage: "url(" + getFile(image) + ")"}}
                                            to={`/app/advantages/details/${advantage_id}`}
                                        >
                                            {
                                                discount_price ?
                                                    <div className="advantages-menu-item-img-price">
                                                        {discount_price}
                                                    </div> : null
                                            }
                                            {
                                                discount_rate ?
                                                    <div className="advantages-menu-item-img-disc">
                                                        {discount_rate}
                                                    </div> : null
                                            }

                                        </Link>
                                        <div className="d-flex justify-content-between mt-3">
                                            <Link
                                                className="advantages-text"
                                                to={`/app/advantages/details/${advantage_id}`}
                                            >
                                                {title}
                                            </Link>
                                            <div>
                                                {advantage_favorites?.length ? (
                                                    <div onClick={() => {
                                                        addFav(setState, advantage_id).then(() => {})

                                                        let advantages = state.advantages;
                                                        advantages[i]['advantage_favorites']=[];
                                                        setState((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                advantages,
                                                            };
                                                        });
                                                    }}><i className="advantages-heart fa fa-heart favorite-heart"/>
                                                    </div>
                                                ) : (
                                                    <div onClick={() => {
                                                        addFav(setState, advantage_id).then(() => {})
                                                        let advantages = state.advantages;
                                                        advantages[i]['advantage_favorites']=[""];
                                                        setState((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                advantages,
                                                            };
                                                        });

                                                    }}><i className="advantages-heart fa fa-heart-o"/></div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {
                    //Pagintaion
                }
                <Pagination
                    page={state.page}
                    size={state.size}
                    onChangeSize={(size) => {
                        setState(prevState => {
                            return {...prevState, loading: true};
                        })
                        getAdvantages(state.page, size)
                    }}
                    onChange={(page) => {
                        setState(prevState => {
                            return {...prevState, loading: true};
                        })
                        getAdvantages(page)
                    }}
                />
            </div>
        </>
    );
}

export default Page;
