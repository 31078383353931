import React, { useEffect, useState } from "react";


const logo = require("../assets/images/platforms/gucumuzbir_.png");

function Page() {
  return (
    <>
      <div className="location">Platformlarımız</div>
      <div className="main">
        <div className="container">
          <div className="area">
            <div className="area-body p-5">
              {/**
               *Logo
               */}
              <div
                className="area-logo"
                style={{ backgroundImage: "url(" + logo + ")" }}
              ></div>

              {/**
               * P
               */}
              <p className="mt-5 b">
                Dünyanın en büyük savunma sanayi firmaları listesinde 48. sırada
                yer alan ve listedeki yükselişini her geçen yıl sürdüren
                Şirketimiz, her alanda yenilikçi ve öncü çalışmalara imza atmaya
                devam ediyor. Tedarikçilerimiz ile aramızdaki bağı güçlendirmek,
                millileştirme çalışmalarını dijital ortama taşıyarak etkinliğini
                artırmak ve dışa bağımlılığı daha da azaltmak, insan kaynakları
                potansiyelimizi tedarikçilerimiz ile paylaşabilmek, ortak sosyal
                sorumluluk çalışmaları gerçekleştirmek, eğitimler ile
                tedarikçilerimizin yetkinliklerine katkıda bulunmak, sektöre
                özel rapor ve değerlendirmeleri paylaşmak ve işbirliğini entegre
                bir çatı altında yürütmek amacı ile kapsamı bakımından dünyada
                ilk olacak öncü ve yenilikçi bir platformu hizmete açtık.
              </p>

              {/**
               * İMG
               */}
              <div className="d-flex justify-content-center">
                <div
                  className="area-content-img mt-5 col-md-6"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("../assets/images/contents/gucumuzbir1.png") +
                      ")",
                  }}
                ></div>
              </div>

              {/**
               * P
               */}
              <p className="mt-5 b">
                “Gücümüz Bir” hedef kitlesi ASELSAN’ın mevcut ve potansiyel
                tedarikçileri olan interaktif bir etkileşim ve gelişim
                platformudur. Bu platform kurumsal bir web sitesi
                (www.gucumuzbir.com) ve mobil uygulamalar aracılığı ile hizmet
                vermektedir.
              </p>

              {/**
               * İMG
               */}
              <div className="d-flex justify-content-center">
                <div
                  className="area-content-img mt-5 col-md-6"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("../assets/images/contents/gucumuzbir2.png") +
                      ")",
                  }}
                ></div>
              </div>

              {/**
               * P
               */}
              <p className="mt-5 b">
                Mevcut tedarikçilerimizle ilişkilerin güçlendirilmesi,
                potansiyel katma değer sağlayıcı tedarikçilerin ekosistemimize
                kazandırılması ve ASELSAN tedarik ekosisteminin
                sürdürülebilirliğine katkı sunulması temel hedefimizdir.
              </p>

              {/**
               * P
               */}
              <p className="mt-5 b">
                Bu amaçla; özellikle“Eğitim/Geliştirme” bölümümüzde yer alan
                eğitimlerimiz ile yetkinliğin artırılmasına yönelik
                çalışmalarımız ve “İK” modülümüz üzerinden insan kaynakları
                portföyümüzün belli kriterler dahilinde paylaşılması ile
                tedarikçilerimize önemli derecede fayda sağlayacağımıza
                inanıyoruz
              </p>

              {/**
               * İMG
               */}
              <div className="d-flex justify-content-center">
                <div
                  className="area-content-img mt-5 col-md-6"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("../assets/images/contents/gucumuzbir3.png") +
                      ")",
                  }}
                ></div>
              </div>

              {/**
               * P
               */}
              <p className="mt-5 b">
                İlave olarak; etkinlikler öncesinde toplantı ve randevu
                taleplerinin yönetilebileceği ve etkinlik duyurularımızın
                yapıldığı “Etkinlikler” bölümü, tedarikçilerimizin duyurmak
                istedikleri önemli haberlerin yayınlandığı “Tedarikçilerimizden”
                bölümü, son olarak güncel araştırma, rapor ve ekonomik verilerin
                yayınladığı “Araştırma/Raporlar” bölümü ile içeriği sürekli
                güncellenecek olan bu yapı desteklerinizle günden güne
                büyüyecektir.
              </p>

              {/**
               * WWW LİNK
               */}
              <div className="mt-5 d-flex justify-content-start align-items-center">
                <img
                  className="mr-3"
                  src={require("../assets/images/www.png")}
                  width="50px"
                  height="50px"
                />
                <a className="btn-link b color-app" href="https://www.gucumuzbir.com/" target="_blank">
                  / www.gucumuzbir.com
                </a>
              </div>

              {/**
               * Barkod
               */}
              <div className="b color-app text-center mt-5">
                Mobil uygulamamızı aşağıdaki barkodları okutarak telefonunuza
                indirebilirsiniz.
              </div>
              <div className="d-flex justify-content-center">
                <img
                className="col-lg-6 col-md-8 col-sm-12"
                  src={require("../assets/images/contents/gucumuzbir-app.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
