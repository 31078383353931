import React, { useState, useEffect } from "react";
/**
 * Configs
 */
import config from "../configs/config.json";
/**
 * Actions
 */
import {AuthForgotPass, AuthForgotPassCode, AuthLogout, setAlert} from "../actions";
import {Link} from "react-router-dom";
import {post} from "../networking/Server";
import Loading from "../components/loading";

function Page(props ) {

  const [email, setEmail] = useState();
  const [code, setCode] = useState();
  const [password, setPassword] = useState();
  const [repassword, setRePassword] = useState();
  const [state, setState] = useState({
    loading:false,
    page:1
  });


  useEffect(() => {
  }, []);


  function emailControl(email) {
    if(!email)
      return;

    setState(prevState => {
      return {...prevState, loading:true};
    });
    post("forgot_password",{email}).then((res)=>{
      setState(prevState => {
        return {...prevState, loading:false,page:1};
      });
      try {
        if(res.result){
          setState(prevState => {
            return {...prevState, page:2};
          });
        }else {
          setAlert("Email Bulunamadı")
        }
      }catch (e) {

      }
    });
  }

  function codeControl(email,code) {
    if(!email || !code)
      return;

    setState(prevState => {
      return {...prevState, loading:true};
    });
    post("forgot_password",{email,code}).then((res)=>{
      setState(prevState => {
        return {...prevState, loading:false,page:2};
      });
      try {
        if(res.result){
          setState(prevState => {
            return {...prevState, page:3};
          });
        }else {
          setAlert("Hatalı Kod Girdiniz")
        }
      }catch (e) {

      }
    });
  }

  function changePassword(email,code,password,repassword) {
    if(!email || !code || !password)
      return;

    if(password.length < 8){
      setAlert("En Az 8 Karakterli Bir Şifre Oluşturunuz");
      return;;
    }

    if(password!=repassword){
      setAlert("Şifreler Uyuşmuyor");
      return;
    }

    setState(prevState => {
      return {...prevState, loading:true};
    });
    post("forgot_password",{email,code,password}).then((res)=>{
      setState(prevState => {
        return {...prevState, loading:false,page:3};
      });
      try {
        if(res.result){
          setAlert("Şifreniz Başarı İle Değiştirilmiştir. Tekrar Giriş Yapınız..","success");
          props.history.push("(login");
        }else {
          setAlert("Bir Sorun Oluştu")
        }
      }catch (e) {

      }
    });
  }

  return (
    <div className="login-container">
      <Loading loading={state.loading}/>
      <div className="login-thumb">
        <div
          className="login-thumb-img"
          style={{
            backgroundImage: `url(${require("../assets/images/login-thumb.png")})`,
          }}
        ></div>
      </div>
      <div className="login-wrapper">
        {/**
         * Login Form
         */}
        {
          state.page==1?
              <div className="login-form">
                <h2 className="login-head">Şifremi Unuttum</h2>
                <div className="login-link-group text-center mb-5">
                  <p>Daha Önce Kayıt Olduğunuz Email Adresinizi Bize Söyleyebilir Misiniz?</p>
                </div>
                {/**
                 * Login Input
                 */}
                <div className="form-group mb-4 login-form-group">
                  <div
                      className="login-input-icon"
                      style={{
                        backgroundImage: `url(${require("../assets/images/icons/mail.png")})`,
                      }}
                  ></div>
                  <input
                      value={email ? email : ""}
                      className="login-input"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {/**
                 * Login Buttons
                 */}
                <div className="btn-login-group">
                  <button
                      className="btn btn-login"
                      onClick={() => {
                        emailControl(email)
                      }}
                  >
                    Gönder
                  </button>
                </div>

                <div className="login-link-group text-center">
                  <span>Şifrenizi Hatırlıyormusunuz? </span>
                  <Link
                      className="login-link font-weight-bold"
                      to={"/login"}
                  >
                    Giriş Yap
                  </Link>
                </div>
              </div>:
              state.page==2?
                  <div className="login-form">
                    <h2 className="login-head">Şifremi Unuttum - Kod Kontrol</h2>
                    <div className="login-link-group text-center mb-5">
                      <p>
                        Email Hesabınıza Gelecek Olan Onay Kodunu Aşağıdaki Alana Giriniz.
                        <br/>
                        <br/>
                        Email Adresinize Kod Gelmediyse Spamı Kontrol Etmeyi Unutmayınız.
                      </p>
                    </div>
                    {/**
                     * Login Input
                     */}
                    <div className="form-group mb-4 login-form-group">
                      <div
                          className="login-input-icon"
                          style={{
                            backgroundImage: `url(${require("../assets/images/icons/password.png")})`,
                          }}
                      ></div>
                      <input
                          value={code ? code : ""}
                          className="login-input"
                          type="password"
                          placeholder="Kod"
                          onChange={(e) => setCode(e.target.value)}
                      />
                    </div>
                    {/**
                     * Login Buttons
                     */}
                    <div className="btn-login-group">
                      <button
                          className="btn btn-login"
                          onClick={()=>{
                            codeControl(email,code)
                          }}
                      >
                        Kontrol Et
                      </button>
                    </div>


                  </div>:
                  state.page==3?
                      <div className="login-form">
                        <h2 className="login-head">Şifremi Değiştirl</h2>
                        <div className="login-link-group text-center mb-5">
                          <p>
                            Hesabınızın Size Ait Olduğunu Onayladınız, Artık Şifrenizi Değiştirme Zamanı.
                          </p>
                        </div>
                        {/**
                         * Login Input
                         */}
                        <div className="form-group mb-4 login-form-group">
                          <div
                              className="login-input-icon"
                              style={{
                                backgroundImage: `url(${require("../assets/images/icons/password.png")})`,
                              }}
                          ></div>
                          <input
                              value={password ? password : ""}
                              className="login-input"
                              type="password"
                              placeholder="Şifreniz"
                              onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>

                        <div className="form-group mb-4 login-form-group">
                          <div
                              className="login-input-icon"
                              style={{
                                backgroundImage: `url(${require("../assets/images/icons/password.png")})`,
                              }}
                          ></div>
                          <input
                              value={repassword ? repassword : ""}
                              className="login-input"
                              type="password"
                              placeholder="Şifre Tekrar"
                              onChange={(e) => setRePassword(e.target.value)}
                          />
                        </div>
                        {/**
                         * Login Buttons
                         */}
                        <div className="btn-login-group">
                          <button
                              className="btn btn-login"
                              onClick={()=>{
                                changePassword(email,code,password,repassword)
                              }}
                          >
                            Değiştir
                          </button>
                        </div>


                      </div>:null

        }
      </div>
    </div>
  );
}

export default Page;
