

export const frames = [
    {
        id: 1,
        frame: require("../assets/images/frames/1.png"),
    },
    {
        id: 2,
        frame: require("../assets/images/frames/2.png"),
    },
    {
        id: 3,
        frame: require("../assets/images/frames/3.png"),
    },
    {
        id: 4,
        frame: require("../assets/images/frames/4.png"),
    },
    {
        id: 5,
        frame: require("../assets/images/frames/5.png"),
    },
    {
        id: 6,
        frame: require("../assets/images/frames/6.png"),
    },
    {
        id: 7,
        frame: require("../assets/images/frames/8.png"),
    },
    {
        id: 8,
        frame: require("../assets/images/frames/9.png"),
    },
];


export function getFrame(id) {

    let path = "";
    frames.map(function (val) {
        if(val.id==id)
            path=val.frame;
    })

    return path;
}
