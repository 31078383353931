import React from "react";
import { Link } from "react-router-dom";
import { getFile } from "../networking/getFile";
import { post } from "../networking/Server";

class Banner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            banners: []
        }
        this.getBanners(this.props.banner_type)
    }

    componentDidMount() {

    }

    createBannerViews(banners) {

        let views = [];

        if (!Array.isArray(banners) || !banners.length)
            views.push(
                <Link
                    className={"carousel-item active"}
                    key={"0"}
                >
                    <div
                        className="carousel-img"
                        style={{
                            backgroundImage: "url(https://ailemaselsan.com.tr:2053/uploads/noadvantage.png)",
                        }}
                    ></div>
                </Link>
            );

        try {
            banners.map(({ advantage, notice, blog, survey, advice, advert, banner_id, image, title }, index) => {
                if (image && title) {
                    views.push(
                        <Link
                            className={"carousel-item" + (index == 0 ? " active" : "")}
                            key={index}
                            to={"/app/banner/" + banner_id}
                        >
                            <div
                                className="carousel-img"
                                style={{
                                    backgroundImage: "url(" + getFile(image) + ")",
                                }}
                            ></div>
                        </Link>
                    )
                }
                else if (advert) {
                    let image = ""; try { image = JSON.parse(advert.images)[0]; } catch (e) { }
                    views.push(
                        <Link
                            className={"carousel-item" + (index == 0 ? " active" : "")}
                            key={index}
                            to={"/app/advertisements_detail/" + advert.advert_id}
                        >
                            <div
                                className="carousel-img"
                                style={{
                                    backgroundImage: "url(" + getFile(image) + ")",
                                }}
                            ></div>
                        </Link>
                    )
                }
                else if (advice) {
                    views.push(
                        <Link
                            className={"carousel-item" + (index == 0 ? " active" : "")}
                            key={index}
                            to={"/app/advices/detail/" + advice.advices_id}
                        >
                            <div
                                className="carousel-img"
                                style={{
                                    backgroundImage: "url(" + getFile(advice.image) + ")",
                                }}
                            ></div>
                        </Link>
                    )
                }
                else if (survey) {
                    views.push(
                        <Link
                            className={"carousel-item" + (index == 0 ? " active" : "")}
                            key={index}
                            to={"/app/surveys/" + survey.survey_id}
                        >
                            <div
                                className="carousel-img"
                                style={{
                                    backgroundImage: "url(" + getFile(survey.image) + ")",
                                }}
                            ></div>
                        </Link>
                    )
                }
                else if (blog) {
                    views.push(
                        <Link
                            className={"carousel-item" + (index == 0 ? " active" : "")}
                            key={index}
                            to={"/app/blogs/details/" + blog.blog_id}
                        >
                            <div
                                className="carousel-img"
                                style={{
                                    backgroundImage: "url(" + getFile(blog.image) + ")",
                                }}
                            ></div>
                        </Link>
                    )
                }
                else if (notice) {
                    views.push(
                        <Link
                            className={"carousel-item" + (index == 0 ? " active" : "")}
                            key={index}
                            to={"/app/announcements/details/" + notice.notice_id}
                        >
                            <div
                                className="carousel-img"
                                style={{
                                    backgroundImage: "url(" + getFile(notice.image) + ")",
                                }}
                            ></div>
                        </Link>
                    )
                }
                else if (advantage) {

                    let { advantage_id, discount_rate, discount_price, slider } = advantage;
                    let image = ""; try { image = JSON.parse(slider)[0]; } catch (e) { }
                    views.push(
                        <Link
                            className={"carousel-item advantage-detail-page" + (index == 0 ? " active" : "")}
                            key={index}
                            to={`/app/advantages/details/${advantage_id}`}
                        >
                            <div
                                className="carousel-img"
                                style={{
                                    objectFit: "contain",
                                    backgroundImage: "url(" + getFile(image) + ")",
                                }}
                            ></div>
                            {discount_price ? (
                                <div className="advantages-menu-item-img-price">
                                    {discount_price}₺
                                </div>
                            ) : null}
                            {discount_rate ? (
                                <div className="advantages-menu-item-img-disc">
                                    {discount_rate}%
                                </div>
                            ) : null}
                        </Link>
                    )
                }
            })

        } catch (e) {
            console.warn("slider error", e)
        }


        return views

    }

    createBannerViews2(banners) {

        let views = [];

        if (!Array.isArray(banners) || !banners.length)
            views.push(
                <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to={"0"}
                    className={"active"}
                ></li>
            )

        try {
            banners.map(({ advantage, notice, blog, survey, advice, advert, banner_id, image, title }, index) => {
                if (image && title) {
                    views.push(
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={index.toString()}
                            className={index == 0 ? "active" : ""}
                        ></li>
                    )
                }
                else if (advert) {
                    let image = ""; try { image = JSON.parse(advert.images)[0]; } catch (e) { }
                    views.push(
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={index.toString()}
                            className={index == 0 ? "active" : ""}
                        ></li>
                    )
                }
                else if (advice) {
                    views.push(
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={index.toString()}
                            className={index == 0 ? "active" : ""}
                        ></li>
                    )
                }
                else if (survey) {
                    views.push(
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={index.toString()}
                            className={index == 0 ? "active" : ""}
                        ></li>
                    )
                }
                else if (blog) {
                    views.push(
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={index.toString()}
                            className={index == 0 ? "active" : ""}
                        ></li>
                    )
                }
                else if (notice) {
                    views.push(
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={index.toString()}
                            className={index == 0 ? "active" : ""}
                        ></li>
                    )
                }
                else if (advantage) {
                    views.push(
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={index.toString()}
                            className={index == 0 ? "active" : ""}
                        ></li>
                    )
                }
            })

        } catch (e) {
            console.warn("slider error", e)
        }


        return views

    }


    async getBanners(banner_type) {
        const res = await post('api/banners/get', { banner_type })

        if (!res.result)
            return null;


        this.setState({
            banners: res.banners
        })
    }


    render() {
        return (
            <>
                <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-ride="carousel"
                //data-interval="false"
                >
                    <ol className="carousel-indicators" style={{ [this.props.top ? 'top' : 'bottom']: 0 }}>
                        {
                            this.createBannerViews2(this.state.banners).map((banner) => (banner))
                        }
                    </ol>
                    <div className="carousel-inner">
                        {
                            this.createBannerViews(this.state.banners).map((banner) => (banner))
                        }

                    </div>
                    <a
                        className="carousel-control-prev"
                        href="#carouselExampleIndicators"
                        role="button"
                        data-slide="prev"
                    >
                        <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                        ></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a
                        className="carousel-control-next"
                        href="#carouselExampleIndicators"
                        role="button"
                        data-slide="next"
                    >
                        <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                        ></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </>
        )
    }
}

export default Banner
