import React, { useEffect, useState } from "react";

const logo = require("../assets/images/platforms/abilge.png");

function Page() {
  return (
    <>
      <div className="location">Platformlarımız</div>
      <div className="main">
        <div className="container">
          <div className="area">
            <div className="area-body p-5">
              {/**
               *Logo
               */}
              <div
                className="area-logo"
                style={{ backgroundImage: "url(" + logo + ")" }}
              ></div>
              {/**
               * P
               */}
              <p className="b mt-5">
                Öğrenme ve gelişim faaliyetlerinin tek bir sistem üzerinden
                bütünsel olarak yönetimi, dokümantasyonu, takibi, ölçümü ve
                raporlanması, ASELSAN çalışanlarına ihtiyaç duydukları
                eğitimlerin e-öğrenme destekli olarak da sunulabilmesi ve
                çalışanların öğrenme ve gelişim bilgilerine kolayca ulaşabilmesi
                amacıyla tasarlanan yeni Öğrenme ve Gelişim Platformu “a BİL-GE”
                2019 yılında tüm ASELSAN çalışanlarının kullanımına açılmıştır.
                a BİL-GE’nin internet ve mobil erişimi ise 2020 yılının Nisan
                ayında tüm çalışanların kullanımına açılmıştır.
              </p>
              {/**
               * WWW LİNK
               */}
              <div className="mt-5 d-flex justify-content-start align-items-center">
                <img
                  className="mr-3"
                  src={require("../assets/images/www.png")}
                  width="50px"
                  height="50px"
                />
                <a
                  className="btn-link b color-app mt-4"
                  href="https://abilge.myenocta.com"
                  target="_blank"
                >
                  / https://abilge.myenocta.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
