import React, {useEffect, useState} from "react";

/**
 * Components
 */
import {BlogBox} from "../components";
import {Link} from "react-router-dom";
import {post} from "../networking/Server";
import Loading from "../components/loading";
import {getFile} from "../networking/getFile";
import {convertDate} from "../functions/convertDate";
import Pagination from "../components/pagination";

function Page() {
    const [state, setState] = useState({
        loading: true,
        page: 1,
        blogs: [],
        size: 9
    });

    useEffect(() => {
        getBlogs(state.page);
    }, []);

    function getBlogs(page, size = state.size) {
        post("api/users/get_blogs", {size, page}).then((res) => {
            try {
                if (res.result) {
                    setState((prevState) => {
                        return {...prevState, blogs: res.blogs, loading: false, page, size};
                    });
                }
            } catch (e) {
            }
        });
    }

    return (
        <>
            <Loading loading={state.loading}/>
            <div className="location">Köşe Yazılarımız</div>
            <div className="main">
                <div className="container">
                    <div className="page-bar mt-5">
                        <Link to="/app/blogs/add" className="page-bar-btn">
                            <img
                                className="page-bar-btn-back"
                                src={require("../assets/images/add.png")}
                            />
                        </Link>
                        <Link to="/app/blogs/add" className="page-bar-head">
                            Köşe Yazısı Ekle
                        </Link>
                    </div>

                    {
                        !state.loading && Array.isArray(state.blogs) && !state.blogs.length?
                            <div style={{textAlign:'center', display:'flex',justifyContent:'center',fontWeight:'center',fontSize:20}}>Fikirlerinizi merak ediyoruz! Kendi köşe yazınızı yukarıdaki + ikonuna basarak oluşturabilir ve bizimle paylaşabilirsiniz.</div>:null

                    }
                    <div className="row ">
                        {state.blogs
                            ? state.blogs.map(
                                (
                                    {
                                        blog_id,
                                        short_desc,
                                        title,
                                        image,
                                        content,
                                        user,
                                        createdAt,
                                    },
                                    i
                                ) => (
                                    <>
                                        <BlogBox
                                            date={convertDate(createdAt)}
                                            id={blog_id}
                                            key={i}
                                            img={getFile(image)}
                                            head={title}
                                            desc={content}
                                            short_desc={short_desc}
                                            writer={user?.name + " " + user?.surname}
                                            offset={i % 2 !== 0 ? true : false}
                                            edit={true}
                                            onEdit={() => alert("edit")}
                                        />
                                    </>
                                )
                            )
                            : null}
                    </div>
                </div>
                {
                    //Pagintaion
                }
                <Pagination
                    page={state.page}
                    size={state.size}
                    onChangeSize={(size) => {
                        setState(prevState => {
                            return {...prevState, loading: true};
                        })
                        getBlogs(state.page, size)
                    }}
                    onChange={(page) => {
                        setState(prevState => {
                            return {...prevState, loading: true};
                        })
                        getBlogs(page)
                    }}
                />
            </div>
        </>
    );
}

export default Page;
