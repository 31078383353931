import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "@tinymce/tinymce-react";
import { post } from "../networking/Server";
import Loading from "../components/loading";
import striptags from "striptags";

function Page(props) {
  /**
   * States
   */

  const [state, setState] = useState({
    loading: false,
    page: 1,
    title: null,
    short_desc: "",
    content: "",
    files: null,
    added: false,
  });

  useEffect(() => {}, []);

  function handleFile(e) {
    let files = e.target?.files;
    setState((prevState) => {
      return { ...prevState, files };
    });
  }

  function handleEditorChange(content, editor) {
    const regex = /<div(\w|[=":;-]|\s?)*>|<\/div>/g;
    const value = content?.replace(regex, "");

    setState((prevState) => {
      return { ...prevState, content: value };
    });
  }

  function addBlog() {
    const { title, content, files } = state;

    if (!title || !content || !files || !files?.length) return;

    let short_desc = striptags(content);

    short_desc = `${short_desc.substring(0, 255)}${
      short_desc.length > 255 ? "..." : ""
    }`;

    let formData = new FormData();

    Array.from(files).forEach((e) => formData.append("banner", e));
    formData.append("title", title);
    formData.append("short_desc", short_desc);
    formData.append("content", content);

    formData.append("web", "1");

    setState((prevState) => {
      return { ...prevState, loading: true };
    });
    post("api/blogs/add", formData).then((res) => {
      try {
        if (res.result) {
          setState((prevState) => {
            return { ...prevState, loading: false, added: true };
          });
        }
      } catch (e) {}
    });
  }

  return (
    <>
      <Loading loading={state.loading} />
      <div className="location">Köşe Yazılarımız</div>
      <div className="main">
        <div className="container">
          <div className="form-area mt-5 mb-5">
            <div className="form-head mb-5">Köşe Yazısı Ekle</div>
            <div className="row">
              <div className="form-group col-md-12">
                <label className="mb-2 col-md-12 p-0" htmlFor="head">
                  Başlık
                </label>
                <input
                  disabled={state.added}
                  placeholder={"Başlık"}
                  className="form-input col-md-12"
                  value={state.title}
                  onChange={(e) => {
                    let text = e.target.value;
                    setState((prevState) => {
                      return { ...prevState, title: text };
                    });
                  }}
                />
              </div>

              <div className="form-group col-md-12">
                <label className="mb-4 p-0" htmlFor="photo">
                  Köşe Yazısı Fotoğrafı
                </label>
                <input
                  accept={"image/*"}
                  disabled={state.added}
                  onChange={handleFile}
                  type="file"
                  className="form-control-file"
                  id="photo"
                />
              </div>
              {/*
                            <div className="form-group col-md-6">
                                <label className="mb-2 col-md-12 p-0" htmlFor="short_desc">
                                    Kısa Açıklama
                                </label>
                                <textarea
                                    disabled={state.added}
                                    id="short_desc"
                                    className="form-input col-md-12"
                                    onChange={(e) => {
                                        let text = e.target.value;
                                        setState(prevState => {
                                            return {...prevState, short_desc: text};
                                        });
                                    }}
                                ></textarea>
                            </div>*/}
            </div>

            <div className="editor-container">
              <Editor
                disabled={state.added}
                apiKey="v8hkiaj7g7zgydy4an0b6q8ivesxuwsd0kconvll9gr28619"
                initialValue={state.content}
                plugins={["emoticons", "lists"]}
                toolbar={
                  "undo redo | styleselect | fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | emoticons"
                }
                init={{
                  height: 500,
                  menubar: false,
                  language: "tr",
                  paste_as_text: true,
                }}
                onEditorChange={handleEditorChange}
              />
              {!state.added ? (
                <div className="d-flex justify-content-center w-100 mt-5">
                  <button
                    onClick={() => {
                      addBlog();
                    }}
                    className="btn form-btn"
                  >
                    Gönder
                  </button>
                </div>
              ) : null}
            </div>
            {state.added ? (
              <div className="form-alert">
                <div className="form-alert-close">x</div>
                <div className="form-alert-message">
                  <div className="mb-2">Katkılarınız için Teşekkürler</div>
                  <div>Köşe Yazınız Değerlendirmeye Alınmıştır.</div>
                </div>
                <div
                  className="form-alert-thumb"
                  style={{
                    backgroundImage:
                      "url(" + require("../assets/images/icons/like.png") + ")",
                  }}
                ></div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
