import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../components/loading";
import {post} from "../networking/Server";

const konular = [
  {
    label: "Konu Seçiniz",
    value: 0,
  },
  {
    label: "Şikayet",
    value: 1,
  },
  {
    label: "Öneri",
    value: 2,
  },
  {
    label: "Diğer",
    value: 3,
  },
];

const contact_desc =
  "Bu web sitesini daha iyi hale getirmek için bizimle iletişime geçiniz";

/**
 * tamamlandı Tasarımı için => true
 */


function Page() {
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);
  const [complate, setComplate] = useState(false);

  function setContactUs(title,desc){

    if(!title.trim() || !desc.trim())
      return;


    setLoading(true);
    post("api/contact_reports/add",{title,message:desc}).then((res)=>{
      try {
        if(res.result){
          setComplate(true);
          setLoading(false)
        }
      }catch (e) {

      }
    });
  }

  return (
    <>
      <Loading loading={loading}/>
      <div className="location">Asil Avantajlar</div>
      <div className="main">
        <div className="container">
          <div className="contact-us-wrapper row">
            <div className="col-md-6">
              <div className="contact-us-form">
                {complate == false ? (
                  <>
                    <div className="form-group">
                      <label className="mb-2 w-100">
                        Konu Ekle <span className="require">*</span>
                      </label>
                      <select onChange={e=>setTitle(e.target.value)} className="col-md-12 form-control">
                        {konular
                          ? konular.map((p, i) => (
                              <option value={p.value}>{p.label}</option>
                            ))
                          : null}
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="mb-2 w-100">
                        Açıklama Ekle <span className="require">*</span>
                      </label>
                      <textarea maxLength={500} onChange={e=>setDesc(e.target.value)} className="form-control"></textarea>
                    </div>
                    <div className="form-group col-md-12 text-center">
                      <button onClick={()=>{
                        setContactUs(title,desc)
                      }} className="btn mt-4 custom-btn">Gönder</button>
                    </div>
                  </>
                ) : (
                  <div className="contact-us-complate">
                    <p>Geri bildiriminiz için teşekkür ederiz.</p>
                    <p className="mt-1">
                      Size en kısa sürede dönüş sağlayacağız.
                    </p>
                    <img className="contact-us-tick" src={require("../assets/images/tick.png")} />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-us-infos">
                <h3 className="h3 mb-4">İletişim Detayları</h3>
                <p>{contact_desc}</p>
                {/*<a className="contact-to  mt-3" href="mailto:name@email.com">
                  <i className="fa fa-envelope mr-3"></i> name@email.com
                </a>
                <a className="contact-to mt-3" href="tel:9999999999">
                  <i className="fa fa-phone mr-3"></i> 9999999999
                </a>
                <a
                  className="contact-to mt-3"
                  href="https://www.google.com.tr/maps"
                >
                  <i className="fa fa-map mr-3"></i> Google Haritasında
                  Görüntüle
                </a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
