/* STATİCS */
/* HELPERS */
import { post } from "../helpers/https";
/* ACTİONS */
import { SetToken, GetToken, RemoveToken } from "./";
import { setData } from "../networking/SaveData";
import { setAlert } from "../actions";
/* FUNCTİONS */

export function AuthLogin(email, pass, fun, fun2) {
  const body = { email: email, password: pass };
  post(`login`, body).then((res) => {
    fun2();
    if (res.result) {
      SetToken(res.token);
      setData("user", res.user);
      fun();
    } else {
      if (res.error == "not_found") {
        setAlert("Email Veya Şifre Yanlış", "danger");
      } else if (res.error == "ban") {
        setAlert("Kullanıcı Hesabı Kapatılmışi", "danger");
      } else {
        setAlert("Giriş Yapılırken Bir Hata Meydana Geldi", "danger");
      }
    }
  });
}

export function AuthRegister(name, surname, email, pass, repass, iagree) {
  alert("register");
}

export function AuthForgotPass(email, fun) {
  alert("forgot-password");
  fun();
}

export function AuthForgotPassCode(code) {
  alert("forgot-password-code");
}

export function AuthVerify(fun) {
  const token = GetToken();
  if (token) {
    fun(true);
  } else {
    fun(false);
  }
}

export function AuthLogout(fun) {
  RemoveToken();
}
