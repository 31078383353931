import React, { useState, useEffect } from "react";
/**
 * Configs
 */
import config from "../configs/config.json";
/**
 * Actions
 */
import {AuthLogin, AuthLogout, setAlert} from "../actions";
import { Link } from "react-router-dom";
import Loading from "../components/loading";

function Page({ history }) {
  /**
   * Logout
   */
  useEffect(() => {
    AuthLogout();
  }, []);

  /**
   * States
   */
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);

  function onKeyPressHandler(e) {
    if (e.key === "Enter") {
      setLoading(true);

      if(!email.trim() || !pass.trim()){
        setAlert("Eksik Bilgi Girdiniz", "danger");
        return;
      }

      AuthLogin(
        email,
        pass,
        () => {
          history.push("/");
        },
        () => {
          setLoading(false);
        }
      );
    }
  }

  return (
    <div className="login-container">
      <Loading loading={loading} />
      <div className="login-thumb">
        <div
          className="login-thumb-img"
          style={{
            backgroundImage: `url(${require("../assets/images/login-thumb.png")})`,
          }}
        ></div>
      </div>
      <div className="login-wrapper">
        {/**
         * Login Form
         */}
        <div className="login-form">
          <h2 className="login-head">Oturum Aç</h2>
          {/**
           * Login Input
           */}
          <div className="form-group mb-4 login-form-group">
            <div
              className="login-input-icon"
              style={{
                backgroundImage: `url(${require("../assets/images/icons/mail.png")})`,
              }}
            ></div>
            <input
              onKeyPress={onKeyPressHandler}
              value={email ? email : ""}
              className="login-input"
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {/**
           * Login Input
           */}
          <div className="form-group mb-4 login-form-group">
            <div
              className="login-input-icon"
              style={{
                backgroundImage: `url(${require("../assets/images/icons/password.png")})`,
              }}
            ></div>
            <input
              onKeyPress={onKeyPressHandler}
              value={pass ? pass : ""}
              className="login-input"
              type="password"
              placeholder="Şifre"
              onChange={(e) => setPass(e.target.value)}
            />
          </div>
          {/**
           *  Remember Me
           */}
          <div className="form-group mb-5 login-form-group">
            <div className="login-check login-check-spuare">
              <input type="checkbox" id="remember_me" />
              <label
                className="login-check-helper"
                htmlFor="remember_me"
              ></label>
              <label
                class="p-0 text-left login-check-label"
                htmlFor="remember_me"
              >
                Beni Hatırla
              </label>
            </div>
          </div>
          <div className="text-right">
            <Link className="login-link" to={"/forgot-password"}>
              Şifreni mi unuttun?
            </Link>
          </div>
          {/**
           * Login Buttons
           */}
          <div className="btn-login-group">
            <button
              className="btn btn-login"
              onClick={() => {

                if(!email.trim() || !pass.trim()){
                  setAlert("Eksik Bilgi Girdiniz", "danger");
                  return;
                }

                setLoading(true);

                AuthLogin(
                  email,
                  pass,
                  () => {
                    history.push("/");
                  },
                  () => {
                    setLoading(false);
                  }
                );
              }}
            >
              GİRİŞ YAP
            </button>
          </div>
          <Link to={"/family-login"} className="btn-login-group">
            <button className="btn btn-login-o">AİLE GİRİŞİ</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Page;
