import React from "react";
import { Link } from "react-router-dom";

function Page({ img, color = 1, platform_id = 0 }) {
  var _color = color ? " platform-box-color-" + color : "";
  return (
    <div className={"platform-box col-lg-4 col-md-6 col-sm-12" + _color}>
      <Link className="platform-box-body" to={"/app/platform/" + platform_id}>
        <div className="platform-box-cont">
          <div className="platform-box-cover">
            <div
              className="platform-box-img"
              style={{ backgroundImage: "url(" + img + ")" }}
            ></div>
          </div>
        </div>
      </Link>
    </div>
  );
}
export default Page;
