import React, {useEffect, useState} from "react";
import {post} from "../networking/Server";
import Loading from "../components/loading";
import {getFile} from "../networking/getFile";
import {convertDate} from "../functions/convertDate";

function Page(props) {

    const [state, setState] = useState({
        loading:true,
        page:1,
        banner:{}
    });

    const banner_id = parseInt(props.match.params.banner_id);

    useEffect( () => {
        getBanner()
    },[]);

    function getBanner() {
        post("api/banners/get_one",{banner_id}).then((res)=>{
            try {
                if(res.result && res.banner){
                    setState(prevState => {
                        return {...prevState, banner:res.banner,loading:false};
                    })
                }
            }catch (e) {

            }
        });
    }

    let {title,desc,image} = state.banner;


  return (
    <>
        <Loading loading={state.loading}/>
      <div className="location">{title}</div>
      <div className="main">
        <div className="container">
            {
                !state.loading?
                    <div className="area">
                        <div
                            className="area-header"
                            style={{
                                backgroundImage: "url(" + getFile(image) + ")",
                            }}
                        ></div>
                        <div className="area-head">{title}</div>
                        <div className="area-body" style={{lineHeight: 1.5,}} dangerouslySetInnerHTML={{ __html: desc }}></div>
                    </div>:null
            }
        </div>
      </div>
    </>
  );
}

export default Page;
