import Axios from "./Axios";

import { GetToken } from "../actions";
let task;

export async function post(adres, params = null, func = () => { }, getTask = false) {
    Axios.defaults.headers.common['x-access-token'] = "Bearer " + GetToken();

    if (params)
        params['web'] = true

    let promise = new Promise(function (resolve, reject) {
        setTimeout(function () {
            task = Axios.post(adres, params);
            resolve(
                task.then(({ data }) => {
                    //IStore.setConnection(0);
                    func();
                    return data;
                }).catch((err) => {
                    func();
                    //IStore.setConnection(1);
                    //console.warn(err)
                    return { result: false, error: "No_Connect" };
                })
            )
        }, 1000);
    });
    return promise;



}

export function getTask() {
    return task;
}

export async function cancelPost(task) {
    task.cancel((res) => {
        console.warn("iptal")
    })
}


export function getURL() {
    return "https://ailemaselsan.com.tr:8443/";
}

export function getImageURL() {
    return "https://ailemaselsan.com.tr:8443/";
}

