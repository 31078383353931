import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { post } from "../networking/Server";
import Pagination from "../components/pagination";
import Loading from "../components/loading";
import { getFile } from "../networking/getFile";
import { bar } from "./advicesPage";
import { cutText } from "../functions/cutText";
import EmojiTextarea from "../components/emojiTextarea";

function Page() {
  const [loading, setLoading] = useState(false);
  const [advices, setAdvices] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(9);
  const [user, setUser] = useState({});

  const [title, setTitle] = useState("");
  const [files, setFiles] = useState([]);
  const [adviceId, setAdviceId] = useState(null);
  const [content, setContent] = useState("");
  const [adviceCategory, setAdviceCategory] = useState(1);
  const formRef = useRef();

  useEffect(() => {
    getAdvices();
    getMy();
  }, []);

  useEffect(() => {
    getAdvices();
  }, [size, page]);

  async function deleteAdvice(advices_id) {
    setLoading(true);
    const data = await post("api/users/remove_advice", { advices_id });

    if (data.result) {
      getAdvices();
    }

    setLoading(false);
  }

  async function getMy() {
    setLoading(true);

    const data = await post("api/users/get");

    if (data.result) {
      setUser(data.user);
    }

    setLoading(false);
  }

  async function getAdvices() {
    setLoading(true);
    const data = await post("api/advices/get_my", { size, page });

    if (data.result) {
      setAdvices(data.advices);
    }
    setLoading(false);
  }

  async function editPrepare(id, title, content, category) {
    setContent(content);
    setTitle(title);
    setAdviceCategory(category);
    setAdviceId(id);
    setFiles([]);
  }

  async function editAdvice() {
    if (adviceId && adviceCategory && content && title) {
      let formData;

      if (files && files.length > 0) {
        formData = new FormData();

        formData.append("title", title);
        formData.append("content", content);
        formData.append("advice_category_id", adviceCategory);
        formData.append("advices_id", adviceId);
        formData.append("banner", files[0]);

        formData.append("web", "1");
      } else {
        formData = {
          title,
          content,
          advice_category_id: adviceCategory,
          advices_id: adviceId,
        };
      }

      const data = await post("api/users/set_advice", formData);

      if (data.result) {
        getAdvices();
      }

      editPrepare(null, "", "", 1);
      formRef.current.reset();
    }
  }

  return (
    <>
      <Loading loading={loading} />
      <div className="location">Tavsiyelerim</div>
      <div className="main"></div>
      <div className="content">
        <div className="container">
          <div className="advices-container">
            <div className="text-right">
              <button
                className="btn custom-btn"
                data-target="#exampleModalCenter"
                data-toggle="modal"
              >
                Tavsiyeni Paylaş
              </button>
            </div>
            {
              !loading && Array.isArray(advices) && !advices.length?
                  <div style={{display:'flex',justifyContent:'center',fontWeight:'center',fontSize:20}}>Tavsiyelerinizi bizimle paylaşmak isterseniz yukarıdaki "Tavsiyeni Paylaş" butonuna tıklayabilirsiniz.</div>:null

            }
            <div className="row">
              {/**
               * Advices
               */}
              {advices
                ? advices.map((p, i) => (
                    <div
                      key={i}
                      className="advice-box col-lg-4 col-md-6 col-sm-12"
                    >
                      <div className="advice-box-title mb-2">
                        <div className="d-flex align-items-center">
                          <div
                            className="advice-box-profile-img"
                            style={{
                              backgroundImage:
                                "url(" + getFile(user?.image) + ")",
                            }}
                          ></div>
                          <div className="advice-box-name ml-3">
                            {user?.name}
                          </div>
                        </div>
                        <div>
                          <button
                            data-target="#exampleModalCenter"
                            data-toggle="modal"
                            onClick={() =>
                              editPrepare(
                                p.advices_id,
                                p.title,
                                p.content,
                                p.advice_category_id
                              )
                            }
                            className="btn btn-icon"
                          >
                            <i className="fa fa-edit"></i>
                          </button>
                          <button
                            onClick={() => deleteAdvice(p.advices_id)}
                            className="btn btn-icon"
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                      <Link
                        className="advice-box-body"
                        to={"/app/advices/detail/" + p.advices_id}
                      >
                        <div
                          className="advice-box-img"
                          style={{
                            backgroundImage: "url(" + getFile(p.image) + ")",
                          }}
                        ></div>
                        <div className="advice-box-desc">
                          {cutText(p.content, 250)}
                        </div>
                        {/*<div className="advice-box-bar">
                          <span>
                            <button className="btn">
                              <i className="fa fa-thumbs-up"></i>
                            </button>
                            9
                          </span>
                                                <span>
                            <button className="btn">
                              <i className="fa fa-comment"></i>
                            </button>
                            12
                          </span>
                                            </div>*/}
                      </Link>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
        <Pagination
          page={page}
          size={size}
          onChangeSize={(_size) => {
            setSize(_size);
          }}
          onChange={(_page) => {
            setPage(_page);
          }}
        />
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Tavsiyeni Düzenle
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  editPrepare(null, "", "", 1);
                  formRef.current.reset();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form ref={formRef}>
                <div className="form-group">
                  <label className="form-label mb-2">Resim</label>
                  <input
                    accept="image/*"
                    multiple={false}
                    type="file"
                    onChange={(e) => {
                      setFiles(e.target.files);
                    }}
                    className="form-control-file"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="category-select" className="form-label mb-2">
                    Kategori
                  </label>
                  <select
                    id="category-select"
                    value={adviceCategory}
                    onChange={(e) => setAdviceCategory(e.target.value)}
                    className="form-control"
                  >
                    {bar.map((e) => {
                      return (
                        <option key={e.id} value={e.id}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label mb-2">Başlık</label>
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label mb-2">Açıklama</label>
                  <EmojiTextarea
                    defaultValue={content}
                    onChange={(val) => {
                      setContent(val);
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  editPrepare(null, "", "", 1);
                  formRef.current.reset();
                }}
              >
                İptal
              </button>
              <button
                data-dismiss="modal"
                onClick={editAdvice}
                type="button"
                className="btn btn-primary"
              >
                Düzenle
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
