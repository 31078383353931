import React from "react";
import { Link } from "react-router-dom";

function BlogBox({
  id = "",
  head = "",
  desc = "",
  short_desc = "",
  writer = "",
  img = require("../assets/images/logo.png"),
  offset = false,
  date = "",
  edit = false,
  onEdit = () => {},
}) {
  return (
    <div
      className={
        "blog-box col-lg-5 col-md-6 col-sm-12" +
        (offset == true ? " offset-lg-2" : "")
      }
    >
      <div className="d-flex  justify-content-between align-items-center">
        <div>
          {edit ? (
            <button className="btn btn-sm" onClick={() => onEdit()}>
              <i className="fa fa-edit"></i>
            </button>
          ) : null}
        </div>
        <div className="blog-box-date">{date}</div>
      </div>
      <Link to={"/app/blogs/details/" + id} className="blog-box-body">
        <div className="blog-box-content">
          <div className="d-flex">
            <div
              className="blog-box-img"
              style={{
                backgroundImage: "url(" + img + ")",
              }}
            ></div>
            <div className="blog-box-article">
              <div className="blog-box-head" style={{ color: "black" }}>
                {head}
              </div>
              <div className="blog-box-desc" style={{ color: "black" }}>
                {short_desc.substring(0, 240)}
              </div>
            </div>
          </div>
          <div className="blog-box-bottom">
            <div className="blog-box-writer">
              <span className="blog-box-writer-hd">Yazar: </span>
              {writer}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default BlogBox;
