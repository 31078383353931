import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom'
import Loading from "../components/loading";
import {post} from "../networking/Server";
import {getFile} from "../networking/getFile";
import $ from "jquery";
import {goTop} from "../functions/goTop";
import {setAlert} from "../actions";


function Section(props) {

  const {profile} = props;

  const [state, setState] = useState({
    loading:false,
    profile:props.profile
  });


  useEffect( () => {
    const profile = props.profile;
    setState(prevState => {
      return {...prevState, profile:profile};
    });

  },[]);

  useEffect(() => {
    if(JSON.stringify(profile) !== JSON.stringify(state.profile)){
      setState(prevState => {
        return {...prevState, profile};
      });
      let image = profile.image ?? require('../assets/images/user.png');
      $('.nav-profile-photo').attr("style", `background-color: rgb(144, 144, 144); background-image: url("${image}");`)
    }
  }, [profile]);



  function setProfilePhoto(files) {

    if(!files || !files?.length)
      return;


    let formData = new FormData();

    Array.from(files).forEach(e => formData.append("banner", e))


    setState(prevState => {
      return {...prevState, loading:true};
    });

    post("api/users/set_profile",formData).then((res)=>{
      try {
        if(res.result){
          setState(prevState => {
            return {...prevState, loading:false};
          });
          props.getProfile()
        }

      }catch (e) {

      }
    })
  }

  function removeProfilePhoto() {

    setState(prevState => {
      return {...prevState, loading:true};
    });
    post("api/users/remove_profile").then((res)=>{
      try {
        if(res.result){
          setState(prevState => {
            return {...prevState, loading:false};
          });
          props.getProfile()
        }

      }catch (e) {

      }
    })
  }

  function setProfile() {

    const {name,surname,work_type_id,children,birthday,gender_id} = state.profile;

    if(!name || !surname || !gender_id || !birthday || !work_type_id || !children)
      return;

    goTop()

    setState(prevState => {
      return {...prevState, loading:true};
    });
    post("api/users/set",{user:state.profile}).then((res)=>{
      try {
        if(res.result){
          setAlert("Profil Bilgileriniz Güncellendi","success")
          setState(prevState => {
            return {...prevState, loading:false};
          });
          props.getProfile()
        }

      }catch (e) {

      }
    })
  }

  return (
    <>
      <Loading loading={state.loading}/>
      <div className="profile-area  w-100">
        <div className="profile-area-body">
          <div className="d-flex justify-content-start flex-wrap">
            <div className="col-lg-4  col-md-12 col-sm-12 ">
              <div className="profile-area-profile-cont">
                <div className="profile-area-profile-big">
                  {
                    state.profile.image?
                        <div
                            className="profile-area-profile-big-img"
                            style={{
                              backgroundImage: "url(" + getFile(state.profile.image) + ")",
                            }}
                        ></div>:
                        <img
                            className="profile-area-profile-big-img"
                            src={require("../assets/images/user.png")}
                        />
                  }
                </div>
              </div>
            </div>
            <div className="p-3 col-lg-8 col-md-12 col-sm-12">
              <div className="btn-group">
                <button className="btn btn-primary" style={{width: 180}}>
                  <input onChange={(e)=>{
                    let files=e.target?.files;
                    setProfilePhoto(files)

                  }} accept="image/*" type="file" style={{opacity: 0, width: '100%', position: 'absolute', zIndex: 1,left:0, top:0}} />
                  <span style={{position: 'absolute', top: '50%', zIndex:0, left: '50%', transform: 'translate(-50%, -50%)', width: '100%'}}>{'Profil Resmi Seç'}</span>
                </button>
                <button

                    onClick={()=>{
                        removeProfilePhoto()
                    }}
                    className="btn btn-outline-primary">Sil</button>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="form-group col-md-6">
              <label
                className="mb-2 col-md-12 p-0"
                htmlFor="exampleFormControlFile1"
              >
                Ad
              </label>
              <input
                  onChange={(e)=>{

                    let profile = state.profile;
                    profile['name']=e.target.value;

                    setState(prevState => {
                      return {...prevState, profile:profile};
                    });

                  }}
                  defaultValue={state.profile.name} className="form-input col-md-12" />
            </div>
            <div className="form-group col-md-6">
              <label
                className="mb-2 col-md-12 p-0"
                htmlFor="exampleFormControlFile1"
              >
                Soyad
              </label>
              <input
                  onChange={(e)=>{
                    let profile = state.profile;
                    profile['surname']=e.target.value;

                    setState(prevState => {
                      return {...prevState, profile:profile};
                    });
                  }}
                  defaultValue={state.profile.surname} className="form-input col-md-12" />
            </div>
            <div className="form-group col-md-6">
              <label
                className="mb-2 col-md-12 p-0"
                htmlFor="exampleFormControlFile1"
              >
                E-Mail
              </label>
              <input defaultValue={state.profile.email} disabled className="form-input col-md-12" />
            </div>
            <div className="form-group col-md-6">
              <label
                className="mb-2 col-md-12 p-0"
                htmlFor="exampleFormControlFile1"
              >
                Cinsiyet
              </label>
              <select
                  onChange={(e)=>{
                    let profile = state.profile;
                    profile['gender_id']=e.target.value;

                    setState(prevState => {
                      return {...prevState, profile:profile};
                    });
                  }}
                  value={state.profile.gender_id}
                  className="form-input col-md-12">
                <option value={"1"}>Erkek</option>
                <option value={"2"}>Kadın</option>
              </select>
            </div>
            <div className="form-group col-md-6">
              <label
                className="mb-2 col-md-12 p-0"
                htmlFor="exampleFormControlFile1"
              >
                Doğum Tarihi
              </label>
              <input
                  onChange={(e)=>{
                    let profile = state.profile;
                    profile['birthday']=e.target.value;

                    setState(prevState => {
                      return {...prevState, profile:profile};
                    });
                  }}
                  defaultValue={state.profile.birthday} type="date" className="form-input col-md-12" />
            </div>
            <div className="form-group col-md-6">
              <label
                  className="mb-2 col-md-12 p-0"
                  htmlFor="exampleFormControlFile1"
              >
                Kaç Çocuğunuz Var
              </label>
              <select
                  onChange={(e) => {
                    let profile = state.profile;
                    profile['children']=e.target.value;

                    setState(prevState => {
                      return {...prevState, profile:profile};
                    });
                  }}
                  value={state.profile.children}
                  style={{ padding: 5 }}
                  className="form-input col-md-12"
              >
                <option value={0}>Seçim Yap</option>
                <option value={1}>Çocuğum Yok</option>
                <option value={2}>1</option>
                <option value={3}>2</option>
                <option value={4}>3</option>
                <option value={5}>4</option>
                <option value={6}>Daha Fazla</option>
              </select>
            </div>
            <div className="form-group col-md-6">
              <label
                  className="mb-2 col-md-12 p-0"
                  htmlFor="exampleFormControlFile1"
              >
                Bağlı Olduğun Genel Müdür Yardımcılığı
              </label>
              <select
                  onChange={(e) => {
                    let profile = state.profile;
                    profile['work_type_id']=e.target.value;

                    setState(prevState => {
                      return {...prevState, profile:profile};
                    });
                  }}
                  value={state.profile.work_type_id}
                  style={{ padding: 5 }}
                  className="form-input col-md-12"
              >
                <option value={0}>Seçim Yap</option>
                <option value={1}>Genel Yönetim</option>
                <option value={2}>HBT</option>
                <option value={3}>UGES</option>
                <option value={4}>SST</option>
                <option value={5}>REHİS</option>
                <option value={6}>MGEO</option>
              </select>
            </div>
            <div className="form-group col-md-6">
              <Link className="btn btn-primary btn-block mt-4"  to={"/app/profile/frame"}>
                Profile Çerçeve Ekle
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/**
       * Button
       */}
      <div className="form-group col-md-12 text-center">
        <button onClick={()=>{
            setProfile()
        }} className="btn btn-primary mt-4">Değiştir ve Kaydet</button>
      </div>
    </>
  );
}
export default Section;
