import React, {useState} from "react";

function App({label, img, color, name}) {
    const [model, setModel] = useState(false);

    return (
        <>
            <a
                className="col btn story-btn"
                data-target={"#" + name}
                data-toggle="modal"
            >
                <div style={{display: 'grid', placeItems: 'center'}}>
                    <img src={img}/>
                    <span style={{color: color}}>{label}</span>
                </div>
            </a>
        </>
    );
}

export default App;
