let weathers = [
    {
        title: "Kasırga",
        img: require("../assets/images/weather/0.png")
    },
    {
        title: "Tropikal Fırtına",
        img: require("../assets/images/weather/1.png")
    },
    {
        title:"Kasırga",
        img:require("../assets/images/weather/2.png")
    },
    {
        title:"Şiddetli Fırtına",
        img:require("../assets/images/weather/3.png")
    },
    {
        title:"Gök Gürültülü",
        img:require("../assets/images/weather/4.png")
    },
    {
        title:"Karla Karışık Yağmur",
        img:require("../assets/images/weather/5.png")
    },
    {
        title:"Sulu Kar",
        img:require("../assets/images/weather/6.png")
    },
    {
        title:"Karışık Kar",
        img:require("../assets/images/weather/7.png")
    },
    {
        title:"Çiseleyen Yağmur",
        img:require("../assets/images/weather/8.png")
    },
    {
        title:"Çiseli",
        img:require("../assets/images/weather/9.png")
    },
    {
        title:"Dondurucu Yağmur",
        img:require("../assets/images/weather/10.png")
    },
    {
        title:"Hafif Yağmur",
        img:require("../assets/images/weather/11.png")
    },
    {
        title:"Yağmurlu",
        img:require("../assets/images/weather/12.png")
    },
    {
        title:"Kar Yağışı",
        img:require("../assets/images/weather/13.png")
    },
    {
        title:"Kar Sağanağı",
        img:require("../assets/images/weather/14.png")
    },
    {
        title:"Karlı",
        img:require("../assets/images/weather/15.png")
    },
    {
        title:"Karlı",
        img:require("../assets/images/weather/16.png")
    },
    {
        title:"Dolu",
        img:require("../assets/images/weather/17.png")
    },
    {
        title:"Sulu Kar",
        img:require("../assets/images/weather/18.png")
    },
    {
        title:"Tozlu",
        img:require("../assets/images/weather/19.png")
    },
    {
        title:"Sisli",
        img:require("../assets/images/weather/20.png")
    },
    {
        title:"Sisli",
        img:require("../assets/images/weather/21.png")
    },
    {
        title:"Dumanlı",
        img:require("../assets/images/weather/22.png")
    },
    {
        title:"Fırtınalı",
        img:require("../assets/images/weather/23.png")
    },
    {
        title:"Rüzgarlı",
        img:require("../assets/images/weather/24.png")
    },
    {
        title:"Soğuk",
        img:require("../assets/images/weather/25.png")
    },
    {
        title:"Bulutlu",
        img:require("../assets/images/weather/26.png")
    },
    {
        title:"Çok Bulutlu",
        img:require("../assets/images/weather/27.png")
    },
    {
        title:"Çok Bulutlu",
        img:require("../assets/images/weather/28.png")
    },
    {
        title:"Parçalı Bulutlu",
        img:require("../assets/images/weather/29.png")
    },
    {
        title:"Parçalı Bulutlu",
        img:require("../assets/images/weather/30.png")
    },
    {
        title:"Açık",
        img:require("../assets/images/weather/31.png")
    },
    {
        title:"Güneşli",
        img:require("../assets/images/weather/32.png")
    },
    {
        title:"Açık Gökyüzü",
        img:require("../assets/images/weather/33.png")
    },
    {
        title:"Açık Gökyüzü",
        img:require("../assets/images/weather/34.png")
    },
    {
        title:"Yağmurla Karışık Dolu",
        img:require("../assets/images/weather/35.png")
    },
    {
        title:"Sıcak",
        img:require("../assets/images/weather/36.png")
    },
    {
        title:"Gök Gürültülü",
        img:require("../assets/images/weather/37.png")
    },
    {
        title:"Aralıklı Sağanak",
        img:require("../assets/images/weather/38.png")
    },
    {
        title:"Dağınık Sağanak",
        img:require("../assets/images/weather/39.png")
    },
    {
        title:"Yoğun Yağış",
        img:require("../assets/images/weather/40.png")
    },
    {
        title:"Sağanak Kar Yağışı",
        img:require("../assets/images/weather/41.png")
    },
    {
        title:"Yoğun Kar Yağışı",
        img:require("../assets/images/weather/42.png")
    },
    {
        title:"Kar Fırtınası",
        img:require("../assets/images/weather/43.png")
    },
    {
        title:"Açık Gökyüzü",
        img:require("../assets/images/weather/44.png")
    },
    {
        title:"Dağınık Sağanak",
        img:require("../assets/images/weather/45.png")
    },
    {
        title:"Dağınık Sağanak",
        img:require("../assets/images/weather/46.png")
    },
    {
        title:"Sağanak Gök Gürültülü",
        img:require("../assets/images/weather/47.png")
    },
];

let days = [
    {
        en:"Tue",
        tr:"Salı"
    },
    {
        en:"Wed",
        tr:"Çarşamba"
    },
    {
        en:"Thu",
        tr:"Perşembe"
    },
    {
        en:"Fri",
        tr:"Cuma"
    },
    {
        en:"Sat",
        tr:"Cumartesi"
    },
    {
        en:"Sun",
        tr:"Pazar"
    },
    {
        en:"Mon",
        tr:"Pazartesi"
    }
]

export function findWeather(index) {

    if(!index)
        return {
            title:"",
            img:""
        }

    try {
        return weathers[index]
    }catch (e) {
        return {
            title:"",
            img:""
        }
    }
}

export function getDay(day) {
    try {
        return days.filter(({en})=>{
            if(en==day)
                return true
        })[0].tr
    }catch (e) {
        return ""
    }
}
