import React, { useEffect, useState } from "react";
/**
 * Components
 */
import { PlatformBox } from "../components";
import {post} from "../networking/Server";
import {getFile} from "../networking/getFile";
import Loading from "../components/loading";

function Page() {

  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    getPlatforms();
  }, []);

  function getPlatforms() {
    post("api/platforms/get").then((res) => {
      try {
        if (res.result) {
          setPlatforms(res.platforms)
        }
      } catch (e) {
      }
    });
  }

  return (
    <>
      <Loading loading={platforms.length==0}/>
      <div className="location">Platformlarımız</div>
      <div className="main">
        <div className="container">
          <div className="row d-flex  justify-content-center mt-5">
            {
              platforms.map(({logo,platform_id})=>{
                  return(
                      <PlatformBox
                          platform_id={platform_id}
                          img={getFile(logo)}
                          color="1"
                      />
                  )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
