import React, { useEffect, useState } from "react";
import {frames} from "../functions/frames";
import {post} from "../networking/Server";
import Loading from "../components/loading";

function Section(props) {
  const [loading, setLoading] = useState(false);
  const [frame_id, setFrameId] = useState(props.frame_id);



  function _setFrame() {
    if(!frame_id)
      return;
    setLoading(true)
    post("api/users/set",{user:{frame:frame_id}}).then((res)=>{

      try {
        if(res.result){
          setLoading(false)
          props.history.push("/app/profile/info")
          window.location.reload(false);
        }
      }catch (e) {

      }
    });
  }
  return (
    <>
      <Loading loading={loading}/>
      <div className="profile-area  w-100">
        <div className="profile-area-body">
          <div className="frame-radios row">
            {frames
              ? frames.map((p, i) => (
                  <div
                    className="frame-radio col-lg-4 col-md-6 col-sm-12"
                    key={i}
                  >
                    <label
                      className="frame-radio-helper"
                      style={{ backgroundImage: "url(" + p.frame + ")" }}
                      htmlFor={"frame" + p.id}
                    ></label>
                    <input checked={p.id==frame_id} onClick={e=>setFrameId(p.id)} type="radio" id={"frame" + p.id} name="frame" />
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      {/**
       * Button
       */}
      <div className="form-group col-md-12 text-center">
        <button onClick={()=>{
            _setFrame()
        }} className="btn btn-primary mt-4">Değiştir ve Kaydet</button>
      </div>
    </>
  );
}
export default Section;
