import React, {useEffect, useState} from "react";
import {NavLink, Link} from "react-router-dom";
import {post} from "../networking/Server";
import {getFile} from "../networking/getFile";
import {getFrame} from "../functions/frames";
import $ from "jquery";

function NavBar(props) {

    const [state, setState] = useState({
        loading: true,
        profile: {}
    });

    useEffect(() => {
        getProfile()
    }, []);

    function closeNav() {
        const width = $(window).width();

        if (width >= 992) return;

        $('#navbar-toggler-button').addClass("collapsed").attr('aria-expanded', false);
        $('#navbarSupportedContent').hide(350, function () {
            $(this).removeClass("show");
            $(this).attr('style', "");
        });
    }

    function getProfile() {
        post("api/users/get").then((res) => {
            try {
                if (res.result && res.user) {
                    setState(prevState => {
                        return {...prevState, profile: res.user, loading: false};
                    })
                }
            } catch (e) {

            }
        });
    }

    const {image, frame} = state.profile;

    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <Link onClick={() => {
                closeNav();
            }} className="navbar-brand" to="/app">
                <img
                    className="logo-img"
                    src={require("../assets/images/logo.png")}
                    alt="Aselsan"
                />
            </Link>

            <Link onClick={() => {
                closeNav();
            }} to="/app/profile" className="nav-profile">
                <div
                    className="nav-profile-frame"
                    style={{
                        backgroundImage:
                            "url(" + getFrame(frame) + ")",
                    }}
                >
                    {
                        image?
                            <div
                                className="nav-profile-photo"
                                style={{
                                    backgroundColor: '#909090',
                                    backgroundImage:
                                        "url(" + getFile(image) + ")",
                                }}
                            ></div>:
                            <div
                                className="nav-profile-photo"
                                style={{
                                    backgroundColor: '#909090',
                                    backgroundImage:
                                        "url(" + require("../assets/images/user.png") + ")",
                                }}
                            ></div>
                    }

                </div>
            </Link>

            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                id="navbar-toggler-button"
            >
                <span className="navbar-toggler-icon"></span>
            </button>

            <div onClick={() => {
                closeNav();
            }} className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <NavLink
                            to="/app/advantages"
                            className="nav-link"
                            activeClassName="active"
                            exact
                        >
                            Asil Avantajlar
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="/app/family-talks"
                            className="nav-link"
                            activeClassName="active"
                            exact
                        >
                            Aile Sohbetlerimiz
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="/app/blogs"
                            className="nav-link"
                            activeClassName="active"
                            exact
                        >
                            Köşe Yazılarımız
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="/app/advertisements"
                            className="nav-link"
                            activeClassName="active"
                            exact
                        >
                            İlanlarımız
                        </NavLink>
                    </li>

                    <div className="nav-mobile mobile-show">
                        <li className="nav-item">
                            <NavLink
                                to="/app/platforms"
                                className="nav-link"
                                activeClassName="active"
                                exact
                            >
                                Platformlarımız
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/app/announcements"
                                className="nav-link"
                                activeClassName="active"
                                exact
                            >
                                Duyurularımız
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/app/surveys"
                                className="nav-link"
                                activeClassName="active"
                                exact
                            >
                                Anketler
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/app/advices"
                                className="nav-link"
                                activeClassName="active"
                                exact
                            >
                                Tavsiyelerimiz
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/app/first-work-day"
                                className="nav-link"
                                activeClassName="active"
                                exact
                            >
                                İlk İş Günüm
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/app/platform/9"
                                className="nav-link"
                                activeClassName="active"
                                exact
                            >
                                ASELSAN 1975
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/app/favorites"
                                className="nav-link"
                                activeClassName="active"
                                exact
                            >
                                Favorilerim
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/app/contact-us"
                                className="nav-link"
                                activeClassName="active"
                                exact
                            >
                                Bize Ulaşın
                            </NavLink>
                        </li>

                    </div>
                    <li className="nav-item dropdown mobile-hide">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="blog"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Diğer
                        </a>
                        <div className="dropdown-menu" aria-labelledby="blog">
                            <Link className="dropdown-item" to="/app/platforms">
                                Platformlarımız
                            </Link>
                            <Link className="dropdown-item" to="/app/announcements">
                                Duyurularımız
                            </Link>
                            <Link className="dropdown-item" to="/app/surveys">
                                Anketler
                            </Link>
                            <Link className="dropdown-item" to="/app/advices">
                                Tavsiyelerimiz
                            </Link>
                            <Link className="dropdown-item" to="/app/first-work-day">
                                İlk İş Günüm
                            </Link>
                            <Link className="dropdown-item" to="/app/platform/9">
                                ASELSAN 1975
                            </Link>
                            <Link className="dropdown-item" to="/app/favorites">
                                Favorilerim
                            </Link>
                            <Link className="dropdown-item" to="/app/contact-us">
                                Bize Ulaşın
                            </Link>

                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;
