var initial_state = {
  color: "",
  message: "",
};

export default function alertReducer(state = initial_state, action) {
  switch (action.type) {
    case "SET_ALERT":
      return { message: action.message, color: action.color };
    case "REMOVE_ALERT":
      return { message: "", color: "" };
    default:
      return state;
  }
}
