import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom'
import {post} from "../networking/Server";
import Loading from "../components/loading";
import {convertDate} from "../functions/convertDate";
import {getFile} from "../networking/getFile";
import Pagination from "../components/pagination";
import Banner from "../components/banner";


function Page() {
    const [state, setState] = useState({
        loading: true,
        page: 1,
        notices: [],
        size: 9
    });

    useEffect(() => {
        getNotices(state.page);
    }, []);
    const [max_size, setMaxSize] = useState(0);


    function getNotices(page, size = state.size) {
        post("api/notices/get", {size, page}).then((res) => {

            try {
                if (res.result) {
                    setMaxSize(res.max_size)
                    setState(prevState => {
                        return {...prevState, notices: res.notices.slice((page-1)*size,(page-1)*size+size), loading: false, page, size};
                    })
                }
            } catch (e) {

            }
        });
    }

    return (
        <>
            <Loading loading={state.loading}/>
            <div className="location">Duyurularımız</div>
            <div className="main">
                <div className="container">
                    {/**Slider  Start*/}
                    {
                        state.loading?null:
                            <div className="slider advertisements-slider col-lg-12 col-md-12">
                                <Banner
                                    banner_type={2}
                                />
                            </div>
                    }


                    {/**Slider End */}
                    <div className="row mt-5">
                        {state.notices
                            ? state.notices.map(({notice_id, title, content, createdAt, image, short_desc}, i, p = {}) => (
                                <Link
                                    to={"/app/announcements/details/" + notice_id}
                                    className="announcement col-md-12" key={i}>
                                    <div className="announcement-title">
                                        <div className="announcement-date">{convertDate(createdAt)}</div>
                                        <div
                                            className="announcement-img"
                                            style={{
                                                backgroundImage:
                                                    "url(" + getFile(image) + ")",
                                            }}
                                        ></div>
                                        <div className="announcement-head">{title}</div>
                                    </div>
                                    <div style={{color: 'black'}} className="announcement-desc">
                                        {short_desc}{" "}
                                    </div>
                                </Link>
                            ))
                            : null}
                    </div>

                </div>
                {
                    //Pagintaion
                }
                <Pagination
                    max_size={max_size}
                    page={state.page}
                    size={state.size}
                    onChangeSize={(size) => {
                        setState(prevState => {
                            return {...prevState, loading: true};
                        })
                        getNotices(state.page, size)
                    }}
                    onChange={(page) => {
                        setState(prevState => {
                            return {...prevState, loading: true};
                        })
                        getNotices(page)
                    }}
                />
            </div>
        </>
    );
}

export default Page;
