import React, {useEffect, useState} from "react";
import {SurveyBox} from "../components";
import {post} from "../networking/Server";
import Pagination from "../components/pagination";
import Loading from "../components/loading";
import {convertDate} from "../functions/convertDate";
import {getFile} from "../networking/getFile";

function Page() {

    const [state, setState] = useState({
        loading: true,
        page: 1,
        surveys: [],
        size: 9
    });
    const [max_size, setMaxSize] = useState(0);


    useEffect(() => {
        getSurveys(state.page);
    }, []);

    function getSurveys(page, size = state.size) {
        post("api/surveys/get", {size, page}).then((res) => {
            try {
                if (res.result) {
                    setMaxSize(res.max_size)
                    setState(prevState => {
                        return {...prevState, surveys: res.surveys, loading: false, page, size};
                    })
                }
            } catch (e) {

            }
        });
    }


    return (
        <>
            <Loading loading={state.loading}/>
            <div className="location">Anketler</div>

            <div className="survey-container main">
                <div className="container">
                    {
                        !state.loading && Array.isArray(state.surveys) && !state.surveys.length?
                            <div style={{display:'flex',justifyContent:'center',fontWeight:'center',fontSize:20}}>Şimdilik anket bulunmuyor</div>:null

                    }

                    {
                        !state.loading?
                            <div className="row">
                                {state.surveys
                                    ? state.surveys.map(({survey_id, description, image, createdAt, title, answers}, i, p = {}) => (
                                        <SurveyBox
                                            date={convertDate(createdAt)}
                                            id={survey_id}
                                            key={i}
                                            head={title}
                                            img={getFile(image)}
                                            desc={description}
                                            status={answers?.length}
                                            offset={i % 2 !== 0 ? true : false}
                                        />
                                    ))
                                    : null}
                            </div>:null
                    }
                </div>
                {
                    //Pagintaion
                }
                <Pagination
                    max_size={max_size}
                    page={state.page}
                    size={state.size}
                    onChangeSize={(size) => {
                        setState(prevState => {
                            return {...prevState, loading: true};
                        });
                        getSurveys(state.page, size)
                    }}
                    onChange={(page) => {
                        setState(prevState => {
                            return {...prevState, loading: true};
                        });
                        getSurveys(page)
                    }}
                />
            </div>
        </>
    );
}

export default Page;
