import React, {useEffect} from "react";
import $ from "jquery";
import {goTop} from "../functions/goTop";

class Pagination extends React.Component {

    componentDidMount() {
        this.props.onChangeSize(10);
    }

    goPage(page) {
        if (page < 1)
            return;
        goTop();
        this.props.onChange(page)
    }

    numbers(max_size,page) {
        let div = [];

        let min = (page - 2) < 1 ? 1 : page - 2;
        let max = page + 2;

        if(max_size<= max)
            max = max_size;


        for (let i = min; i <= max; i++) {
            div.push(<div key={i} onClick={() => {
                this.goPage(i)
            }} className={"pagination-item " + (page == i ? "active" : "")}>{i}</div>)
        }

        return div;
    }

    render() {
        let page = this.props.page;
        let max_size = this.props.max_size;

        console.warn(page,max_size)
        if ((page) && max_size ) {
            return (
                <div className="pagination">
                    <div className="pagination-bar">
                        <div onClick={() => {
                            this.goPage(page - 1)
                        }} className="pagination-prev">Önceki
                        </div>
                        {
                            this.numbers(max_size,page)
                        }
                        <div onClick={() => {
                            this.goPage(page + 1)
                        }} className="pagination-next">Sonraki
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-center" style={{marginLeft:10}}>
                        {/*<span style={{marginRight: 0.5 + 'rem'}}>Sayfa başına</span>*/}
                        <select value={this.props.size} onChange={e => {
                            this.props.onChangeSize(e.target.value)
                            goTop()
                        }} id="perPage" className="perPage form-control">
                            {[10, 20, 50, 100].map(e => {


                                return (<option key={e-(e%3)} value={e-(e%3)}>{e}</option>)
                            })}
                        </select>
                        {/*<span style={{marginLeft: 0.5 + 'rem'}}>adet göster</span>*/}
                    </div>
                </div>
            )
        }
        else
            return <div/>
    }
}

Pagination.defaultProps = {
    onChange: () => {

    },
    onChangeSize: () => {

    }
}

export default Pagination
