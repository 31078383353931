import React, {useEffect, useState} from "react";
import {post} from "../networking/Server";
import Loading from "../components/loading";


function Page(props) {

  const [state, setState] = useState({
    loading:true,
    page:1,
    questions:[],
    answers:[]
  });

  const survey_id = parseInt(props.match.params.survey_id);


  useEffect( () => {
    getSurvey();

  },[]);

  function getSurvey(props) {
    post("api/surveys/get_questions",{survey_id}).then((res)=>{
      try {
        if(res.result){
          setState(prevState => {
            return {...prevState, questions:res.questions,loading:false};
          })
        }
      }catch (e) {

      }
    });
  }

  function setSurvey() {

    setState(prevState => {
      return {...prevState, loading:true};
    });
    if(state.questions?.length == state.answers?.length){
      post("api/surveys/set_answer",{answers:state.answers,survey_id}).then((res)=>{
        try {
          if(res.result){
            props.history.goBack()
          }
        }catch (e) {

        }
      });
    }else{

    }
  }

  return (
    <>
      <Loading loading={state.loading}/>
      <div className="location">Anketler</div>

      <div className="survey-container">
        <div className="container">
          <div className="row">
            {/**
             * Accordion Start
             */}
            <div className="accordion col-12" id="accordionExample">
              {/**
               * Start
               */}
              {state.questions? state.questions.map(({survey_question_id,question,options,question_type_id}, i,p={}) => {



                try {
                  options=JSON.parse(options);
                }catch (e) {
                  options=[]
                }

                return(
                    <div className="card survey-accordion-card" key={i}>
                      <div className="card-header survey-accordion-header">
                        <button
                            className="survey-accordion-btn"
                            type="button"
                            data-toggle="collapse"
                            data-target={"#collapse" + i}
                            aria-expanded="true"
                            aria-controls={"collapse" + i}
                        >
                          <div className="survey-accordion-num">
                            <span>{i + 1}</span>
                          </div>
                          <div className="survey-accordion-text">
                            {question}
                          </div>
                        </button>
                      </div>

                      {
                        question_type_id==1?
                            <div
                                id={"collapse" + i}
                                className={"collapse" + (i == 0 ? " show" : "")}
                                data-parent="#accordionExample"
                            >
                              <div className="card-body">
                                <div className="survey-question">{p.question}</div>
                                <div className="survey-answers row">
                                  {options.map((option, i2) => (
                                      <div
                                          key={i2}
                                          className={
                                            "survey-answer-option col-lg-6 col-sm12" +
                                            (state.answers[i]?.answer == option ? " active" : "")
                                          }
                                          onClick={()=>{
                                            let answers=state.answers;
                                            answers[i]={
                                              answer:option,
                                              survey_question_id:survey_question_id
                                            };
                                            setState(prevState => {
                                              return {...prevState, answers};
                                            })
                                          }}
                                      >
                                        <div className="survey-answer-option-body">
                                          {option}
                                        </div>
                                      </div>
                                  ))}
                                </div>
                              </div>
                            </div>:
                            question_type_id==2?
                                <div
                                    id={"collapse" + i}
                                    className={"collapse" + (i == 0 ? " show" : "")}
                                    data-parent="#accordionExample"
                                >
                                  <div className="card-body">
                                    <div className="survey-question">{p.question}</div>
                                    <div className="survey-answers row">
                                      <div
                                          key={i}
                                          className={"survey-answer-option col-lg-6 col-sm12"}
                                      >
                                        <input
                                            placeholder={"Cevabınız"} style={{width:'100%',height:150}} className="survey-answer-option-body"
                                            value={state.answers[i]?.answer}
                                            onChange={(e)=>{
                                              let answers=state.answers;

                                              if(!e.target.value.trim()){
                                                answers.splice(i, 1);
                                                setState(prevState => {
                                                  return {...prevState, answers};
                                                });
                                                return;
                                              }

                                              answers[i]={
                                                answer:e.target.value.trim(),
                                                survey_question_id:survey_question_id
                                              };
                                              setState(prevState => {
                                                return {...prevState, answers};
                                              })
                                            }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>:
                                null
                      }


                    </div>
                )
              }):null}
            </div>
            {/**
             * Button
             */}
            <div className="btn-group col-md-12 mt-5">
              <button
                  disabled={state.questions?.length != state.answers?.length}
                  className="btn btn-primary m-auto"
                  onClick={()=>{
                      setSurvey()
                  }}
              >Tamamla</button>
            </div>
          </div>
          {/**
           * Accordion End
           */}
        </div>
      </div>
    </>
  );
}

export default Page;
