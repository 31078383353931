import React, { useEffect, useState } from "react";

import Carousel from "react-multi-carousel";

const logo = require("../assets/images/platforms/teknomacera.png");

function Page() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 1001},
      items: 2,
    },
    mobile: {
      breakpoint: { max: 1000, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="location">Platformlarımız</div>
      <div className="main">
        <div className="container">
          <div className="area">
            <div className="area-body p-5">
              {/**
               *Logo
               */}
              <div
                className="area-logo"
                style={{ backgroundImage: "url(" + logo + ")" }}
              ></div>
              {/**
               * P
               */}
              <p className="b mt-5">
                24 Nisan 2019 tarihinde hayata geçirilen ASELSAN Tekno Macera,
                yeni neslin çocuklarına, bilim ve teknoloji alanında faydalı
                bilgi ve alışkanlıklar kazandırarak çocukların meraklı,
                araştırmacı ve üretken bireyler haline gelmelerini amaçlayan bir
                ücretsiz dijital eğitim platformudur. Tekno Macera, Ankara’da
                çeşitli AVM’lerde düzenlenen Robotik ve Kodlama Atölyelerinin
                yanı sıra Teknofest ve Konya Bilim Festivali gibi etkinlikler ve
                İstanbul, Van ve Bitlis gibi şehirlerde okullara yapılan
                ziyaretlerle Anadolu’nun birçok yerinde çocuklarımızla
                buluşturulmuştur. Günümüz itibariyle katılım sağlanan
                etkinliklerde yaklaşık 30.000 teknoloji meraklısı çocukla
                birebir temas sağlanmıştır.Pedagogların denetiminde hazırlanan
                blog yazıları, eğlenceli eğitim videoları ve oyunların yer
                aldığı zengin içeriğe sahip www.teknomacera.com internet
                sitesini tarayıcılarınızda favorilerinize eklemeyi ihmal
                etmeyin. Etkinliklerimiz ve yarışmalarımızdan haberdar olmak
                için de sosyal medya hesaplarımızı takip etmeyi unutmayın.
              </p>
              {/**
               * WWW LİNK
               */}
              <div className="d-flex justify-content-between flex-wrap">
                <div className="mt-5 d-flex justify-content-center align-items-center  flex-wrap">
                  <a target={"_blank"} href="https://www.twitter.com/teknomacera">
                    <img
                        className="mr-3"
                        src={require("../assets/images/twitter.png")}
                        width="50px"
                        height="50px"
                    />
                  </a>
                  <a target={"_blank"} href="https://www.youtube.com/channel/UCPm49YBADCxoEqZ5eACatdQ">
                    <img
                        className="mr-3"
                        src={require("../assets/images/youtube.png")}
                        width="50px"
                        height="50px"
                    />
                  </a>
                  <a target={"_blank"} href="https://www.facebook.com/teknomacera">
                    <img
                        className="mr-3"
                        src={require("../assets/images/facebook.png")}
                        width="50px"
                        height="50px"
                    />
                  </a>
                  <a target={"_blank"} href="https://www.instagram.com/teknomacera/?hl=tr">
                    <img
                        className="mr-3"
                        src={require("../assets/images/instagram.png")}
                        width="50px"
                        height="50px"
                    />
                  </a>

                  <span className="b color-app mt-4">/ Aselsan Tekno Macera</span>
                </div>
                <div className="mt-5 d-flex justify-content-center align-items-center flex-wrap">
                  <img
                    className="mr-3"
                    src={require("../assets/images/www.png")}
                    width="50px"
                    height="50px"
                  />
                  <a
                    className="b color-app mt-4"
                    href="https://www.teknomacera.com/"
                    target="_blank"
                  >
                    / https://www.teknomacera.com/
                  </a>
                </div>
              </div>
              {/**
               * Multi coursel
               */}
              <div className="mt-5">
                <Carousel showDots  responsive={responsive}>
                  <div className="multi-coursel-item">
                    <img
                      draggable="false"
                      className="multi-coursel-img"
                      src={require("../assets/images/teknomacera/1.png")}
                    />
                  </div>
                  <div className="multi-coursel-item">
                    <img
                      draggable="false"
                      className="multi-coursel-img"
                      src={require("../assets/images/teknomacera/2.png")}
                    />
                  </div>
                  <div className="multi-coursel-item">
                    <img
                      draggable="false"
                      className="multi-coursel-img"
                      src={require("../assets/images/teknomacera/3.png")}
                    />
                  </div>
                  <div className="multi-coursel-item">
                    <img
                      draggable="false"
                      className="multi-coursel-img"
                      src={require("../assets/images/teknomacera/4.png")}
                    />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
