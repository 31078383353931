
function setData(tokenKey, tokenValue) {
    try {
        return localStorage.setItem(tokenKey, JSON.stringify(tokenValue));
    } catch (e) {
        return "err"
    }
}
function getData(tokenKey) {
    try {
        return JSON.parse((localStorage.getItem(tokenKey)));
    } catch (e) {
        return localStorage.getItem(tokenKey);
    }
}
function removeData(tokenKey) {
    try {
        return localStorage.removeItem(tokenKey);
    } catch (e) {
        return "err"
    }
}


export {
    setData,
    getData,
    removeData
}
