import React, {useEffect, useState} from "react";
import {post} from "../networking/Server";
import Loading from "../components/loading";
import {getFile} from "../networking/getFile";
import {convertDate} from "../functions/convertDate";
import {goTop} from "../functions/goTop";

const header_back = require("../assets/images/blog-thumb.png");
const profile = "https://www.pngarts.com/files/3/Avatar-PNG-Pic.png";
const writer = "Şükrü Taha Bıyık";
const date = "26.08.2020";
const head = "Head";
const body =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

function Page(props) {

  const [state, setState] = useState({
    loading:true,
    page:1,
    blog:{},
  });


  const blog_id = parseInt(props.match.params.detail);


  useEffect( () => {
    goTop()
    getBlog();
  },[]);


  function getBlog() {
    post("api/blogs/get_one",{blog_id}).then((res)=>{

      try {
        if(res.result && res.blog){
          setState(prevState => {
            return {...prevState, blog:res.blog,loading:false};
          })
        }
      }catch (e) {

      }
    });
  }


  const {image,content,title,createdAt,user} = state.blog;


  return (
    <>
      <Loading loading={state.loading}/>
      <div className="location">Köşe Yazısı</div>
      <div className="main">
        <div className="container">
          {
            !state.loading?
                <div className="area">
                  <div
                      className="area-header"
                      style={{
                        backgroundImage: "url(" + getFile(image) + ")",
                      }}
                  ></div>
                  <div className="area-bar">
                    <div
                        className="area-profile"
                        style={{
                          backgroundImage: "url(" + getFile(user?.image) + ")",
                        }}
                    ></div>
                    <div className="area-writer">
                      <span className="area-writer">Yazar :</span>
                      {user?.name+" "+user?.surname}
                    </div>
                    <div className="area-date">{convertDate(createdAt)}</div>
                  </div>

                  <div className="area-head">{title}</div>
                  <div className="area-body" style={{lineHeight: 1.5,}}  dangerouslySetInnerHTML={{ __html:content}}></div>
                </div>:null
          }
        </div>
      </div>
    </>
  );
}

export default Page;
