export const c_specifications = {
    0:[],
    1:[
        {
            title:"Fiyat",
            desc:"Kaç TL?",
            constant:true
        },
        {
            title:"Marka",
            desc:"Markası Nedir?",
            constant:true
        },
        {
            title:"Model",
            desc:"Modeli Nedir?",
            constant:true
        },
        {
            title:"Model Yılı",
            desc:"Model Yılı Kaç?",
            constant:true
        },
        {
            title:"Kilometre",
            desc:"Kaç Kilometrede?",
            constant:true
        },
        {
            title:"Motor Hacmi",
            desc:"Motor Hacmi Nedir?",
            constant:true
        },
        {
            title:"Kasa Tipi",
            desc:"Kasa Tipi Nedir?",
            constant:true
        },
        {
            title:"Renk",
            desc:"Renk Nedir?",
            constant:true
        },
        {
            title:"İç Donanım",
            desc:"İç Donanım Neler?",
            constant:true
        },
        {
            title:"Dış Donanım",
            desc:"Dış Donanım Neler?",
            constant:true
        }
    ],
    2:[
        {
            title:"Fiyat",
            desc:"Kaç TL?",
            constant:true
        },
        {
            title:"Aidat",
            desc:"Aidat Ne Kadar?",
            constant:true
        },
        {
            title:"Isınma Şekli",
            desc:"Isınma Şekli Nedir?",
            constant:true
        },
        {
            title:"Oda + Salon Sayısı",
            desc:"Oda + Salon Sayısı Kaçtır?",
            constant:true
        },
        {
            title:"Metrekare",
            desc:"Kaç Metrekare?",
            constant:true
        },
        {
            title:"Bulunduğu Kat",
            desc:"Bulunduğunuz Kat Kaç?",
            constant:true
        },
        {
            title:"Bina Yaşı",
            desc:"Bina Yaşı Kaç?",
            constant:true
        },
        {
            title:"Kat Sayısı",
            desc:"Kaç Katlı?",
            constant:true
        },
        {
            title:"Eşya Durumu",
            desc:"Eşya Durumu Nedir?",
            constant:true
        },
        {
            title:"Cephe",
            desc:"Hangi Cephe?",
            constant:true
        }
    ],
    3:[
        {
            title:"Fiyat",
            desc:"Kaç TL?",
            constant:true
        }
    ],
    4:[
        {
            title:"Fiyat",
            desc:"Kaç TL?",
            constant:true
        }
    ],
}
